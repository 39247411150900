class Subscriber {
  constructor(data) {
    this.id = data.hasOwnProperty('id') && data.id !== null ? parseInt(data.id) : null;
    this.email = data.hasOwnProperty('email') ? data.email : null;
    // The registration count has changed. It used to be a simple count, but now it's two separate counts. If the
    // new-style ones are not present and the old-style one is, then convert the old-style to the new-style.
    if (data.hasOwnProperty('newsletterCount') && data.hasOwnProperty('partnershipCount')) {
      this.newsletterCount = parseInt(data.newsletterCount);
      this.partnershipCount = parseInt(data.partnershipCount);
    } else {
      // Assume the old-style count is just the newsletter count, and that the partnership count is zero.
      this.newsletterCount = data.hasOwnProperty('registrationCount') ? parseInt(data.registrationCount) : 0;
      this.partnershipCount = 0;
    }

    this.createdAt = null;
    if (this.hasId()) {
      // createdAt should remain null if it's missing in the record.
      // @TODO: We should validate this date/time.
      this.createdAt = data.hasOwnProperty('createdAt') ? new Date(data.createdAt) : null;
    } else {
      this.createdAt = new Date(); // @TODO: This needs to be converted to a string on save.
    }
  };

  getId() {
    return this.id;
  };

  hasId() {
    return this.id !== null;
  };

  setId(newId) {
    this.id = newId;
  };

  getEmail() {
    return this.email;
  };

  hasEmail() {
    return this.email !== null && this.email !== '';
  };

  setEmail(email) {
    this.email = email;
  };

  getNewsletterCount() {
    return this.newsletterCount;
  };

  getPartnershipCount() {
    return this.partnershipCount;
  };

  incrementRegistrationCount(subscriptionType) {
    if (subscriptionType === 'newsletter') {
      ++this.newsletterCount;
    } else {
      ++this.partnershipCount;
    }
  };

  getCreatedAt() {
    return this.createdAt;
  }

  getCreatedAtAsString() {
    return this.createdAt === null ? null : this.createdAt.toUTCString();
  }
}

export default Subscriber;
