import Certification from "../models/Certification";
import certificationJson from "./internalDataSource/certifications.js";

/**
 * This class is the interface between the certification data and the storage engine (at time of writing, a file in
 * the application). It is instantiated when the data repository factory is instantiated. The raw data is loaded from
 * the file whenever the application asks for it. At the moment, the data is read-only.
 * If/when the certification data is stored like the rest of the data, this class should be refactored to work like the
 * other ones, so extend AbstractDataRepository, implement persist, etc.
 */
class CertificationRepository {
  constructor() {
    this.certifications = null;
  }

  /**
   * Certifications are only loaded on demand. If the class property is null, it means we haven't
   * tried to load them (note: an empty array would mean we tried to load them and there were zero
   * of them).
   *
   * @return {Certification[]}
   */
  getCertifications() {
    // If we haven't loaded the certifications, load them now.
    if (this.certifications === null) {
      /**
       * @var {Array} certificationJson Defined in src/dataStore/internalDataSource/certifications.js
       */
      this.certifications = [];
      for(let certIdx in certificationJson) {
        if (certificationJson.hasOwnProperty(certIdx)) {
          this.certifications.push(new Certification(certificationJson[certIdx]));
        }
      }
    }

    return this.certifications;
  };

  /**
   * Return all the certifications that have images.
   *
   * @return {Certification[]}
   */
  getCertificationsWithImages() {
    return this.getCertifications().filter(
      function (certification) {
        return certification.hasImages();
      }
    );
  };
}

export default CertificationRepository;
