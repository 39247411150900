import React from "react";
import AbstractCategoryForm from "./AbstractCategoryForm";

class AbstractCategoryMaintenance extends AbstractCategoryForm {
  getActionButtonSection() {
    return (
      <div className="row">
        <div className="col-md-2">
          <input type="button" value="Save" className="btn btn-primary" onClick={this.saveCategory}/>
        </div>
      </div>
    );
  }

  showSeasonalSettings() {
    return true;
  }

  afterCategorySave(recordId) {
    this.props.history.push('/admin/categories/list'); // @TODO: We should make this URL a constant somewhere.
  }
}

export default AbstractCategoryMaintenance;
