import AbstractSuggestedCompanyMaintenance from "./AbstractSuggestedCompanyMaintenance";
import React from "react";
import {SuggestedCompanyStatuses} from "../../../../models/Statuses/SuggestedCompanyStatuses";
import SuggestedCompany from "../../../../models/SuggestedCompany";
import CommunicationsHelperService from "../../../../services/CommunicationsHelperService";

class SuggestedCompanyEdit extends AbstractSuggestedCompanyMaintenance {
  componentDidMount() {
    // Check that the status allows for editing. Basically, it has to be pending.
    if (this.company.getStatus() !== SuggestedCompanyStatuses.pending) {
      window.alert('Sorry, only companies that are Pending can be edited');
      window.location.href = '/admin/suggestedCompanies/list';
    }
  }

  getInitialCompany() {
    // Note: we can't use "this.suggestedCompanyRepository" as it hasn't been initialised at this point.
    return this.props.suggestedCompanyRepository.getSuggestedCompanyById(this.props.match.params.id);
  }

  isPublicFacing() {
    return false;
  }

  prepareObjectToSave() {
    return new SuggestedCompany(this.state.companyCopy);
  }

  persistObject(objectToSave) {
    return this.suggestedCompanyRepository.persist(
      objectToSave,
      'The suggestion was saved!',
      () => {
        // We need to send an email to the suggester, saying we've updated the record.
        let suggestedCompany = this.suggestedCompanyRepository.getSuggestedCompanyById(objectToSave.getId());
        let communicationsHelperService = new CommunicationsHelperService(); // @TODO: This should be injected, really.
        communicationsHelperService.afterEdit(
          suggestedCompany,
          () => {
            // Redirect back to the review page for this suggested company.
            // @TODO: It would be nice to do this without causing it to reload the entire page.
            window.location.href = '/admin/suggestedCompanies/review/'+suggestedCompany.getId();
          }
        );
      }
    );
  }

  render() {
    return (
      <div className="edit">
        <h2>Edit a Suggested Company</h2>
        <h3>Company Details</h3>
        {this.maintenanceForm()}
      </div>
    )
  }
}

export default SuggestedCompanyEdit;
