import React from "react";
import OneEthicalFact from "./OneEthicalFact";

class ShowEthicalFacts extends React.Component {
  constructor(props) {
    super(props);

    this.ethicalFacts = props.ethicalFactsRepository.getRandom(3);
  }

  render() {
    let ethicalFacts = [];
    for(let factIdx in this.ethicalFacts) {
      if (this.ethicalFacts.hasOwnProperty(factIdx)) {
        /**
         * @var {EthicalFact} ethicalFact
         */
        let ethicalFact = this.ethicalFacts[factIdx];
        ethicalFacts.push(<OneEthicalFact key={factIdx} ethicalFact={ethicalFact} index={factIdx}/>);
      }
    }

    return (
      <div className="ethical-facts">
        {ethicalFacts}
      </div>
    )
  }
}

export default ShowEthicalFacts;
