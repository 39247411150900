import React from "react";
import {Link} from "react-router-dom";

class UserList extends React.Component {
  render() {
    return (
      <div>
        <p>I wanted to show a list of users here, but I couldn't work out how to do it!</p>
        <Link to='/admin/users/add'><span className="btn btn-primary">Add a user</span></Link>
      </div>
    );
  }
}

export default UserList;
