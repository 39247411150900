import React from "react";
import {Link, NavLink, Route, Switch} from "react-router-dom";
import ShowDefaultInfo from "../ShowDefaultInfo";
import ShowCategories from "../category/ShowCategories";
import AboutUs from "../AboutUs/AboutUs";
import SuggestCompany from "../Suggestions/SuggestCompany";
import ShowCategory from "../category/ShowCategory";
import LoginPage from "../admin/LoginPage";
import ShowCertifications from "../Certifications/ShowCertifications";
import MainLogo from "../MainLogo";
import ManageSuggestedCompany from "../Suggestions/ManageSuggestedCompany";
import Company from "../Company/Company";
import NumberOfDisplayedRecords from "../Common/NumberOfDisplayedRecords";
import $ from 'jquery';
import Footer from "./Footer";

class PublicFacingController extends React.Component {
  constructor(props) {
    super(props);

    // We need to control the number of companies that are displayed on the category page to be managed at a higher
    // level, hence initialising it here. It gets reset if the user navigates to the list of all categories.
    this.numberOfCompaniesDisplayedForCategory = new NumberOfDisplayedRecords([15]);

    this.state = {
      categoryRepository: this.props.categoryRepository,
      certificationRepository: this.props.certificationRepository,
      companyRepository: this.props.companyRepository,
      countryRepository: this.props.countryRepository,
      ethicalFactsRepository: this.props.ethicalFactsRepository,
      subscriberService: this.props.subscriberService,
      suggestedCompanyRepository: this.props.suggestedCompanyRepository,
      tagRepository: this.props.tagRepository,
    };
  }

  onItemClick(event) {
    const searchActive = document.getElementsByClassName("active");
    const prevActive = searchActive && searchActive[0];

    // Remove from prev elem
    if (prevActive) {
      prevActive.classList.remove("active");
    }

    // Add to current click item
    event.target.classList.add("active");
  };

  render() {
    // The "/admin" links seems to need to be there otherwise "production" doesn't work!
    return (<>
      <div>
          <div className="content-wrap">
            <div className="header">
            <div className="main-logo-container">
              <Link to="/"><MainLogo/></Link>
            </div>
            <button className="navbar-toggler" type="button" onClick={() => $("#navbarSupportedContent").toggle(150)}>
                <span className="glyphicon glyphicon-align-justify" aria-hidden="true"/>
            </button>
            <nav className="navbar navbar-expand-lg navbar-light" id="nav_position">
              <div id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item ">
                    <NavLink className="nav-link" onClick={this.onItemClick} to="/categories">Shop Categories</NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink className="nav-link" onClick={this.onItemClick} to="/certifications">Certifications</NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink className="nav-link" onClick={this.onItemClick} to="/suggestCompany">Suggest A Company</NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink className="nav-link" onClick={this.onItemClick} to="/about">About Us</NavLink>
                  </li>
                </ul>
              </div>
            </nav>
          </div>

            <Switch>
              <Route exact path="/">
                <ShowDefaultInfo categoryRepository={this.state.categoryRepository} ethicalFactsRepository={this.state.ethicalFactsRepository} subscriberService={this.state.subscriberService}/>
              </Route>
              <Route
                exact
                path="/categories"
                render={(props) => <ShowCategories {...props} categoryRepository={this.state.categoryRepository} numberOfCompaniesDisplayedForCategory={this.numberOfCompaniesDisplayedForCategory}/>}
              />
              <Route
                exact
                path="/certifications"
                render={(props) => <ShowCertifications {...props} certificationRepository={this.state.certificationRepository}/>}
              />
              <Route
                exact
                path="/suggestCompany"
                render={(props) => <SuggestCompany {...props} companyRepository={this.state.companyRepository} suggestedCompanyRepository={this.state.suggestedCompanyRepository} categoryRepository={this.state.categoryRepository} tagRepository={this.state.tagRepository} countryRepository={this.state.countryRepository}/>}
              />
              <Route
                exact
                path="/manageSuggestedCompany/:key"
                render={(props) => <ManageSuggestedCompany {...props} suggestedCompanyRepository={this.state.suggestedCompanyRepository} categoryRepository={this.state.categoryRepository} tagRepository={this.state.tagRepository} countryRepository={this.state.countryRepository}/>}
              />
              <Route exact path="/about" component={AboutUs}/>
              <Route
                exact
                path="/category/:id"
                render={(props) => <ShowCategory {...props} categoryRepository={this.state.categoryRepository} companyRepository={this.state.companyRepository} numberOfCompaniesDisplayedForCategory={this.numberOfCompaniesDisplayedForCategory}/>}
              />
              <Route
                exact
                path="/company/:id"
                render={(props) => <Company {...props} companyRepository={this.state.companyRepository}/>}
              />
              <Route exact path="/admin/login" component={LoginPage}/>
            </Switch>
          </div>

        <Footer subscriberService={this.state.subscriberService}/>

      </div>
        </>
    );
  }
}

export default PublicFacingController;
