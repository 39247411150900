import ValidationResult from "./ValidationResult";

class TagValidator {
  /**
   * @param {TagRepository} tagRepository
   * @param {CompanyRepository} companyRepository
   * @param {SuggestedCompanyRepository} suggestedCompanyRepository
   */
  constructor(tagRepository, companyRepository, suggestedCompanyRepository) {
    this.tagRepository = tagRepository;
    this.companyRepository = companyRepository;
    this.suggestedCompanyRepository = suggestedCompanyRepository;
  }

  /**
   * @param {Tag} tag
   *
   * @return {ValidationResult}
   */
  validate(tag) {
    let result = new ValidationResult();

    // Make sure the name has been entered.
    if (tag.getName() === '' || tag.getName() === null || tag.getName() === undefined) {
      result.addError('Tag name is mandatory');
    } else {
      // Make sure the name is unique.
      let tagsByName = this.tagRepository.getTagsByName(tag.getName());
      // If the tag already exists, we have to make sure we don't check against itself.
      if (tag.getId()) {
        tagsByName.filter(
          function (thisTag) {
            return thisTag.getId() !== tag.getId();
          }
        )
      }
      if (tagsByName.length) {
        result.addError('Tag name must be unique');
      }
    }

    return result;
  }

  validateDelete(tag) {
    let result = new ValidationResult();

    // Check whether this tag is referenced by any companies or suggested companies.
    let companies = this.companyRepository.getByTagId(tag.getId());
    if (companies.length) {
      // @TODO: Maybe include the names of the first 2 or 3 in the message?
      result.addError('Tag is in use by at least one company.');
    }
    let suggestedCompanies = this.suggestedCompanyRepository.getByTagId(tag.getId());
    if (suggestedCompanies.length) {
      // @TODO: Maybe include the names of the first 2 or 3 in the message?
      result.addError('Tag is in use by at least one suggested company.');
    }

    return result;
  }
}

export default TagValidator;
