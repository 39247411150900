/**
 * This service allows us to send emails from the application. The relevant class just calls "send" on this object.
 * The application doesn't need to know what service we're using, etc. It means we can change the service without
 * changing the application.
 * @TODO: Well, in theory - we do ask for the template id to be passed in. This should possibly be renamed to "EmailJsMailerService"?
 */

import * as emailjs from "emailjs-com";

class MailerService {
  send(templateId, templateParams, callback) {
    emailjs.send(process.env.REACT_APP__EMAILJS__SERVICE_ID, templateId, templateParams, process.env.REACT_APP__EMAILJS__USER_ID)
      .then(function(response) {
        if (callback) {
          callback();
        }
      }, function(error) {
        window.alert('Failed to send email. Error was: '+error);
        if (callback) {
          callback();
        }
      });
  }

  sendToSuggester(toEmail, subject, givenName, body, suggestedProductLink, callback) {
    let result = {
      success: false,
      errorMessage: 'Unknown error'
    };
    if (toEmail === null || toEmail === '') {
      result.errorMessage = '"To" is missing';

      return result;
    }

    // If sending emails to the suggester is suppressed, don't send the email, but it's important that we call the
    // callback function.
    if (process.env.REACT_APP__EMAIL__SUPPRESS_SUGGESTER_EMAIL === 'true') {
      window.alert('Sending emails to the suggester is suppressed; we would have sent an email here');
      if (callback) {
        callback();
      }
    } else {
      this.send(
        process.env.REACT_APP__EMAILJS__SUGGESTER_TEMPLATE_ID,
        {
          admin_email: process.env.REACT_APP__EMAILJS__ADMIN_EMAIL_ADDRESS,
          subject: subject,
          to_email: toEmail,
          given_name: givenName,
          suggested_product_link: suggestedProductLink,
          message_html: body
        },
        callback
      );
    }

    result.success = true;
    result.errorMessage = null;

    return result;
  }

  sendToAdmin(fromEmail, suggesterName, subject, body, adminLink, callback) {
    suggesterName = suggesterName ? suggesterName : '-unknown-';
    // If sending emails to the suggester is suppressed, don't send the email, but it's important that we call the
    // callback function.
    if (process.env.REACT_APP__EMAIL__SUPPRESS_ADMIN_EMAIL === 'true') {
      window.alert('Sending emails to the admins is suppressed; we would have sent an email here');
      if (callback) {
        callback();
      }
    } else {
      this.send(
        process.env.REACT_APP__EMAILJS__ADMIN_TEMPLATE_ID,
        {
          admin_email: process.env.REACT_APP__EMAILJS__ADMIN_EMAIL_ADDRESS,
          from_email: fromEmail,
          suggester_name: suggesterName,
          subject: subject,
          message_html: body,
          admin_link: adminLink
        },
        callback
      );
    }
  }
}

export default MailerService;
