import AbstractCompanyMaintenance from "../AbstractCompanyMaintenance";
import {SuggestedCompanyStatuses} from "../../../../models/Statuses/SuggestedCompanyStatuses";
import FieldTextArea from "../../FormWidgets/FieldTextArea";
import Recaptcha from "../../FormWidgets/Recaptcha";
import FieldText from "../../FormWidgets/FieldText";
import React from "react";
import SuggestedCompanyService from "../../../../services/SuggestedCompanyService";
import {Link} from "react-router-dom";
// eslint-disable-next-line
import CompanyFieldAttributes from "../../../../services/CompanyFieldAttributes";
import SuggestedCompanyFieldAttributes from "../../../../services/SuggestedCompanyFieldAttributes";

class AbstractSuggestedCompanyMaintenance extends AbstractCompanyMaintenance {
  constructor(props) {
    super(props);

    this.suggestedCompanyRepository = this.props.suggestedCompanyRepository;

    this.state.companyCopy.suggesterReason = this.company.getSuggesterReason();
    this.state.companyCopy.suggesterGivenName = this.company.getSuggesterGivenName();
    this.state.companyCopy.suggesterFamilyName = this.company.getSuggesterFamilyName();
    this.state.companyCopy.suggesterPhoneNumber = this.company.getSuggesterPhoneNumber();
    this.state.companyCopy.suggesterEmailAddress = this.company.getSuggesterEmailAddress();
    this.state.companyCopy.suggesterAdditionalDetails = this.company.getSuggesterAdditionalDetails();
    this.state.companyCopy.status = this.company.getStatus();
    this.state.companyCopy.createdAt = this.company.getCreatedAt();
    this.state.companyCopy.internalNotes = this.company.getInternalNotes();
    this.state.companyCopy.adminNotes = this.company.getAdminNotes();
  }

  isPublicFacing() {
    return true;
  }

  /**
   * Get the appropriate attributes for this screen.
   * @return {CompanyFieldAttributes}
   */
  getFieldAttributes() {
    return new SuggestedCompanyFieldAttributes();
  }

  updateState() {
    this.setState({companyCopy: this.state.companyCopy});
  }

  setGivenName = event => {
    this.state.companyCopy.suggesterGivenName = event.target.value;
    this.updateState();
  }

  setFamilyName = event => {
    this.state.companyCopy.suggesterFamilyName = event.target.value;
    this.updateState();
  }

  setPhoneNumber = event => {
    this.state.companyCopy.suggesterPhoneNumber = event.target.value;
    this.updateState();
  }

  setEmailAddress = event => {
    this.state.companyCopy.suggesterEmailAddress = event.target.value;
    this.updateState();
  }

  setInternalNotes = (event) => {
    this.state.companyCopy.internalNotes = event.target.value;
    this.updateState();
  }

  enableInternalNotes = () => {
    this.setState({internalNotesEnabled: true});
  }

  enableAdminNotes = () => {
    this.setState({adminNotesEnabled: true});
  }

  setAdminNotes = (event) => {
    this.state.companyCopy.adminNotes = event.target.value;
    this.updateState();
  }

  saveInternalNotes = () => {
    // Load the record and set its internal notes.
    let suggestedCompany = this.suggestedCompanyRepository.getSuggestedCompanyById(this.state.companyCopy.id);
    suggestedCompany.setInternalNotes(this.state.companyCopy.internalNotes);

    this.suggestedCompanyRepository.persist(
      suggestedCompany,
      'The internal notes have been updated',
      () => {
        this.setState({internalNotesEnabled: false});
      }
    );
  }

  saveAdminNotes = () => {
    // We need to use the suggested company service because we need to send emails, etc.
    // Note: I'm being naughty and instantiating the service here - it should really be injected.
    let suggestedCompanyService = new SuggestedCompanyService(this.suggestedCompanyRepository, this.companyRepository);
    suggestedCompanyService.saveAdminNotes(
      this.state.companyCopy.id,
      this.state.companyCopy.adminNotes,
      'The admin notes have been updated',
      () => {
        this.setState({adminNotesEnabled: false});
      }
    );
  }

  additionalFields() {
    let finalFields = [];
    let additionalFields = <FieldTextArea key="additionalNotes" isViewScreen={this.isViewScreen() || !this.isPublicFacing()} label={'Additional Details'+(this.isPublicFacing()?'':' (from suggester)')} placeholder="Enter any additional details you think may be helpful" value={this.state.companyCopy.suggesterAdditionalDetails} required={this.fieldAttributes.isRequired('additionalDetails')} onchange={this.setAdditionalDetails}/>;
    if (this.isPublicFacing()) {
      if (this.isViewScreen()) {
        if (this.state.companyCopy.status !== SuggestedCompanyStatuses.submitted) {
          finalFields.push(<FieldTextArea key="adminNotesReadOnly" isViewScreen={true}
                                          label="Admin Notes (public)"
                                          value={this.state.companyCopy.adminNotes}/>);
          finalFields.push(additionalFields);
          finalFields.push(<FieldTextArea key="addToAdditionalDetails" label="Add To Additional Details"
                             textClass="addToAdditionalDetails"
                             placeholder="Enter any more additional details"
                             value={this.state.companyCopy.moreAdditionalDetails} required={false}
                             onchange={this.setMoreAdditionalDetails}/>);
        } else {
          finalFields.push(additionalFields);
        }
      } else {
        finalFields.push(additionalFields);
        finalFields.push(
          <div className="row" key="recaptcha-final">
            <div className="col-md-2"/>
            <div className="col-md-10">
              <div>
                <Recaptcha key="recaptcha" onVerify={this.recaptchaVerified} onExpire={this.recaptchaExpired}/>
              </div>
            </div>
          </div>
        );
      }
    } else {
      finalFields.push(<FieldText key="status" isViewScreen="true" label="Status" value={this.state.companyCopy.status} required={true}/>);
      if (this.company.getGeneratedCompanyID()) {
        // A company has been generated from this suggestion - show a link to it.
        let generatedCompany = this.companyRepository.getCompanyById(this.company.getGeneratedCompanyID());
        finalFields.push(
          <div key="generatedCompanyLink" className="row">
            <div className="col-md-2">Generated Company</div>
            <div className="col-md-10">
              <Link key="generatedCompanyLink" to={`/admin/companies/edit/${this.company.getGeneratedCompanyID()}`}>{generatedCompany.getName()}</Link>
            </div>
          </div>
        );
      }
      finalFields.push(<FieldText key="createAt" isViewScreen="true" label="Suggested At" value={this.state.companyCopy.createdAt.toLocaleString()} required={true}/>);
      finalFields.push(additionalFields);

      // Internal notes. We are in the admin screen. If the record is being edited, the notes are read-only. Otherwise,
      // we either show the "add/edit notes" button or show a textarea with the notes. The state holds which one we
      // are currently doing.
      // Note: I'm not sure why, but if you don't change the key value when edit mode is toggled, nothing happens!
      let internalNotesAreInEditMode = this.isViewScreen() && this.state.internalNotesEnabled;
      let internalNotesKey = 'internalNotes'+(internalNotesAreInEditMode ? 'Edit' : 'ReadOnly');
      finalFields.push(<FieldTextArea key={internalNotesKey} isViewScreen={!internalNotesAreInEditMode}
                                      label="Internal Notes (private)" placeholder="Enter any internal notes (not visible to suggester)"
                                      value={this.state.companyCopy.internalNotes} required={this.fieldAttributes.isRequired('internalNotes')}
                                      onchange={this.setInternalNotes}/>);
      if (this.isViewScreen()) {
        let internalClassNames = 'btn-outline internal-notes';
        let internalKey = 'internalNotesButton';
        if (this.state.internalNotesEnabled) {
          finalFields.push(<div key={internalKey} className={internalClassNames} onClick={this.saveInternalNotes}>Save InternalNotes</div>);
        } else {
          finalFields.push(<div key={internalKey} className={internalClassNames} onClick={this.enableInternalNotes}>Add/Edit Internal Notes</div>);
        }
      }

      // Admin notes. We are in the admin screen. If the record is being edited, the notes are read-only. Otherwise,
      // we either show the "add/edit notes" button or show a textarea with the notes. The state holds which one we
      // are currently doing.
      // Note: I'm not sure why, but if you don't change the key value when edit mode is toggled, nothing happens!
      let adminNotesAreInEditMode = this.isViewScreen() && this.state.adminNotesEnabled;
      let adminNotesKey = 'adminNotes'+(adminNotesAreInEditMode ? 'Edit' : 'ReadOnly');
      finalFields.push(<FieldTextArea key={adminNotesKey} isViewScreen={!adminNotesAreInEditMode}
                                      label="Admin Notes (public)" placeholder="Enter any notes for the admins"
                                      value={this.state.companyCopy.adminNotes} required={this.fieldAttributes.isRequired('adminNotes')}
                                      onchange={this.setAdminNotes}/>);
      if (this.isViewScreen()) {
        let adminClassNames = 'btn-outline admin-notes';
        let adminKey = 'adminNotesButton';
        if (this.state.adminNotesEnabled) {
          finalFields.push(<div key={adminKey} className={adminClassNames} onClick={this.saveAdminNotes}>Save Admin Notes</div>);
        } else {
          finalFields.push(<div key={adminKey} className={adminClassNames} onClick={this.enableAdminNotes}>Add/Edit Admin Notes</div>);
        }
      }
    }

    return (
      <div>
        <FieldTextArea isViewScreen={this.isViewScreen() || !this.isPublicFacing()} label="Reason" placeholder="How is this company ethical?" value={this.state.companyCopy.suggesterReason} required={this.fieldAttributes.isRequired('reason')} onchange={this.setReason}/>
        <h3>{this.isPublicFacing() ? 'Your' : 'Suggester'} Details</h3>
        <FieldText isViewScreen={this.isViewScreen()} label="Given Name" value={this.state.companyCopy.suggesterGivenName} required={this.fieldAttributes.isRequired('suggesterGivenName')} onchange={this.setGivenName}/>
        <FieldText isViewScreen={this.isViewScreen()} label="Family Name" value={this.state.companyCopy.suggesterFamilyName} required={this.fieldAttributes.isRequired('suggesterFamilyName')} onchange={this.setFamilyName}/>
        <FieldText isViewScreen={this.isViewScreen()} label="Phone No." value={this.state.companyCopy.suggesterPhoneNumber} required={this.fieldAttributes.isRequired('suggesterPhone')} onchange={this.setPhoneNumber}/>
        <FieldText isViewScreen={this.isViewScreen()} label="Email" value={this.state.companyCopy.suggesterEmailAddress} required={this.fieldAttributes.isRequired('suggesterEmail')} onchange={this.setEmailAddress}/>
        {finalFields}
      </div>
    )
  }
}

export default AbstractSuggestedCompanyMaintenance;
