import React from "react";
import UrlHelperService from "../../services/UrlHelperService";

class Company extends React.Component {
  constructor(props) {
    super(props);

    this.companyRepository = this.props.companyRepository;
    this.company = this.companyRepository.getCompanyById(this.props.match.params.id);
    this.urlHelperService = new UrlHelperService();
  }

  render() {
    if (this.company === null) {
      return <p>Company not found</p>;
    }

    let imgSrc = this.company.getImage().hasUrl() ? this.company.getImage().getUrl() : '/images/noimage.png';

    return (
      <div className="row company-detail">
        <div className="col-md-3">
          <div className="image-container">
            <img src={`${imgSrc}`} className="img-responsive"
                 alt={this.company.getName()}/>
          </div>
        </div>

        <div className="col-md-9">
          <div className="row">
            <div className="col-md-9">
              <h2>{this.company.getName()}</h2>
            </div>
            <div className="col-md-3 company-link">
              {
                this.company.hasLinkUrl() &&
                <a
                  href={this.urlHelperService.makeFullUrl(this.company.getLinkUrl())}
                  target="_blank"
                  className="btn-outline"
                  rel="noopener noreferrer"
                >
                  Visit website <span className="glyphicon glyphicon-new-window" aria-hidden="true"/>
                </a>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {this.company.getDescription()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Company;
