import React from "react";
import FieldText from "../FormWidgets/FieldText";
import Tag from "../../../models/Tag";
import ErrorBlock from "../FormWidgets/ErrorBlock";

class TagEdit extends React.Component {
  constructor(props) {
    super(props);

    this.tag = this.props.tagRepository.getTagById(this.props.match.params.id);
    this.tagCompanies = this.props.companyRepository.getByTagId(this.tag.getId());

    this.state = {
      tagCopy: {
        id: this.tag.getId(),
        name: this.tag.getName()
      },
      errors: []
    };
  }

  setName = event => {
    let tagCopy = this.state.tagCopy;
    tagCopy.name = event.target.value;
    this.setState({tagCopy: tagCopy});
  }

  saveTag = event => {
    event.preventDefault();
    let tagToSave = new Tag(this.state.tagCopy);
    let result = this.props.tagRepository.persist(
      tagToSave,
      'The tag was saved!',
      (recordId) => {
        this.props.history.push('/admin/tags/list'); // @TODO: We should make this URL a constant somewhere.
      }
    );
    this.setState({errors: result.getErrors()});
    // If there are any warnings, just alert them, but the processing won't be aborted.
    if (result.hasWarnings()) {
      result.getWarnings().forEach(
        function (warningMessage) {
          alert('Warning: '+warningMessage);
        }
      );
    }
  }

  render() {
    return (
      <div className="edit">
        <h2>Edit Tag - {this.tag.getName()}</h2>
        <p>This tag is used in {this.tagCompanies.length} compan(ies).</p>
        <FieldText label="Name" value={this.state.tagCopy.name} required={true} onchange={this.setName}/>
        <ErrorBlock errorMessages={this.state.errors}/>
        <div className="row">
          <div className="col-md-2">
            <input type="button" value="Save" className="btn btn-primary" onClick={this.saveTag}/>
          </div>
        </div>
      </div>
    )
  }
}

export default TagEdit;
