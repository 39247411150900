/**
 * This is a helper class that holds the allowed numbers of records per page, the current setting and the actual
 * number of records shown.
 */
class NumberOfDisplayedRecords {
  constructor(allowedPageSizes, currentState) {
    // @TODO: We need to verify that allowedPageSizes is an array of integers, with a zero-based index.
    // @TODO: And that if initialPageSize is passed in, that it is one of the values in the array.
    this.allowedPageSizes = allowedPageSizes;
    // This call initialises the number of displayed records.
    this.setPageSize(currentState && currentState.hasOwnProperty('pageSize') ? currentState.pageSize : this.allowedPageSizes[0]);
    if (currentState && currentState.hasOwnProperty('numDisplayedRecords')) {
      this.numDisplayedRecords = currentState.numDisplayedRecords;
    }
  }

  /**
   * Because components must supply a new object to setState(), we need a new copy of this object when anything changes.
   * So, this method returns a new object with the same values that are currently in the object. The idea is that the
   * code calls the appropriate setter(s) with the new values, then calls this copy method and passes the result in to
   * setState().
   *
   * @return {NumberOfDisplayedRecords}
   */
  getCopy() {
    return new NumberOfDisplayedRecords(
      this.allowedPageSizes,
      {
        pageSize: this.pageSize,
        numDisplayedRecords: this.numDisplayedRecords
      }
    )
  }

  setPageSize(newSize) {
    this.pageSize = newSize;
    this.numDisplayedRecords = this.pageSize;
  }

  getAllowedPageSizes() {
    return this.allowedPageSizes;
  }

  isSelected(pageSize) {
    return pageSize === this.pageSize;
  }

  getCurrentPageSize() {
    return this.pageSize;
  }

  getNumDisplayedRecords() {
    return this.numDisplayedRecords;
  }

  /**
   * This effectively does a "next page", except everything is within one page. It adds the current page size on to the
   * current number of records displayed. Eg if page size is 5 and 15 records are currently showing, it'll increase
   * that to 20 records.
   */
  incrementNumDisplayedRecords() {
    this.numDisplayedRecords += this.pageSize;
  }

  /**
   * Sometimes we need to reset the number of displayed records, in which case we have to set them to the page size.
   * Ie we show just the first page.
   */
  reset() {
    this.numDisplayedRecords = this.pageSize;
  }
}

export default NumberOfDisplayedRecords;
