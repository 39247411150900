import React from "react";

class MainLogo extends React.Component {
  render() {
    return (
      <img className="main-logo" src="/images/source_right_logo.png" alt="SourceRight"/>
    );
  }
}

export default MainLogo;

