import {CollectionNames} from "./Constants";
import AbstractDataRepository from "./AbstractDataRepository";
import Tag from "../models/Tag";

/**
 * This class is the interface between the tag data and the storage engine (at time of writing, firebase).
 * It is instantiated when the data repository factory determines the database is ready. The raw data is passed in to
 * the constructor.
 */
class TagRepository extends AbstractDataRepository {
  /**
   * The constructor receives raw data from the database, representing the raw tag data.
   * @param dataSets
   * @param {StorageRepository} storageRepository
   * @param rawTags
   */
  constructor(dataSets, storageRepository, rawTags) {
    super(dataSets, storageRepository);
    this.replaceDataset(rawTags);
  }

  replaceDataset(rawTags) {
    this.tags = {};
    for (let tagRef in rawTags) {
      if (rawTags.hasOwnProperty(tagRef)) {
        let oneTag = rawTags[tagRef];
        this.tags[tagRef] = new Tag(oneTag);
      }
    }
  }

  /**
   * @param {TagValidator} tagValidator
   */
  setTagValidator(tagValidator) {
    this.tagValidator = tagValidator;
  }

  getTags(excludeTagID) {
    // Because this.tags is an object pretending to be an array, we need to convert it to a proper array before
    // returning it. Note the firebase keys will get replaced with a simple zero-based index.
    let result = [];
    this.forEachRecord(
      this.tags,
      function(tag) {
        if (excludeTagID === undefined || tag.getId() !== excludeTagID) {
          result.push(tag);
        }
      }
    );

    return result;
  }

  getTagById(tagId) {
    tagId = parseInt(tagId);
    let matching = this.filterRecords(
      this.tags,
      function (tag) {
        return tagId === tag.getId();
      }
    )

    if (matching.length === 1) {
      return matching.shift(); // Take the first one (index not necessarily 0)
    }

    return null;
  };

  getTagsByName(name) {
    name = name.toLowerCase(); // Case insensitive search!

    return this.filterRecords(
      this.tags,
      function (tag) {
        return name === tag.getName().toLowerCase();
      }
    )
  }

  /**
   * Save the given tag back to the data store.
   *
   * @param tag {Tag}
   * @param successMsg {string|null}
   * @param callback {Function|null}
   * @return {ValidationResult}
   */
  persist(tag, successMsg, callback) {
    let result = this.tagValidator.validate(tag);
    if (!result.isValid()) {
      return result;
    }

    if (tag.hasId()) {

      // This is an update.
      // Determine the index of the record in the array from the model's id.
      let tagIndex = this.determineIndexFromTagModel(tag);
      // Create a reference to the object in the array.
      let tagSource = this.tags[tagIndex];
      // @TODO: I wonder if the original version of the record should be in the model, before the model constructor
      // @TODO: does any data migrations (adding columns, etc).
      let originalTag = Object.assign({}, tagSource);
      // Overwrite the record with the values from the model. It is a bit yucky because the image value has to be the
      // raw value that firebase will accept, but we need to convert it back to an object afterwards, hence wrapping
      // any callback function pass in with a function that will do that conversion.
      this.populateRecordFromTagModel(tagSource, tag);
      // Save the record back to the store.
      this.update(CollectionNames.tags, tagIndex, successMsg, callback, tag.getId(), originalTag, tagSource);

    } else {

      // This is an insert. Generate a new id and start a new object.
      tag.setId(this.generateNextTagId());
      let newTag = {
        id: tag.getId()
      };
      // Populate the record with the values from the model.
      this.populateRecordFromTagModel(newTag, tag);
      // Create the record in the store.
      this.insert(CollectionNames.tags, newTag, successMsg, callback, tag.getId());

    }

    return result;
  };

  generateNextTagId() {
    let id = 0;
    this.forEachRecord(
      this.tags,
      function(tag) {
        id = Math.max(id, tag.id);
      }
    );
    ++id;

    return id;
  };

  determineIndexFromTagModel(tagModel) {
    // Determine the index of the firebase array, using the tag model's id.
    let tagIndex = null;
    this.forEachRecord(
      this.tags,
      function (tag, index) {
        if (tag.id === tagModel.getId()) {
          tagIndex = index;
        }
      }
    );

    return tagIndex;
  };

  populateRecordFromTagModel(tagRecord, tag) {
    tagRecord.name = tag.getName();
  };

  /**
   * A fudge to add any newly created record on to the list of tag models in this repository.
   * @param newKey
   * @param record
   */
  afterInsert(newKey, record) {
    this.tags[newKey] = new Tag(record);
  }

  deleteTag(tag, successMsg, callback) {
    let result = this.tagValidator.validateDelete(tag);

    if (result.isValid()) {
      this.delete(CollectionNames.tags, this.determineIndexFromTagModel(tag), successMsg, callback);
    }

    return result;
  }
}

export default TagRepository;
