import React from 'react';
import CategoryListOne from "./CategoryListOne";
import {Link} from "react-router-dom";
import ListSearchBox from "../Common/ListSearchBox";
import TextSearch from "../../Contexts/TextSearch";

class CategoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      availableCategories: this.getCategories(),
      textFilter: new TextSearch(JSON.parse(localStorage.getItem('categoryListTextSearch'))),
      onlySeasonal: localStorage.getItem('categoryListOnlySeasonal') === 'true',
      categoryRepository: this.props.categoryRepository
    };
  }

  reload = event => {
    this.setState({availableCategories: this.getCategories()});
  }

  getCategories() {
    // @TODO: It would be nice to have a utility class that did this sorting for us.
    return this.props.categoryRepository.getCategories().sort(
      function(category1, category2) {
        return category1.getName().toLowerCase() > category2.getName().toLowerCase() ? 1 : -1
      }
    );
  }


  onSearchBoxChange = event => {
    // I'm assuming a case-insensitive search.
    this.state.textFilter.setTextFilter(event.target.value.toLowerCase());
    this.setState(
      {
        textFilter: this.state.textFilter
      },
      () => {
        // Save the new state of the search box into local storage.
        localStorage.setItem('categoryListTextSearch', JSON.stringify(this.state.textFilter))
      }
    )
  }

  onOnlySeasonalChange = event => {
    this.setState(
      {
        onlySeasonal: event.target.checked
      },
      () => {
        // Save the new state of the search box into local storage.
        localStorage.setItem('categoryListOnlySeasonal', JSON.stringify(this.state.onlySeasonal))
      }
    )
  }

  render() {
    let filteredCategories = this.state.availableCategories;
    let textFilter = this.state.textFilter.getTextFilter();
    if (textFilter) {
      filteredCategories = filteredCategories.filter(
        function (category) {
          return category.getName().toLowerCase().indexOf(textFilter) !== -1;
        }
      )
    }
    if (this.state.onlySeasonal) {
      filteredCategories = filteredCategories.filter(
        function (category) {
          return category.getIsSeasonalCategory();
        }
      )
    }
    let categories = [];
    filteredCategories.forEach(
      (category) => {
        categories.push(<CategoryListOne key={category.getId()} categoryRepository={this.state.categoryRepository} category={category} reload={this.reload}/>);
      }
    );

    return (
      <div>
        <div className="search-container">
          <div className="pull-right list-top-right-options">
            <label><input type="checkbox" value="1" checked={this.state.onlySeasonal === true} onChange={this.onOnlySeasonalChange}/> Only Seasonal</label>
            <ListSearchBox onchange={this.onSearchBoxChange} initialValue={this.state.textFilter.getTextFilter()}/>
            <Link className='btn btn-primary' to='/admin/categories/add'>Add new</Link>
          </div>
        </div>
        {categories}
      </div>
    )
  }
}

export default CategoryList;
