/**
 * This constant represents the locations where the given types of object are stored.
 * @TODO: This was copied from the original app, but we are removing brands and products, but adding companies.
 *
 * @type {{category: string}}
 */
export const UploadFolderNames = {
  /*brand: 'Brand',*/
  category: 'Category',
  company: 'Company',
  suggestedCompany: 'SuggestedCompany',/*
  product: 'Product'*/
  tag: 'tag'
};
