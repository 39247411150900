import ValidationResult from "./ValidationResult";

class SubscriberValidator {
  /**
   * @param {SubscriberRepository} subscriberRepository
   */
  constructor(subscriberRepository) {
    this.subscriberRepository = subscriberRepository;
  }

  /**
   * @param {Subscriber} subscriber
   */
  validate(subscriber) {
    // There is currently no validation.
    return new ValidationResult();
  }
}

export default SubscriberValidator;
