class Tag {
  constructor(data) {
    this.id = data.hasOwnProperty('id') && data.id !== null ? parseInt(data.id) : null;
    this.name = data.hasOwnProperty('name') ? data.name : null;
  };

  getId() {
    return this.id;
  };

  hasId() {
    return this.id !== null;
  };

  setId(newId) {
    this.id = newId;
  };

  getName() {
    return this.name;
  };

  hasName() {
    return this.name !== null && this.name !== '';
  };

  setName(name) {
    this.name = name;
  };
}

export default Tag;
