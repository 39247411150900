import React from "react";

class PageSizeOption extends React.Component {
  render() {
    let optionClassName = 'page-size-option'+(this.props.isSelected ? ' selected' : '');
    return (
      <div className={optionClassName} onClick={() => this.props.clickHandler(this.props.pageSize)}>{this.props.pageSize}</div>
    )
  }
}

export default PageSizeOption;
