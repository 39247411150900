import React from "react";
import AbstractCategoryMaintenance from "./AbstractCategoryMaintenance";

class CategoryEdit extends AbstractCategoryMaintenance {
  getInitialCategory() {
    return this.categoryRepository.getCategoryById(this.props.match.params.id);
  }

  render() {
    return (
      <div className="edit">
        <h2>Edit Category - {this.state.category.getName()}</h2>
        {this.maintenanceForm()}
      </div>
    )
  }
}

export default CategoryEdit;
