import React from "react";
import CategoryDetailThumbnail from "./CategoryDetailThumbnail";
import CompaniesForCategory from "./CompaniesForCategory";

class ShowCategory extends React.Component {
  constructor(props) {
    super(props);
    // Because sometimes pages higher in the hierarchy need to reset this number, it's actually passed in to this
    // component as "numberOfCompaniesDisplayedForCategory".

    this.categoryRepository = this.props.categoryRepository;
    this.companyRepository = this.props.companyRepository;
    let category = this.categoryRepository.getCategoryById(this.props.match.params.id);
    let companies = [];
    if (category) {
      companies = this.companyRepository.getCompaniesForCategory(category);
    }

    this.state = {
      category: category,
      companies: companies
    };
  }

  render() {
    if (this.state.category === null) {
      return (
        <div className="col-md-12 thumbnail">
          No Category Details Found
        </div>
      )
    }

    return (
      <div>
        <CategoryDetailThumbnail category={this.state.category}/>
        <CompaniesForCategory category={this.state.category} companies={this.state.companies} numberOfCompaniesDisplayedForCategory={this.props.numberOfCompaniesDisplayedForCategory}/>
      </div>
    )
  }
}

export default ShowCategory;
