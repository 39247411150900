import React from "react";

class OneLozenge extends React.Component {
  render() {
    let clickHandler = this.props.clickHandler ? this.props.clickHandler : () => {};
    let lozengeClasses = "lozenge";
    let iconClasses = "";
    if (this.props.icon) {
      iconClasses += "lozenge-action glyphicon glyphicon-"+this.props.icon;
    } else {
      lozengeClasses += ' no-action';
    }
    let hint = this.props.hint ? this.props.hint : null;

    return (
      <div className={lozengeClasses}>
        {this.props.value}<span onClick={clickHandler} className={iconClasses} aria-hidden="true" title={hint}/>
      </div>
    );
  }
}

export default OneLozenge;
