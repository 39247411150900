class Certification {
  constructor(data) {
    let self = this;
    this.name = data.hasOwnProperty('name') ? data.name : null;
    this.paragraphs = [];
    if (data.hasOwnProperty('paragraphs') && Array.isArray(data.paragraphs)) {
      data.paragraphs.forEach(
        function (oneParagraph) {
          if (typeof oneParagraph === 'string') {
            self.paragraphs.push(oneParagraph);
          }
        }
      );
    }

    this.headingUrl = data.hasOwnProperty('headingUrl') ? data.headingUrl : null;
    this.images = [];
    if (data.hasOwnProperty('images') && Array.isArray(data.images)) {
      data.images.forEach(
        function (oneImage) {
          if (typeof oneImage === 'string') {
            self.images.push(oneImage);
          }
        }
      );
    }
  }

  getName() {
    return this.name;
  };

  getParagraphs() {
    return this.paragraphs;
  };

  getHeadingUrl() {
    return this.headingUrl;
  };

  hasImages() {
    return this.images.length > 0;
  };

  getImages() {
    return this.images;
  };
}

export default Certification;
