import React from 'react';
import CompanyListOne from "./CompanyListOne";
import {Link} from "react-router-dom";
import CompanyListHeadings from "./CompanyListHeadings";
import ListSearchBox from "../Common/ListSearchBox";
import ShowMoreButton from "../../Common/ShowMoreButton";
import NumberOfDisplayedRecords from "../../Common/NumberOfDisplayedRecords";
import PageSizeOptions from "../../Common/PageSizeOptions";
import CategoryFilter from "../Common/CategoryFilter";
import OneLozenge from "../FormWidgets/OneLozenge";
import LabelLookup from "../../../services/LabelLookup";
import TextSearch from "../../Contexts/TextSearch";
import IdFilter from "../../Contexts/IdFilter";

class CompanyList extends React.Component {
  constructor(props) {
    super(props);
    let numberOfDisplayedRecords = new NumberOfDisplayedRecords(
      [5, 10, 15],
      JSON.parse(localStorage.getItem('companyListNumRecordsToDisplay'))
    );
    // @TODO: It would be nice to have a utility class that did this sorting for us.
    this.availableCompanies = this.props.companyRepository.getCompanies().sort(
      function(company1, company2) {
        return company1.getName().toLowerCase() > company2.getName().toLowerCase() ? 1 : -1
      }
    );

    this.categoriesById = new LabelLookup(
      this.props.categoryRepository.getCategories(),
      (category) => {return category.getId()},
      (category) => {return category.getName()}
    )
    this.tagsById = new LabelLookup(
      this.props.tagRepository.getTags(),
      (tag) => {return tag.getId()},
      (tag) => {return tag.getName()}
    )

    this.state = {
      textFilter: new TextSearch(JSON.parse(localStorage.getItem('companyListTextSearch'))),
      displayedRecordsHandler: numberOfDisplayedRecords,
      filterCategoryIds: new IdFilter(JSON.parse(localStorage.getItem('companyListCategoryFilter'))),
      showCategoryFilter: false
    };
  }

  toggleShowFilter = () => {
    this.setState({showCategoryFilter: !this.state.showCategoryFilter});
}

  toggleFilterId = categoryId => {
    this.state.filterCategoryIds.toggleFilterId(categoryId);
    this.setState(
      {
        filterCategoryIds: new IdFilter({idList: this.state.filterCategoryIds.getIds()})
      },
      () => {
        // Save the new state of the search box into local storage.
        localStorage.setItem('companyListCategoryFilter', JSON.stringify(this.state.filterCategoryIds))
      }
    );
  }

  onSearchChange = event => {
    // I'm assuming a case-insensitive search.
    this.state.textFilter.setTextFilter(event.target.value.toLowerCase());
    this.setState(
      {
        textFilter: this.state.textFilter
      },
      () => {
        // Save the new state of the search box into local storage.
        localStorage.setItem('companyListTextSearch', JSON.stringify(this.state.textFilter))
      }
    )
  }

  handlePageSizeChange = newPageSize => {
    if (newPageSize !== this.state.displayedRecordsHandler.getCurrentPageSize()) {
      // Update the object, then ask the object for a copy of itself that we can pass in to the setState method.
      this.state.displayedRecordsHandler.setPageSize(newPageSize)
      this.displayedRecordsContextHasChanged();
    }
  }

  handleMoreClick = () => {
    this.state.displayedRecordsHandler.incrementNumDisplayedRecords();
    this.displayedRecordsContextHasChanged();
  }

  displayedRecordsContextHasChanged() {
    let displayedRecordsHandler = this.state.displayedRecordsHandler.getCopy();
    this.setState(
      {
        displayedRecordsHandler: displayedRecordsHandler
      },
      () => {
        // Save the new state of the number of records to display into local storage.
        localStorage.setItem('companyListNumRecordsToDisplay', JSON.stringify(displayedRecordsHandler))
      }
    );
  }

  render() {
    let filteredCompanies = this.availableCompanies;
    let self = this;
    // Do the "text" filtering.
    let textFilter = this.state.textFilter.getTextFilter();
    if (textFilter) {
      filteredCompanies = filteredCompanies.filter(
        function (company) {
          return company.getName().toLowerCase().indexOf(textFilter) !== -1;
        }
      )
    }
    // Do the category filtering.
    if (this.state.filterCategoryIds.hasIds()) {
      filteredCompanies = filteredCompanies.filter(
        function (company) {
          let isMatch = false;
          company.getCategoryIds().forEach(
            function (companyCategoryId) {
              isMatch = isMatch || self.state.filterCategoryIds.hasId(companyCategoryId);
            }
          )
          return isMatch;
        }
      )
    }
    // Restrict the output to the number of records that are actually displayed.
    let numBeforePageRestriction = filteredCompanies.length;
    filteredCompanies = filteredCompanies.slice(0, this.state.displayedRecordsHandler.getNumDisplayedRecords());
    let companies = [];
    filteredCompanies.forEach(
      (company) => {
        companies.push(<CompanyListOne key={company.getId()} company={company} categoriesById={this.categoriesById} tagsById={this.tagsById}/>);
      }
    );

    // Show the selected categories as lozenges.
    let categoryFilters = [];
    this.state.filterCategoryIds.getIds().forEach(
      function (categoryId) {
        categoryFilters.push(
          <OneLozenge key={categoryId} clickHandler={() => self.toggleFilterId(categoryId)} value={self.categoriesById.getLabelFor(categoryId)} icon="remove" hint="Remove this category from the filter"/>
        )
      }
    )

    return (
      <div className="companies-list">
        <div className="search-container">
          <div className="pull-right list-top-right-options">
            <ListSearchBox onchange={this.onSearchChange} initialValue={this.state.textFilter.getTextFilter()}/>
            <Link className='btn btn-primary' to='/admin/companies/add'>Add new</Link>
          </div>
          <div className="pull-left list-top-left-options">
            <div className="show-hide-category-filter" onClick={this.toggleShowFilter}>Filter</div>
            <div>TOTAL {this.availableCompanies.length} companies</div>
            <PageSizeOptions pageSizeHandler={this.state.displayedRecordsHandler} changeHandler={this.handlePageSizeChange}/>
          </div>
        </div>
        {categoryFilters &&
        <div className="selected-categories">
          {categoryFilters}
        </div>
        }
        <CategoryFilter visible={this.state.showCategoryFilter} categoryRepository={this.props.categoryRepository} selectedIds={this.state.filterCategoryIds.getIds()} toggleFilterId={this.toggleFilterId} toggleVisibility={this.toggleShowFilter}/>
        <CompanyListHeadings/>
        {companies}
        {numBeforePageRestriction > filteredCompanies.length ? <div><ShowMoreButton clickHandler={this.handleMoreClick}/></div> : null}
      </div>
    )
  }
}

export default CompanyList;
