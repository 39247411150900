import React from "react";
import AbstractSuggestedCompanyMaintenance from "./AbstractSuggestedCompanyMaintenance";
import {SuggestedCompanyStatuses} from "../../../../models/Statuses/SuggestedCompanyStatuses";
import SuggestedCompanyService from "../../../../services/SuggestedCompanyService";
import {Link} from "react-router-dom";

class SuggestedCompanyReview extends AbstractSuggestedCompanyMaintenance {
  constructor(props) {
    super(props);

    this.state.confirmationPopup = null; // This determines which confirmation message to show, if any.
  }

  getInitialCompany() {
    // Note: we can't use "this.suggestedCompanyRepository" as it hasn't been initialised at this point.
    return this.props.suggestedCompanyRepository.getSuggestedCompanyById(this.props.match.params.id);
  }

  isSubmittedSuggestedCompany() {
    return true;
  }

  isViewScreen() {
    return true;
  }

  isPublicFacing() {
    return false;
  }

  confirmApprove = () => {
    // Validate that the company suggestion is in a valid state where it can be converted to a company. Show the
    // reasons why, if not. Bear in mind we have to pass the _suggested_ company into the _company_ validator.
    let result = this.companyRepository.getCompanyValidator().validate(this.company);
    if (result.isValid()) {
      this.confirmTransitionTo(SuggestedCompanyStatuses.approved);
    } else {
      window.alert('This suggestion can\'t be converted into a company because: '+result.getErrors());
    }
  }

  confirmReject = () => {
    this.confirmTransitionTo(SuggestedCompanyStatuses.rejected);
  }

  confirmUnreject = () => {
    this.confirmTransitionTo(SuggestedCompanyStatuses.pending);
  }

  confirmDelete = () => {
    this.confirmTransitionTo('Deleted');
  }

  confirmTransitionTo(newState) {
    this.setState({"confirmationPopup": newState});
  }

  cancelConfirmation = () => {
    this.setState({"confirmationPopup": null});
  }

  confirmed = () => {
    // Use the suggested company service to transition the record from one state to another. If we are going to "approved",
    // the process is not trivial, so it shouldn't be in a component (which is why it's in a service).
    // Note: I'm being naughty and instantiating the service here - it should really be injected.
    let suggestedCompanyService = new SuggestedCompanyService(this.suggestedCompanyRepository, this.companyRepository);
    let action = this.state.confirmationPopup === SuggestedCompanyStatuses.pending ? 'un-rejected' : this.state.confirmationPopup.toLowerCase();
    suggestedCompanyService.transition(
      this.state.companyCopy.id,
      this.state.confirmationPopup,
      'This suggestion has been '+action,
      () => {
        // Redirect back to the suggested company list.
        window.location.href = '/admin/suggestedCompanies/list';
      }
    );
  }

  render() {
    // @TODO: Think about putting this in a method.
    let confirmation = null;
    if (this.state.confirmationPopup) {
      let inlineText = '';
      let confirmButtonText = '';
      let canBeUndone = false;
      switch (this.state.confirmationPopup) {
        case SuggestedCompanyStatuses.approved:
          inlineText = 'approve';
          confirmButtonText = 'Approve';
          break;
        case SuggestedCompanyStatuses.rejected:
          inlineText = 'reject';
          confirmButtonText = 'Reject';
          canBeUndone = true;
          break;
        case SuggestedCompanyStatuses.pending:
          inlineText = 'un-reject';
          confirmButtonText = 'Un-reject';
          canBeUndone = true;
          break;
        case 'Deleted':
          inlineText = 'permanently delete';
          confirmButtonText = 'Delete';
          break;
        default:
          inlineText = '[unknown option]';
          confirmButtonText = 'Unknown';
          break
      }
      confirmation = [];
      confirmation.push(<div key="confirmation-background" className="confirmation-background" onClick={this.cancelConfirmation}/>);
      confirmation.push(<div key="confirmation" className="review-confirm">
        <p className="question">Are you sure you want to {inlineText} {this.state.company.getName()}?</p>
        {!canBeUndone && <p className="note">This cannot be undone</p>}
        <div className="row">
          <div className="col-md-3 btn-outline" onClick={this.confirmed}>{confirmButtonText}</div>
          <div className="col-md-3 btn-outline" onClick={this.cancelConfirmation}>Cancel</div>
        </div>
      </div>);
    }

    let options = [];
    // The allowed options depend on the status of the record.
    if (this.company.getStatus() === SuggestedCompanyStatuses.pending) {
      options.push(<div key="approve" className="approve" onClick={this.confirmApprove}><span className="glyphicon glyphicon-ok" aria-hidden="true"/>Approve</div>);
      options.push(<div key="reject" className="reject" onClick={this.confirmReject}><span className="glyphicon glyphicon-remove" aria-hidden="true"/>Reject</div>);
    } else {
      if (this.company.getStatus() === SuggestedCompanyStatuses.rejected) {
        options.push(<div key="unreject" className="unreject" onClick={this.confirmUnreject}><span className="glyphicon glyphicon-step-backward" aria-hidden="true"/>Un-reject</div>);
      }
    }
    if (this.company.getStatus() !== SuggestedCompanyStatuses.approved) {
      options.push(<div key="delete" className="delete" onClick={this.confirmDelete}><span className="glyphicon glyphicon-trash" aria-hidden="true"/>Permanently Delete</div>);
    }
    if (this.company.getStatus() === SuggestedCompanyStatuses.pending) {
      options.push(<Link key="edit" className="edit" to={`/admin/suggestedCompanies/edit/${this.company.getId()}`}><span className="glyphicon glyphicon-pencil" aria-hidden="true"/>Edit</Link>);
    }
    return (
      <div className="edit">
        <h2>Review a Suggested Company</h2>
        {confirmation}
        {options.length ? <div className="review-options">{options}</div> : null}
        <h3>Company Details</h3>
        {this.maintenanceForm()}
      </div>
    )
  }
}

export default SuggestedCompanyReview;
