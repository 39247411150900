import React from "react";
import {Link} from "react-router-dom";

class OneCategory extends React.Component {
  scrollToTop = () => {
    // Make sure the window scrolls to the top of the page when a category is chosen.
    window.scrollTo(0, 0);
  }

  render() {
    let imgSrc = this.props.category.getImage().hasUrl() ? this.props.category.getImage().getUrl() : '/images/noimage.png';
    return (
      <div className="thumbnail">
        <Link to={`/category/${this.props.category.getId()}`} onClick={this.scrollToTop}>
          <img src={`${imgSrc}`} alt={this.props.category.getName()}/>
        </Link>
      </div>
    )
  }
}

export default OneCategory;
