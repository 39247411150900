/**
 * This class is the interface between the UI and the subscriber repository
 */
import Subscriber from "../models/Subscriber";

class SubscriberService {
  /**
   * @param {SubscriberRepository} subscriberRepository
   */
  constructor(subscriberRepository) {
    this.subscriberRepository = subscriberRepository;
  }

  /**
   * This method subscribes a new user, or if the email address already exists, increments the count on that subscription.
   * @param {string} email
   * @param {string} subscriptionType
   * @param {string} successMsg
   * @param {Function|null} callback
   */
  subscribe(email, subscriptionType, successMsg, callback) {
    let thisSubscriber = this.subscriberRepository.findByEmail(email);
    if (thisSubscriber === null) {
      thisSubscriber = new Subscriber({
        email: email
      });
    }
    thisSubscriber.incrementRegistrationCount(subscriptionType);

    this.subscriberRepository.persist(thisSubscriber, successMsg, callback);
  }
}

export default SubscriberService;
