import ValidationResult from "./ValidationResult";

class CategoryValidator {
  /**
   * @param {CategoryRepository} categoryRepository
   * @param {CompanyRepository} companyRepository
   */
  constructor(categoryRepository, companyRepository) {
    this.categoryRepository = categoryRepository;
    this.companyRepository = companyRepository;
  }

  /**
   * @param {Category} category
   */
  validate(category) {
    let result = new ValidationResult();

    // Do the basic checks - are the mandatory fields entered?
    if (!category.hasName()) {
      result.addError('Category name is mandatory.');
    }
    if (!category.hasDescription()) {
      result.addError('Category description is mandatory.');
    }
    if (category.getImage().isEmpty()) {
      result.addError('Category image is mandatory.');
    }

    // There are some rules for the seasonal values.
    if (category.getIsSeasonalCategory()) {
      if (!category.hasSeasonalText()) {
        result.addError('Seasonal Text must be entered if this is a seasonal category');
      }
      if (!category.hasSeasonalButtonLabel()) {
        result.addError('Seasonal Button Label must be entered if this is a seasonal category');
      }
    } else {
      if (category.getIsCurrentSeasonalCategory()) {
        result.addError('A category cannot be the current seasonal category if it is not a seasonal category')
      }
    }

    return result;
  }
}

export default CategoryValidator;
