import React from "react";

class ShowMoreButton extends React.Component {
  render() {
    return (
      <div className="btn-outline more-button" onClick={this.props.clickHandler}>Show More</div>
    );
  }
}

export default ShowMoreButton;
