import React from "react";
import MandatoryFieldIndicator from "../FormWidgets/MandatoryFieldIndicator";
import {firebaseAuth} from "../../../dataStore/firebase";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.isAdd = (props.authUser === null || props.authUser === undefined);
    this.user = this.isAdd ? {email: '', displayName: ''} : props.authUser;

    this.state = {
      email: this.user.email,
      displayName: this.user.displayName,
      password: '',
      confirmPassword: ''
    };
  }

  setEmail = event => {
    this.setState({email: event.target.value})
  }

  setDisplayName = event => {
    this.setState({displayName: event.target.value})
  }

  setPassword = event => {
    this.setState({password: event.target.value})
  }

  setConfirmPassword = event => {
    this.setState({confirmPassword: event.target.value})
  }

  validateEmail(email) {
    if (email === '') {
      window.alert('Email is mandatory');

      return false;
    }

    return true;
  }

  saveEMail = event => {
    event.preventDefault();
    let self = this;
    if (this.validateEmail(this.state.email)) {
      this.user.updateEmail(this.state.email)
        .then(function() {
          if (self.props.onUserEdit) {
            self.props.onUserEdit();
          }
          window.alert('It seems to have been successful!');
        }).catch(function(error) {
          window.alert('An error occurred: '+error);
        });
    }
  }

  saveDisplayName = event => {
    event.preventDefault();
    let newDisplayName = this.state.displayName === '' ? null : this.state.displayName;
    let self = this;
    this.user.updateProfile({
      displayName: newDisplayName
    }).then(function() {
      if (self.props.onUserEdit) {
        self.props.onUserEdit();
      }
      window.alert('It seems to have been successful!');
    }).catch(function(error) {
      window.alert('An error occurred: '+error);
    });
  }

  savePassword = event => {
    event.preventDefault();

    let passwordIsValid = true;
    if (this.state.password === '') {
      passwordIsValid = false;
      window.alert('Password is mandatory');
    }
    if (this.state.confirmPassword === '') {
      passwordIsValid = false;
      window.alert('Confirm is mandatory');
    }
    if (passwordIsValid && this.state.password !== this.state.confirmPassword) {
      passwordIsValid = false;
      window.alert('Password and Confirm do not match');
    }

    if (passwordIsValid) {
      firebaseAuth.currentUser.updatePassword(this.state.password)
        .then(
          function () {
            window.alert('Your password was updated!');
          }
        )
        .catch(
          function (error) {
            window.alert('There was a problem updating your password: '+error);
          }
        )
    }
  }

  saveNewUser = event => {
    event.preventDefault();
    let newDisplayName = this.state.displayName === '' ? null : this.state.displayName;
    let newEmail = this.state.email;
    if (this.validateEmail(newEmail)) {
      // We will generate a random password, then create the user with that password, then send a "reset password" link
      // to that user, then (because it logs the new user in) we will log out.
      // In the middle of all that, if a display name was provided, update the new user's profile with that name.
      firebaseAuth.createUserWithEmailAndPassword(newEmail, 'new'+Math.floor(Math.random()*1e+8)+'password')
        .then(
          function() {

            if (newDisplayName) {
              firebaseAuth.currentUser.updateProfile({
                displayName: newDisplayName
              })
              .then(
                function () {
                }
              )
              .catch(
                function (error) {
                  window.alert('An error occurred when setting the display name (creation will continue): ' + error);
                }
              );
            }

            firebaseAuth.sendPasswordResetEmail(newEmail)
              .then(
                function() {
                  firebaseAuth.signOut()
                    .then(
                      function () {
                        window.alert('The new user has been created. Please log back in.');
                      }
                    )
                    .catch(
                      function(error) {
                        window.alert('There was an error when signing out (you are now logged in as the new user): '+error);
                      }
                    );
                }
              )
              .catch(
                function(error) {
                  window.alert('There was an error when sending the password reset email: '+error);
                }
              );
          }
        )
        .catch(
          function(error) {
            window.alert('There was an error when creating user: '+error);
          }
        )
    }
  }

  render() {
    return (
      <form className='profile-form'>
        <div className="row">
          <div className="col-md-6">
            <h2>{this.isAdd ? "Add a user" : "My Profile"}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">Email <MandatoryFieldIndicator/></div>
          <div className="col-md-3">
            <input
              name="email"
              value={this.state.email}
              onChange={this.setEmail}
              type="text"
              placeholder="Email Address"
            />
          </div>
          {!this.isAdd &&
          <div className="col-md-2">
            <button onClick={this.saveEMail}>Save Email</button>
          </div>}
        </div>
        <div className="row">
          <div className="col-md-2">Display Name (optional)</div>
          <div className="col-md-3">
            <input
              name="displayName"
              value={this.state.displayName ?? ''}
              onChange={this.setDisplayName}
              type="text"
              placeholder="Display Name"
            />
          </div>
          {!this.isAdd &&
          <div className="col-md-2">
            <button onClick={this.saveDisplayName}>Save Display Name</button>
          </div>}
        </div>
        {this.isAdd &&
        <div className="row">
          <div className="col-md-2"/>
          <div className="col-md-4">
            <button onClick={this.saveNewUser}>Add</button> Unfortunately, you will be logged out after this.
          </div>
        </div>
        }
        {!this.isAdd &&
        <div className="row">
          <div className="col-md-12">
            <h3>Change password</h3>
          </div>
        </div>
        }
        {!this.isAdd &&
        <div className="row">
          <div className="col-md-2">Password <MandatoryFieldIndicator/></div>
          <div className="col-md-3">
            <input
              name="password"
              value={this.state.password}
              onChange={this.setPassword}
              type="password"
              placeholder="Password"
            />
          </div>
        </div>
        }
        {!this.isAdd &&
        <div className="row">
          <div className="col-md-2">Confirm <MandatoryFieldIndicator/></div>
          <div className="col-md-3">
            <input
              name="confirm"
              value={this.state.confirmPassword}
              onChange={this.setConfirmPassword}
              type="password"
              placeholder="Confirm password"
            />
          </div>
          <div className="col-md-2">
            <button onClick={this.savePassword}>Save Password</button>
          </div>
        </div>
        }
      </form>
    )
  }
}

export default UserProfile;
