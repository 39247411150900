import React from 'react';
import SuggestedCompany from "../../models/SuggestedCompany";
import CommunicationsHelperService from "../../services/CommunicationsHelperService";
import AbstractSuggestedCompanyMaintenance from "../admin/companies/Suggested/AbstractSuggestedCompanyMaintenance";

class SuggestCompany extends AbstractSuggestedCompanyMaintenance {
  constructor(props) {
    super(props);

    this.state.companyCopy.verificationCode = this.company.extractVerificationCode();

    this.communicationsHelperService = new CommunicationsHelperService(); // @TODO: I'm not sure how best to get an instance of this service.
  }

  getInitialCompany() {
    return new SuggestedCompany({});
  }

  showImageChooserImage() {
    return false;
  }

  excludeSeasonalCategories() {
    return true;
  }

  allowAddCategory() {
    return false;
  }

  allowAddTag() {
    return false;
  }

  getSaveButtonInitialEnabledState() {
    return false;
  }

  setReason = event => {
    this.state.companyCopy.suggesterReason = event.target.value;
    this.updateState();
  }

  setAdditionalDetails = event => {
    this.state.companyCopy.suggesterAdditionalDetails = event.target.value;
    this.updateState();
  }

  prepareObjectToSave() {
    return new SuggestedCompany(this.state.companyCopy);
  }

  persistObject(objectToSave) {
    return this.suggestedCompanyRepository.persist(
      objectToSave,
      'Your suggestion was saved!',
      (recordId) => {
        // Reload the record so we can get its rowid (key).
        let suggestedCompany = this.suggestedCompanyRepository.getSuggestedCompanyById(recordId);
        // Send an email to the suggester here, with the URL and the verification code.
        this.communicationsHelperService.afterCreation(
          suggestedCompany,
          () => {
            // Redirect to a (mostly) read-only version of the page that shows the verification code popup.
            // @TODO: It would be nice to do this without causing it to reload the entire page.
            window.location.href = '/manageSuggestedCompany/'+suggestedCompany.getRowId();
          }
        );
      }
    );
  }

  verificationCodeForm() {
    return null;
  }

  recaptchaVerified = token => {
    this.setSaveButtonEnabled();
  }

  recaptchaExpired = () => {
    this.setSaveButtonDisabled();
  }

  render() {
    return (
      <div className="public-facing add">
        <h2>Suggest a Company</h2>
        {this.verificationCodeForm()}
        <h3>Company Details</h3>
        {this.maintenanceForm()}
      </div>
    )
  }
}

export default SuggestCompany;
