class EthicalFact {
  constructor(data) {
    this.logoFilename = data.hasOwnProperty('logoFilename') ? data.logoFilename : null;
    this.fact = data.hasOwnProperty('fact') ? data.fact : null;
  }

  getLogoFilename() {
    return this.logoFilename;
  }

  getFact() {
    return this.fact;
  }
}

export default EthicalFact;
