/**
 * This allows filtering on records (at time of writing, companies on the dashboard).
 * This is the initial version and might change.
 */
class DashboardCompanyFilter {
  constructor(currentState) {
    this.options = {
      latest5: 'latest 5',
      latest10: 'latest 10',
      latest20: 'latest 20',
      thisMonth: 'added this month',
      last4weeks: 'added in last 4 weeks'
    }

    // Initialise the filter. Use the value passed in, if there is one.
    let initialFilter = 'latest5';
    if (currentState !== null && currentState.hasOwnProperty('currentFilter')) {
      initialFilter = currentState.currentFilter;
    }
    // Set the properties to sensible defaults.
    this.limitCount = 5;
    this.cutoffDate = null;
    // Actually set the filter and the associated properties.
    this.setFilter(initialFilter);
  }

  setFilter(newFilter) {
    // Check the new filter value is valid.
    if (this.options.hasOwnProperty(newFilter)) {
      this.currentFilter = newFilter;
      if (this.currentFilter.indexOf('latest') === 0) {
        // The limit it a number of records.
        this.limitCount = parseInt(this.currentFilter.substr(6));
        this.cutoffDate = null;
      } else {
        // The limit is a number of days since the record was created.
        this.limitCount = null;
        this.cutoffDate = new Date();
        this.cutoffDate.setHours(0, 0, 0, 0); // Everything is based on midnight.
        if (newFilter === 'thisMonth') {
          // Cutoff is start of this month.
          this.cutoffDate.setDate(1);
        } else {
          // Cutoff is 28 days ago.
          this.cutoffDate.setDate(this.cutoffDate.getDate() - 28);
        }
      }
    }
  }

  getOptions() {
    return this.options;
  }

  isCurrentFilter(filter) {
    return filter === this.currentFilter;
  }

  isLimitedByCount() {
    return this.limitCount !== null
  }

  getLimitCount() {
    return this.limitCount
  }

  getDateCutoff() {
    return this.cutoffDate;
  }
}

export default DashboardCompanyFilter;
