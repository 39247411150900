import React from 'react';
import OneCertification from "./OneCertification";

class ShowCertifications extends React.Component {
  constructor(props) {
    super(props);

    this.certifications = props.certificationRepository.getCertificationsWithImages();
  }

  render() {
    let certifications = [];
    for(let certIdx in this.certifications) {
      if (this.certifications.hasOwnProperty(certIdx)) {
        /**
         * @var {Certification} certification
         */
        let certification = this.certifications[certIdx];
        certifications.push(<OneCertification key={certIdx} certification={certification} uniqueId={certIdx}/>);
      }
    }

    return (
      <div className="certifications row">
        <h2 className="certificationHeading">Certifications</h2>
        {certifications}
      </div>
    )
  }
}

export default ShowCertifications;
