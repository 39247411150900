import React from 'react';

class ErrorBlock extends React.Component {
  render() {
    let errorList = [];
    for(let errorIdx in this.props.errorMessages) {
      if (this.props.errorMessages.hasOwnProperty(errorIdx)) {
        errorList.push(<li key={errorIdx} className='error-message'>{this.props.errorMessages[errorIdx]}</li>);
      }
    }

    if (errorList.length === 0) {
      return null;
    }

    return (
      <div className='error-container'>
        <div className='error-prompt'>
          <span className="glyphicon glyphicon-alert" aria-hidden="true"></span>&nbsp;
          Please correct these errors and try again...
        </div>
        <ul>
          {errorList}
        </ul>
      </div>
    )
  }
}

export default ErrorBlock;
