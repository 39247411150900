import React from 'react';
import MandatoryFieldIndicator from "./MandatoryFieldIndicator";
import AbstractField from "./AbstractField";

class FieldText extends AbstractField {
  render() {
    let inputValue = this.props.value || '';
    let placeholderValue = this.props.placeholderValue || '';

    return (
      <div className="row">
          <div className="col-md-2">
            {this.props.label}{this.props.required && <MandatoryFieldIndicator/>}
          </div>
          <div className="col-md-10">
            {this.isViewScreen ?
              <div>{inputValue}</div>
              :
              <input type="text" value={inputValue} placeholder={placeholderValue} onChange={this.props.onchange}/>
            }
          </div>
      </div>
    )
  }
}

export default FieldText;
