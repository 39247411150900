class ValidationResult {
  constructor() {
    this.errorMessages = [];
    this.warningMessages = [];
  }

  /**
   * @param {String} errorMessage
   */
  addError(errorMessage) {
    this.errorMessages.push(errorMessage);
  }

  isValid() {
    return this.errorMessages.length === 0;
  }

  getErrors() {
    return this.errorMessages;
  }

  addWarning(warningMessage) {
    this.warningMessages.push(warningMessage);
  }

  hasWarnings() {
    return this.warningMessages.length > 0;
  }

  getWarnings() {
    return this.warningMessages;
  }
}

export default ValidationResult;
