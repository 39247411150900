class AbstractDataRepository {
  /**
   * @param recordSets
   * @param {StorageRepository} storageRepository
   */
  constructor(recordSets, storageRepository) {
    this.recordSets = recordSets;
    this.storageRepository = storageRepository;
  }

  // @TODO: I had to create this because JS/React can't do a "filter" on an array with string keys (well, it can, but it
  // @TODO: ignores those elements). This method emulates that method.
  filterRecords(records, callback) {
    let matching = [];
    Object.keys(records).forEach(
      function (key) {
        if (callback(records[key], key)) {
          matching.push(records[key]);
        }
      }
    );

    return matching;
  }

  // @TODO: We also need to emulate forEach(). See filterRecords above.
  forEachRecord(records, callback) {
    Object.keys(records).forEach(
      function (key) {
        callback(records[key], key);
      }
    );
  }

  /**
   * I haven't yet worked out how to automatically (and efficiently) refresh the model list in the repository after
   * an insert, so this method is called after an insert and it's up to each subclass to implement it and basically
   * instantiate an appropriate model and add it to its model list.
   * @param newKey
   * @param record
   */
  afterInsert(newKey, record) {
    window.alert('afterInsert: this method must be implemented in each repository!');
  }

  insert(collectionName, record, successMsg, callback, recordId) {
    let newKey = this.recordSets.ref(collectionName).push().key;
    let self = this;
    this.recordSets.ref(collectionName+'/'+newKey).set(
      record,
      function (error) {
        if (error) {
          window.alert('Error: ' + error.toString());
        } else {
          self.afterInsert(newKey, record);
          if (successMsg) {
            window.alert(successMsg);
          }
          if (callback) {
            callback(recordId);
          }
          // Save the audit log (for insert).
          // @TODO uncomment this
          // self.auditLogger.log(self.auditLogHelper.getAllowedOperationTypes().insert, collectionName, recordId, null, record);
        }
      }
    );
  };

  /**
   * @param {string} collectionName
   * @param {int} recordIndex
   * @param {string} successMsg
   * @param callback
   * @param {int} recordId
   * @param {object} previousState
   * @param {object} currentState
   */
  update(collectionName, recordIndex, successMsg, callback, recordId, previousState, currentState) {
    this.recordSets.ref(collectionName+'/'+recordIndex).set(
      currentState,
      function (error) {
        if (error) {
          window.alert('Error: ' + error.toString());
        } else {
          if (successMsg) {
            window.alert(successMsg);
          }
          if (callback) {
            callback(recordId);
          }
          // Save the audit log (for update).
          // @TODO uncomment this
          // self.auditLogger.log(self.auditLogHelper.getAllowedOperationTypes().update, collectionName, recordId, previousState, currentState);
        }
      }
    );
  };

  delete(collectionName, recordIndex, successMsg, callback) {
    this.recordSets.ref(collectionName+'/'+recordIndex).remove()
      .then(function() {
        if (successMsg) {
          window.alert(successMsg);
        }
        if (callback) {
          callback();
        }
      })
      .catch(function(error) {
        console.log("Remove failed: " + error.message);
      });
  }
}

export default AbstractDataRepository;
