import ModelImage from "./common/ModelImage";

class Category {
  constructor(data) {
    this.description = data.hasOwnProperty('description') ? data.description : null;
    this.id = data.hasOwnProperty('id') && data.id !== null ? parseInt(data.id) : null;
    this.setImage(data.hasOwnProperty('image') ? data.image : null);
    this.name = data.hasOwnProperty('name') ? data.name : null;
    this.isSeasonalCategory = data.hasOwnProperty('isSeasonalCategory') && !!data.isSeasonalCategory;
    this.isCurrentSeasonalCategory = data.hasOwnProperty('isCurrentSeasonalCategory') && !!data.isCurrentSeasonalCategory;
    // @TODO: The seasonalHeading and seasonalSubtext fields have been repurposed. For the time being, if there is data
    // @TODO: in either "old" field and nothing in the "new" field, assume the old field is valid. If the category then
    // @TODO: gets saved, the data in the data store will be corrected. Eventually we'll be able to remove references
    // @TODO: to the old fields.
    let seasonalHeading = data.hasOwnProperty('seasonalHeading') ? data.seasonalHeading : null;
    let seasonalSubtext = data.hasOwnProperty('seasonalSubtext') ? data.seasonalSubtext : null;
    this.seasonalText = data.hasOwnProperty('seasonalText') ? data.seasonalText : null;
    this.seasonalButtonLabel = data.hasOwnProperty('seasonalButtonLabel') ? data.seasonalButtonLabel : null;
    if (this.seasonalText === null && seasonalHeading !== null) {
      this.seasonalText = seasonalHeading;
    }
    if (this.seasonalButtonLabel === null && seasonalSubtext !== null) {
      this.seasonalButtonLabel = seasonalSubtext;
    }
  };

  getId() {
    return this.id;
  };

  hasId() {
    return this.id !== null;
  };

  setId(newId) {
    this.id = newId;
  };

  getName() {
    return this.name;
  };

  hasName() {
    return this.name !== null && this.name !== '';
  };

  setName(name) {
    this.name = name;
  };

  getDescription() {
    return this.description;
  };

  setDescription(description) {
    this.description = description;
  };

  hasDescription() {
    return this.description !== null && this.description !== '';
  };

  getImage() {
    return this.image;
  };

  setImage(imageInfo) {
    this.image = imageInfo instanceof ModelImage ? imageInfo : new ModelImage(imageInfo);
  };

  setIsSeasonalCategory(isSeasonalCategory) {
    this.isSeasonalCategory = !!isSeasonalCategory;
  }

  getIsSeasonalCategory() {
    return this.isSeasonalCategory;
  }

  setIsCurrentSeasonalCategory(isCurrentSeasonalCategory) {
    this.isCurrentSeasonalCategory = !!isCurrentSeasonalCategory;
  }

  getIsCurrentSeasonalCategory() {
    return this.isCurrentSeasonalCategory;
  }

  setSeasonalText(seasonalHeading) {
    this.seasonalHeading = seasonalHeading;
  };

  getSeasonalText() {
    return this.seasonalText;
  };

  hasSeasonalText() {
    return this.seasonalText !== null && this.seasonalText !== '';
  };

  setSeasonalButtonLabel(seasonalButtonLabel) {
    this.seasonalButtonLabel = seasonalButtonLabel;
  };

  getSeasonalButtonLabel() {
    return this.seasonalButtonLabel;
  };

  hasSeasonalButtonLabel() {
    return this.seasonalButtonLabel !== null && this.seasonalButtonLabel !== '';
  };
}

export default Category;
