import CompanyValidator from "./CompanyValidator";
import ValidationResult from "./ValidationResult";
import {SuggestedCompanyStatuses} from "../models/Statuses/SuggestedCompanyStatuses";
// eslint-disable-next-line
import CompanyFieldAttributes from "../services/CompanyFieldAttributes";
import SuggestedCompanyFieldAttributes from "../services/SuggestedCompanyFieldAttributes";

class SuggestedCompanyValidator extends CompanyValidator{
  /**
   * Get the appropriate attributes for this screen.
   * @return {CompanyFieldAttributes}
   */
  getFieldAttributes() {
    return new SuggestedCompanyFieldAttributes();
  }
  /**
   * @param {SuggestedCompany} suggestedCompany
   * @param {boolean|null} isSuggestedCompany
   * @param {int|null} generatedCompanyID If the object we're validating is a suggested company and it has been
   *                                      approved, this is the generated company ID.
   * @return {ValidationResult}
   */
  validate(suggestedCompany, isSuggestedCompany, generatedCompanyID) {
    // It must be a valid company.
    let result = super.validate(suggestedCompany, true, suggestedCompany.getGeneratedCompanyID());

    // And a valid suggested company.
    // Currently phone number is not mandatory.
    // The "required" attributes for the fields are now held in a separate class, so we have to go through every field,
    // check its attribute and then whether it has been entered.
    if (this.fieldAttributes.isRequired('reason') && !suggestedCompany.hasSuggesterReason()) {
      result.addError('Your reasons for suggesting this company are mandatory.');
    }
    if (this.fieldAttributes.isRequired('suggesterGivenName') && !suggestedCompany.hasSuggesterGivenName()) {
      result.addError('Your given name is mandatory.');
    }
    if (this.fieldAttributes.isRequired('suggesterFamilyName') && !suggestedCompany.hasSuggesterFamilyName()) {
      result.addError('Your family name is mandatory.');
    }
    if (this.fieldAttributes.isRequired('suggesterPhone') && !suggestedCompany.hasSuggesterPhone()) {
      result.addError('Your phone number is mandatory.');
    }
    if (this.fieldAttributes.isRequired('suggesterEmail') && !suggestedCompany.hasSuggesterEmailAddress()) {
      result.addError('Your email address is mandatory.');
    }
    if (this.fieldAttributes.isRequired('additionalDetails') && !suggestedCompany.hasSuggesterAdditionalDetails()) {
      result.addError('Additional details are mandatory.');
    }

    return result;
  }

  validateDelete(suggestedCompany) {
    let result = new ValidationResult();

    // You can only delete a suggested company if its status is "submitted", "pending" or "rejected".
    if (suggestedCompany.getStatus() !== SuggestedCompanyStatuses.submitted &&
      suggestedCompany.getStatus() !== SuggestedCompanyStatuses.pending &&
      suggestedCompany.getStatus() !== SuggestedCompanyStatuses.rejected) {
      result.addError('You cannot delete a suggested company with a status of "'+suggestedCompany.getStatus()+'".');
    }

    return result;
  }
}

export default SuggestedCompanyValidator;
