import React from "react";
import Tag from "../../../models/Tag";
import ListSearchBox from "../Common/ListSearchBox";
import TextSearch from "../../Contexts/TextSearch";

class TagList extends React.Component {
  constructor(props) {
    super(props);
    this.tagName = '';

    this.state = {
      tags: this.getTags(),
      textFilter: new TextSearch(JSON.parse(localStorage.getItem('tagListTextSearch')))
    }
  }

  getTags() {
    return this.props.tagRepository.getTags().sort(
      (tag1, tag2) => {
        return tag1.getName().toLowerCase() < tag2.getName().toLowerCase() ? -1 : 1;
      }
    )
  }

  nameChangeHandler = event => {
    this.tagName = event.target.value;
  }

  onSearchChange = event => {
    // I'm assuming a case-insensitive search.
    this.state.textFilter.setTextFilter(event.target.value.toLowerCase());
    this.setState(
      {
        textFilter: this.state.textFilter
      },
      () => {
        // Save the new state of the search box into local storage.
        localStorage.setItem('tagListTextSearch', JSON.stringify(this.state.textFilter))
      }
    )
  }

  saveTag = event => {
    let tag = new Tag({name: this.tagName});
    let self = this;
    let result = this.props.tagRepository.persist(
      tag,
      'The tag was saved!',
      function () {
        self.setState({tags: self.getTags()});
      }
    );
    if (!result.isValid()) {
      alert('Creation failed: '+result.getErrors()[0]);
    }
  }

  delete(tagId) {
    let tag = this.props.tagRepository.getTagById(tagId);
    if (window.confirm('Are you sure you want to delete this tag ("'+tag.getName()+'")?')) {
      let self = this;

      let result = this.props.tagRepository.deleteTag(
        tag,
        'Tag has been deleted!',
        function () {
          self.setState({tags: self.getTags()});
        }
      );
      if (!result.isValid()) {
        alert('Could not delete that tag: '+result.getErrors().join('; '));
      }
    }
  }

  render() {
    let allTags = [];

    let filteredTags = this.state.tags
    let textFilter = this.state.textFilter.getTextFilter();
    if (textFilter) {
      filteredTags = filteredTags.filter(
        function (tag) {
          return tag.getName().toLowerCase().indexOf(textFilter) !== -1;
        }
      )
    }

    for (let tagIdx = 0; tagIdx < filteredTags.length; tagIdx++) {
      let thisTag = filteredTags[tagIdx];
      let tagCompanies = this.props.companyRepository.getByTagId(thisTag.getId());
      let companies = [];
      tagCompanies.forEach(
        company => {
          companies.push(<div key={company.getId()}><a href={'/admin/companies/edit/'+company.getId()} target="company-edit">{company.getName()}</a></div>)
        }
      )
      allTags.push(
        <div className="row record" key={thisTag.getId()}>
          <div className="col-md-4">{thisTag.getName()}</div>
          <div className="col-md-1">{tagCompanies.length === 0 ? '' : tagCompanies.length}</div>
          <div className="col-md-4 tag-company-names">{companies}</div>
          <div className="col-md-1"><a href={'/admin/tags/edit/'+thisTag.getId()}>Edit</a></div>
          <div className="col-md-1"><button onClick={() => {this.delete(thisTag.getId());}} className="btn btn-danger">Delete <span className="glyphicon glyphicon-trash" aria-hidden="true"/></button></div>
        </div>
      );
    }

    let tagDetail = <p>There are no tags</p>;
    if (allTags.length > 0) {
      tagDetail =
        <div className="tags-list">
          <div className="row">
            <div className="col-md-4">Name</div>
            <div className="col-md-5">Used In Companies</div>
            <div className="col-md-1">Edit</div>
            <div className="col-md-1">Delete</div>
          </div>
          {allTags}
        </div>;
    }

    return (
      <div>
        <h2>Add</h2>
        <div className="row">
          <div className="col-md-1">Name</div>
          <div className="col-md-3"><input onChange={this.nameChangeHandler}/></div>
          <div className="col-md-1"><button onClick={this.saveTag}>Save</button></div>
        </div>
        <div className="pull-right">
          <ListSearchBox onchange={this.onSearchChange} initialValue={this.state.textFilter.getTextFilter()}/>
        </div>
        <h2>All tags</h2>
        {tagDetail}
      </div>
    )
  }
}

export default TagList;
