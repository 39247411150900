import React from "react";
import './Subscribe.css';

/**
 * This is a temporary component that allows users to subscribe to either our newsletter or the partnership programme.
 * It will be replaced by mailerlite forms when they approve our account.
 */
class Subscribe extends React.Component {
  constructor(props) {
    super(props);

    this.template = this.props.template;
    this.subscriberService = this.props.subscriberService;

    this.resetEmail();
    this.state = {
      error: null,
    };
  }

  resetEmail() {
    this.email = '';
    this.emailIsValid = false;
  }

  captureEmail = event => {
    this.email = null;
    this.emailIsValid = event.target.validity.valid;
    if (this.emailIsValid) {
      this.email = event.target.value;
    }
  }

  subscribe = event => {
    let error = null;
    if (this.email === '') {
      error = 'Please enter an email address';
    } else {
      if (this.emailIsValid) {
        // We need to do slightly different things depending on whether the template is for the simple newsletter or
        // the partnership thing.
        let subscriptionType;
        let confirmMessage;
        if (this.template === 'landing-partnership') {
          subscriptionType = 'partnership';
          confirmMessage = 'Thank you for your interest in partnering with us! We will be in touch soon.';
        } else {
          subscriptionType = 'newsletter';
          confirmMessage = 'Thank you for subscribing! We will keep you up to date with the latest news from SourceRight.'
        }
        this.subscriberService.subscribe(
          this.email,
          subscriptionType,
          confirmMessage,
          () => {
            this.resetEmail();
          }
        );
      } else {
        error = 'Email address is not valid';
      }
    }
    this.setState({error: error});
  }

  render() {
    // decide which form we're going to show.
    let content = <div>Unknown subscribe form!</div>;
    switch (this.template) {
      case 'landing-page-cta':
        content =
          <div className="subscribe-form landing-page-cta img-dim">
            <div style={{margin: "auto", width: "fit-content"}}>
              <div className="prompt">Get to know the latest on ethical shopping!</div>
              <input className="subscriber-email" type="email" placeholder="Your email" onChange={this.captureEmail}/>
              <input type="submit" className="subscribe-button" value="SUBSCRIBE" onClick={this.subscribe}/>
              {this.state.error !== null && <div className="errors">{this.state.error}</div>}
            </div>
          </div>;
        break;
      case 'footer-newsletter':
        content =
          <div className="subscribe-form join-the-movement">
            <div className="prompt">Get the latest brands straight to your inbox!</div>
            <input className="subscriber-email" type="email" placeholder="Type your email" onChange={this.captureEmail}/>
            <button onClick={this.subscribe} className="subscribe-button">
              <span className="glyphicon glyphicon-send" aria-hidden="true"/>
            </button>
            {this.state.error !== null && <div className="errors">{this.state.error}</div>}
          </div>;
        break;
      case 'landing-partnership':
        content =
          <div className="subscribe-form partnership">
            <div className="prompt">Interested in becoming our Partner Brand?</div>
            <p>
              Stay tuned to this space, we are working hard to create instructions for partnership.
              Meanwhile, let us know where we can send you updates.
            </p>
            <input className="subscriber-email" type="email" placeholder="Type your email" onChange={this.captureEmail}/>
            <input type="submit" className="subscribe-button" value="SUBSCRIBE" onClick={this.subscribe}/>
            {this.state.error !== null && <div className="errors">{this.state.error}</div>}
          </div>;
        break;
      default:
        content = <p>Something has gone wrong with this subscribe form!</p>
        break;
    }

    return content;
  }
}

export default Subscribe;
