/**
 * This file is emulating Certification data coming from a database (or firebase). It is read by
 * the data repository.
 * Note: This contains characters/strings that should really be HTML entities, such as "@reg;"
 *       instead of "®", but I couldn't get the application to render them properly, so I'm leaving
 *       them for now.
 *
 * @type {*[]}
 */
let certificationJson = [
    {
      "name": "Fair Trade",
      "headingUrl": "https://www.fairtrade.net/",
      "paragraphs": [
        "There are quite a few fair trade certifications and labels in the marketplace. Organizations that grant these certifications vary in their economic, social, governance and environmental standards as well as their inspecting, certifying, and auditing policies. However as a whole, fair trade certifications indicate that produces are paid a fair price for their goods; workers receive fair wages and work in good conditions; and there are no instances of forced, bonded or child labor throughout their supply chains. Third-party auditors conduct inspections to ensure that members of these fair trade organizations are in compliance.",
        "Though a food product may be fair certified, there are still ingredients such as milk, vegetable fat, emulsifiers and flavorings in it that can’t be sourced through fair trade. Some of our product suggestions only have one certified ingredient. Product from companies and brands in our Best category have a higher total percentage of fair trade sourced ingredients. "
      ],
    },
    {
      "name": "FAIRTRADE® Certification Copy and FAIRTRADE® Program Mark",
      "headingUrl": "https://www.fairtrade.net/",
      "paragraphs": [
        "The FAIRTRADE® Certification Mark from Fairtrade International is the oldest and appears on about 80% of the world’s fair trade products. Products with the label have 20% or more fair trade content; in addition, all ingredients that can be sourced on fair trade terms must be. Single ingredient products with the label must have 100% fair trade content. Along with food and drink items, Fairtrade International certifies fair trade cotton, flowers, gold and sports balls.",
        "Fairtrade International has a FAIRTRADE® Program Mark for cocoa, sugar and cotton. The cocoa, sugar and cotton programs enable companies who don’t have certified composite products to still commit to buying slavery- free cocoa, sugar or cotton. These display the FAIRTRADE Cocoa ProgramTM, FAIRTRADE Sugar ProgramTM or FAIRTRADE Cotton ProgramTM labels."
      ],
      "images": [
        "/images/certifications/FAIRTRADE.png"
      ]
    },
  {
    "name": "Fair Trade Certified™",
    "headingUrl": "https://www.fairtradecertified.org/",
    "paragraphs": [
      "Fair Trade Certified™ products from Fair Trade USA℠ have at least 20% fair trade ingredients; however, unlike Fairtrade International, there’s no requirement to source all available fair trade ingredients. In addition to packaged food and fresh produce, Fair Trade USA℠ certifies drinks, cotton, apparel, body care, home goods and plant products as well as sewing, factories and fisheries."
    ],
    "images": [
      "/images/certifications/FairTrade copy.png",
      "/images/certifications/FairTradeIngr.png"
    ]
  },
  {
    "name": "Fair for Life®",
    "headingUrl": "https://www.fairforlife.org/pmws/indexDOM.php?client_id=fairforlife&page_id=home",
    "paragraphs": [
      "Composite products with the Fair for Life® label must have at least 80% fair trade content. Single ingredient products must be 100% fair trade. Fair for Life® certifies food, plant, cosmetic, textiles, small scale mining and artisanal products as well as tourist services."
    ],
    "images": [
      "/images/certifications/FairforLife1.png",
      "/images/certifications/Fairforlife2.png"
    ]
  },
    {
      "name": "Sustainable Development",
      "paragraphs": [
        "Other than fair trade labels, there are sustainable development labels that address slave labor and environmental concerns. In general, these labels indicate that raw ingredients can be traced back to their sources. Traceability enables inspectors to audit supply chains and find suppliers that may be using slave labor. For the most part, sustainable development labels focus on the environmental impact and the long-term availability of resources. Environmental concern, in turn, leads to the development of sustainable farming programs and trainings for workers."
      ],
      "images": []
    },
    {
      "name": "Rainforest Alliance Certified™",
      "headingUrl": "https://www.rainforest-alliance.org/",
      "paragraphs": [
        "Products that are Rainforest Alliance Certified™ must have at least 30% of a main ingredient sourced under Rainforest Alliance Certified™ terms."
      ],
      "images": [
        "/images/certifications/RainForestAlliance.png"
      ]
    },
    {
      "name": "UTZ Certified™",
      "headingUrl":"https://utz.org/",
      "paragraphs": [
        "UTZ Certified™ products must have more than 90% UTZ Certified™ cocoa content. Roundtable on Sustainable Palm Oil (RSPOTM), which provides a certification for sustainably sourced palm oil, contracts UTZ Certified™ for its traceability services."
      ],
      "images": [
        "/images/certifications/utz1.png",
        "/images/certifications/utz2.png",
        "/images/certifications/utz3.png"
      ]
    },
    {
      "name": "Brought to you by the community of Certified B Corporations℠",
      "headingUrl": "https://bcorporation.net/",
      "paragraphs": [
        "B Lab certifies for-profit companies that implement and enforce strict social, environmental, accountability and transparency standards. These companies receive the Brought to you by the community of Certified B Corporations℠ label."
      ],
      "images": [
        "/images/certifications/BCorp.png"
      ]
    },
    {
      "name": "Fair Labor Association®",
      "headingUrl": "https://www.fairlabor.org/",
      "paragraphs": [
        "Suppliers and companies that commit to the Fair Labor Association® Code of Conduct have agreed to implement and enforce standards that prohibit forced and child labor, while also ensuring fair labor practices and humane working conditions. Affiliation with the Fair Labor Association® is voluntary and participants are 100% responsible for their supply chains and are subject to random assessments by the Fair Labor Association®."
      ],
      "images": [
        "/images/certifications/fair-labor-association_large_verge_medium_landscape.gif"
      ]
    },
    {
      "name": "Fair Trade Federation℠",
      "headingUrl":"https://www.fairtradefederation.org/",
      "paragraphs": [
        "Fair Trade Federation℠ is a non-profit trade association that supports fair trade businesses in North America. Members adhere to a Code of Practice, and the Fair Trade Federation℠ logo indicates that at least 85% of their products are sourced according to the Fair Trade Federation℠ Principles, which includes no use of slave labor. The other 15% of inventory may be eco-friendly products, made by local artisans or educational materials related to fair trade. In addition, this latter 15% must be produced in conditions that do not harm people, the environment or other cultures."
      ],
      "images": [
        "/images/certifications/Fair_Trade_Federation_Logo1.jpg"
      ]
    },
    {
      "name": "World Fair Trade Organization",
      "headingUrl": "https://wfto.com/",
      "paragraphs": [
        "Over 300 fair trade organizations are members of the World Fair Trade Organization. Members comply with the 10 Principles of Fair Trade which are based on the International Labor Organization’s conventions, including no child or forced labor and ensuring good working conditions. The World Fair Trade Organization Guarantee System is an assurance that members have implemented the 10 Principles of Fair Trade in their supply chains and practices. The WFTO Product Label indicates that an item is made and traded by Guaranteed Fair Trade Organizations."
      ],
      "images": [
        "/images/certifications/World Fair Trade Organization.jpg"
      ]
    },
    {
      "name": "GoodWeave®",
      "headingUrl": "https://goodweave.org/",
      "paragraphs": [
        "Companies and brands with the GoodWeave® label disclose a fully traceable supply chain for all production processes and are subject to unannounced inspections by GoodWeave® approved personnel. These verification visits ensure that child labor isn’t used and that adult workers aren’t abused."
      ],
      "images": [
        "/images/certifications/goodweave-label-sample-transparent.gif"
      ]
    },
    {
      "name": "Electronic Industry Citizenship Coalition®",
      "headingUrl": "http://www.responsiblebusiness.org/",
      "paragraphs": [
        "Electronic Industry Citizenship Coalition® is a non-profit made up of electronics companies who have signed onto and are held accountable to a Code of Conduct that prohibits forced labor, bonded labor, involuntary prison labor, child labor, slavery and trafficking."
      ],
      "images": [
        "/images/certifications/Electronic Industry Citizenship Coalition.jpg"
      ]
    },
    {
      "name": "Global Sustainability Initiative®",
      "headingUrl": "https://gesi.org/",
      "paragraphs": [
        "Global Sustainability Initiative® members disseminate information, resources and best practices to promote social and environmental sustainability."
      ],
      "images": [
        "/images/certifications/Global Sustainability Initiative.jpg"
      ]
    },
    {
      "name": "Conflict-Free Sourcing Initiative®",
      "headingUrl": "http://www.responsiblemineralsinitiative.org/",
      "paragraphs": [
        "Members of the Electronic Industry Citizenship Coalition® and the Global Sustainability Initiative® founded the Conflict-Free Sourcing Initiative® which runs the Conflict-Free Smelter Program that confirms which smelters and refiners follow global standards and which ones can be claimed “conflict-free.”"
      ],
      "images": [
        "/images/certifications/Conflict-Free Sourcing Initiative.png"
      ]
    },
    {
      "name": "Solutions for Hope",
      "headingUrl": "https://www.resolve.ngo/site-solutionsforhope/default.htm\n",
      "paragraphs": [
        "Solutions for Hope works with governments, companies, civil society and local refineries to provide sourcing opportunities for artisanal scale miners and cooperatives that meet conflict-free standards."
      ],
      "images": [
        "/images/certifications/Solutions for Hope.jpg"
      ]
    },
    {
      "name": "RESOLVE’s Public-Private Alliance for Responsible Minerals Trade",
      "headingUrl": "https://www.resolve.ngo/site-ppa/default.htm",
      "paragraphs": [
        "RESOLVE’s Public-Private Alliance for Responsible Minerals Trade provides funding and coordination support for those working in the Democratic Republic of the Congo and the Great Lakes Region of Central Africa to identify conflict-free supply chains."
      ],
      "images": [
        "/images/certifications/Resolve.png"
      ]
  }
]
;

export default certificationJson;
