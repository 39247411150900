import AbstractFieldObjectSelector from "./AbstractFieldObjectSelector";
import Tag from "../../../models/Tag";

class FieldTagSelector extends AbstractFieldObjectSelector {
  constructor(props) {
    super(props);

    this.tagRepository = this.props.modelRepository;
  }

  getLabel() {
    return 'Tags';
  }

  loadAllModels() {
    return this.tagRepository.getTags();
  }

  getObjectById(id) {
    return this.tagRepository.getTagById(id);
  }

  getModelId(tag) {
    return tag.getId();
  }

  getModelName(tag) {
    return tag.getName();
  }

  getModelDescription() {
    return 'tag';
  }

  createModel = name => {
    // Create a tag record, with the given name. Then act as if the user selected it.
    let tag = new Tag({name: name});
    let self = this;
    this.tagRepository.persist(
      tag,
      'New tag created!',
      function (newTagId) {
        self.refreshAllModels();
        self.selectModel(newTagId);
      }
    );
  }
}

export default FieldTagSelector;
