import React from "react";
import Subscribe from "./Subscribe";

class Footer extends React.Component {
  makeSocialMediaLinks() {
    // This is the config for the various social media channels. They have as much data as possible, but in some cases
    // there is no URL (we don't have an account), eg YouTube, Facebook. They are excluded by the code. Makes it easy
    // to add them in if/when we get an account.
    let socialMediaData = [
      {name: 'Email us', icon: 'mail_icon.png', url: 'mailto:contact@sourceright.org'},
      {name: 'Medium', icon: 'medium_icon.png', url: 'https://sourceright.medium.com/'},
      {name: 'YouTube', icon: 'youtube_icon.png', url: null},
      {name: 'Instagram', icon: 'Instagram_icon.png', url: 'https://www.instagram.com/sourcerightorg/'},
      {name: 'Facebook', icon: 'Facebook_icon.png', url: null},
      {name: 'LinkedIn', icon: 'Linkedin_icon.png', url: 'https://www.linkedin.com/company/source-right-by-elixir-labs'},
      {name: 'Twitter', icon: 'Twitter_icon.png', url: 'https://twitter.com/SourceRightorg'},
    ]
    socialMediaData = socialMediaData.filter(
      (socialMediaDatum) => {
        return socialMediaDatum.url !== null;
      }
    );

    let result = [];
    for(let smIdx = 0; smIdx < socialMediaData.length; ++smIdx) {
      result.push(
        <div key={smIdx} className="social-media-link">
          <a href={socialMediaData[smIdx].url} target="_blank" rel="noopener noreferrer">
            <img
              src={'/images/footer/social-media/' + socialMediaData[smIdx].icon}
              alt={'Link to ' + socialMediaData[smIdx].name}
            />
          </a>
        </div>
      );
    }

    return result;
  }

  render() {
    return (
      <div className="footer-container">
        <div className="footer">
          <div className="row">
            <div className="col-md-6 col-sm-6 logo">
              <img src="/images/source_right_logo.png" alt="SourceRight"/>
              <div className="company-name">
                Source<br/>Right
              </div>
              <p className="made-by">
                Made with <span className="glyphicon glyphicon-heart" aria-hidden="true"/>&nbsp;
                by <a href="https://www.elixirlabs.org/" target="_blank" rel="noopener noreferrer">Elixir Labs</a>
              </p>
            </div>
            <div className="col-md-6 col-sm-6">
              <Subscribe template="footer-newsletter" subscriberService={this.props.subscriberService}/>
              <div className="social-media-container pull-right">
                <div className="social-media-links">{this.makeSocialMediaLinks()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer;

