import AbstractDataRepository from "./AbstractDataRepository";
import SuggestedCompany from "../models/SuggestedCompany";
import {UploadFolderNames} from "./UploadFolderNames";
import {CollectionNames} from "./Constants";

/**
 * This class is the interface between the suggested company data and the storage engine (at time of writing, firebase).
 * It is instantiated when the data repository factory determines the database is ready. The raw data is passed in to
 * the constructor.
 */
class SuggestedCompanyRepository extends AbstractDataRepository {
  /**
   * The constructor receives raw data from the database, representing the raw suggested company data.
   * @param dataSets
   * @param {StorageRepository} storageRepository
   * @param rawSuggestedCompanies
   */
  constructor(dataSets, storageRepository, rawSuggestedCompanies) {
    super(dataSets, storageRepository);
    this.replaceDataset(rawSuggestedCompanies);
  }

  replaceDataset(rawSuggestedCompanies) {
    this.suggestedCompanies = {};
    for (let suggestedCompanyRef in rawSuggestedCompanies) {
      if (rawSuggestedCompanies.hasOwnProperty(suggestedCompanyRef)) {
        let oneSuggestedCompany = rawSuggestedCompanies[suggestedCompanyRef];
        this.suggestedCompanies[suggestedCompanyRef] = new SuggestedCompany(oneSuggestedCompany, suggestedCompanyRef);
      }
    }
  }

  /**
   * @param {SuggestedCompanyValidator} suggestedCompanyValidator
   */
  setSuggestedCompanyValidator(suggestedCompanyValidator) {
    this.suggestedCompanyValidator = suggestedCompanyValidator;
  }

  getAll() {
    return this.filterRecords(
      this.suggestedCompanies,
      () => {return true;}
    );
  }

  getSuggestedCompanyByKey(key) {
    let result = null;
    this.forEachRecord(
      this.suggestedCompanies,
      /**
       * @param {SuggestedCompany} suggestedCompany
       * @param recordKey
       */
      function(suggestedCompany, recordKey) {
        // We don't care about the suggested company as such, just its key.
        if (key === recordKey) {
          result = suggestedCompany;
        }
      }
    )

    return result;
  }

  getSuggestedCompanyById(suggestedCompanyId) {
    suggestedCompanyId = parseInt(suggestedCompanyId);
    let matching = this.filterRecords(
      this.suggestedCompanies,
      function (suggestedCompany) {
        return suggestedCompanyId === suggestedCompany.getId();
      }
    )

    if (matching.length === 1) {
      return matching.shift(); // Take the first one (index not necessarily 0)
    }

    return null;
  };

  getSuggestedCompaniesByStatus(status) {
    let matching = this.filterRecords(
        this.suggestedCompanies,
        function (suggestedCompany) {
          return suggestedCompany.getStatus() === status;
        }
    )

    return matching;
  }

  /**
   * Return the internal key for the record with the given id.
   *
   * @param {int} recordId
   * @return {String|null}
   */
  getKeyForRecordId(recordId) {
    let result = null;
    this.forEachRecord(
      this.suggestedCompanies,
      /**
       * @param {SuggestedCompany} suggestedCompany
       * @param recordKey
       */
      function(suggestedCompany, recordKey) {
        if (recordId === suggestedCompany.getId()) {
          result = recordKey;
        }
      }
    )

    return result;
  }

  getByTagId(tagId) {
    return this.filterRecords(
      this.suggestedCompanies,
      function (suggestedCompany) {
        return suggestedCompany.getTagIds().indexOf(tagId) >= 0;
      }
    );
  }

  /**
   * Save the given suggested company back to the data store.
   *
   * @param suggestedCompany {SuggestedCompany}
   * @param successMsg {string|null}
   * @param callback {Function|null}
   * @return {ValidationResult}
   */
  persist(suggestedCompany, successMsg, callback) {
    let result = this.suggestedCompanyValidator.validate(suggestedCompany);
    if (!result.isValid()) {
      return result;
    }

    // If the suggested company's image need uploading, we forget about saving the suggested company (kind of) and
    // instead upload the image. In the callback method from that upload, we receive the new image URL, which we put in
    // to the suggested company object and call persist recursively.
    if (suggestedCompany.getImage() && suggestedCompany.getImage().requiresUpload()) {
      // In the callback, we put the new image URL into the suggested company and call persist recursively.
      let uploadCallback = imageUrl => {
        suggestedCompany.setImage(imageUrl);
        this.persist(suggestedCompany, successMsg, callback);
      }
      this.storageRepository.uploadImageInModel(suggestedCompany.getImage(), UploadFolderNames.suggestedCompany, uploadCallback);

      return result; // Forget about saving the the suggested company in this "thread". The callback above will do it now.
    }

    if (suggestedCompany.hasId()) {

      // This is an update.
      // Determine the index of the record in the array from the model's id.
      let suggestedCompanyIndex = this.determineIndexFromSuggestedCompanyModel(suggestedCompany);
      // Create a reference to the object in the array.
      let suggestedCompanySource = this.suggestedCompanies[suggestedCompanyIndex];
      // @TODO: I wonder if the original version of the record should be in the model, before the model constructor
      // @TODO: does any data migrations (adding columns, etc).
      let originalSuggestedCompany = Object.assign({}, suggestedCompanySource);
      // Overwrite the record with the values from the model. It is a bit yucky because the image value has to be the
      // raw value that firebase will accept, but we need to convert it back to an object afterwards, hence wrapping
      // any callback function pass in with a function that will do that conversion.
      this.populateRecordFromSuggestedCompanyModel(suggestedCompanySource, suggestedCompany);
      let persistCallback = () => {
        // Convert the text URL back to an image object.
        suggestedCompanySource.setImage(suggestedCompanySource.getImage());
        if (callback) {
          callback();
        }
      }
      // Save the record back to the store.
      this.update(CollectionNames.suggestedCompanies, suggestedCompanyIndex, successMsg, persistCallback, suggestedCompany.getId(), originalSuggestedCompany, suggestedCompanySource);

    } else {

      // This is an insert. Generate a new id and start a new object.
      suggestedCompany.setId(this.generateNextSuggestedCompanyId());
      let newSuggestedCompany = {
        id: suggestedCompany.getId()
      };
      // Populate the record with the values from the model.
      this.populateRecordFromSuggestedCompanyModel(newSuggestedCompany, suggestedCompany);
      // Create the record in the store.
      this.insert(CollectionNames.suggestedCompanies, newSuggestedCompany, successMsg, callback, suggestedCompany.getId());

    }

    return result;
  };

  generateNextSuggestedCompanyId() {
    let id = 0;
    this.forEachRecord(
      this.suggestedCompanies,
      function(suggestedCompany) {
        id = Math.max(id, suggestedCompany.id);
      }
    );
    ++id;

    return id;
  };

  determineIndexFromSuggestedCompanyModel(suggestedCompanyModel) {
    // Determine the index of the firebase array, using the suggested company model's id.
    let suggestedCompanyIndex = null;
    this.forEachRecord(
      this.suggestedCompanies,
      function (suggestedCompany, index) {
        if (suggestedCompany.id === suggestedCompanyModel.getId()) {
          suggestedCompanyIndex = index;
        }
      }
    );

    return suggestedCompanyIndex;
  };

  populateRecordFromSuggestedCompanyModel(suggestedCompanyRecord, suggestedCompany) {
    suggestedCompanyRecord.description = suggestedCompany.getDescription();
    suggestedCompanyRecord.image = suggestedCompany.getImage().hasUrl() ? suggestedCompany.getImage().getUrl() : null;
    suggestedCompanyRecord.name = suggestedCompany.getName();
    suggestedCompanyRecord.categoryIds = suggestedCompany.getCategoryIds();
    suggestedCompanyRecord.tagIds = suggestedCompany.getTagIds();
    suggestedCompanyRecord.linkUrl = suggestedCompany.getLinkUrl();
    suggestedCompanyRecord.countryCodes = suggestedCompany.getCountryCodes();
    suggestedCompanyRecord.status = suggestedCompany.getStatus();
    suggestedCompanyRecord.verificationCodeData = suggestedCompany.getVerificationCodeData();
    suggestedCompanyRecord.createdAt = suggestedCompany.getCreatedAtAsString();
    suggestedCompanyRecord.suggesterReason = suggestedCompany.getSuggesterReason();
    suggestedCompanyRecord.suggesterGivenName = suggestedCompany.getSuggesterGivenName();
    suggestedCompanyRecord.suggesterFamilyName = suggestedCompany.getSuggesterFamilyName();
    suggestedCompanyRecord.suggesterPhoneNumber = suggestedCompany.getSuggesterPhoneNumber();
    suggestedCompanyRecord.suggesterEmailAddress = suggestedCompany.getSuggesterEmailAddress();
    suggestedCompanyRecord.suggesterAdditionalDetails = suggestedCompany.getSuggesterAdditionalDetails();
    suggestedCompanyRecord.internalNotes = suggestedCompany.getInternalNotes();
    suggestedCompanyRecord.adminNotes = suggestedCompany.getAdminNotes();
    suggestedCompanyRecord.generatedCompanyID = suggestedCompany.getGeneratedCompanyID();
  };

  /**
   * A fudge to add any newly created record on to the list of company models in this repository.
   * @param newKey
   * @param record
   */
  afterInsert(newKey, record) {
    this.suggestedCompanies[newKey] = new SuggestedCompany(record, newKey);
  }

  deleteSuggestedCompany(suggestedCompany, successMsg, callback) {
    let result = this.suggestedCompanyValidator.validateDelete(suggestedCompany);

    if (result.isValid()) {
      this.delete(
        CollectionNames.suggestedCompanies,
        this.determineIndexFromSuggestedCompanyModel(suggestedCompany),
        successMsg,
        callback
      );
    }

    return result;
  }
}

export default SuggestedCompanyRepository;
