import Company from "./Company";
import {SuggestedCompanyStatuses} from "./Statuses/SuggestedCompanyStatuses";

class SuggestedCompany extends Company {
  constructor(data, suggestedCompanyRef) {
    super(data);

    // This is the data store's reference to this record.
    this.rowid = suggestedCompanyRef;

    this.suggesterReason = data.hasOwnProperty('suggesterReason') ? data.suggesterReason : null;
    this.suggesterGivenName = data.hasOwnProperty('suggesterGivenName') ? data.suggesterGivenName : null;
    this.suggesterFamilyName = data.hasOwnProperty('suggesterFamilyName') ? data.suggesterFamilyName : null;
    this.suggesterPhoneNumber = data.hasOwnProperty('suggesterPhoneNumber') ? data.suggesterPhoneNumber : null;
    this.suggesterEmailAddress = data.hasOwnProperty('suggesterEmailAddress') ? data.suggesterEmailAddress : null;
    this.suggesterAdditionalDetails = data.hasOwnProperty('suggesterAdditionalDetails') ? data.suggesterAdditionalDetails : null;

    // If this is a new record (ie not one that's coming from the data store), initialise some of the values. They may
    // get overridden by values passed in, though.
    this.status = SuggestedCompanyStatuses.submitted;
    this.verificationCodeData = null;
    if (this.hasId()) {
      if (data.hasOwnProperty('status')) {
        // @TODO: Make sure the status value is in SuggestedCompanyStatuses
        this.status = data.status;
      }
      if (data.hasOwnProperty('verificationCodeData')) {
        this.verificationCodeData = data.verificationCodeData;
      }
    } else {
      // This is a new record. Set the defaults where the value is not trivial (ie takes resources to generate).
      this.verificationCodeData = 100000 + Math.ceil(900000*Math.random());
      this.verificationCodeData = '1:'+this.verificationCodeData; // Prefix with version number.
    }

    this.internalNotes = data.hasOwnProperty('internalNotes') ? data.internalNotes : null;
    this.adminNotes = data.hasOwnProperty('adminNotes') ? data.adminNotes : null;
    this.generatedCompanyID = data.hasOwnProperty('generatedCompanyID') && data.generatedCompanyID !== null ? parseInt(data.generatedCompanyID) : null;
  }

  getRowId() {
    return this.rowid;
  }

  setStatus(newStatus) {
    // @TODO: We should verify that the status is valid.
    this.status = newStatus;
  }

  getStatus() {
    return this.status;
  }

  getVerificationCodeData() {
    return this.verificationCodeData;
  }

  extractVerificationCode() {
    if (this.verificationCodeData === null) {
      return null;
    }

    // For now, we just assume it is version 1.
    let codeParts = this.verificationCodeData.split(':');

    return codeParts[1];
  }

  getSuggesterReason() {
    return this.suggesterReason;
  }

  hasSuggesterReason() {
    return this.suggesterReason !== null && this.suggesterReason !== '';

  }

  getSuggesterGivenName() {
    return this.suggesterGivenName;
  }

  hasSuggesterGivenName() {
    return this.suggesterGivenName !== null && this.suggesterGivenName !== '';
  }

  getSuggesterFamilyName() {
    return this.suggesterFamilyName;
  }

  hasSuggesterFamilyName() {
    return this.suggesterFamilyName !== null && this.suggesterFamilyName !== '';
  }

  getSuggesterFullName() {
    let name = this.getSuggesterGivenName()+' '+this.getSuggesterFamilyName();
    name = name.trim();
    if (name === '') {
      name = null;
    }

    return name;
  }

  getSuggesterPhoneNumber() {
    return this.suggesterPhoneNumber;
  }

  hasSuggesterPhone() {
    return this.suggesterPhoneNumber !== null && this.suggesterPhoneNumber !== '';
  }


  getSuggesterEmailAddress() {
    return this.suggesterEmailAddress;
  }

  hasSuggesterEmailAddress() {
    return this.suggesterEmailAddress !== null && this.suggesterEmailAddress !== '';
  }

  setSuggesterAdditionalDetails(newDetails) {
    this.suggesterAdditionalDetails = newDetails;
  }

  getSuggesterAdditionalDetails() {
    return this.suggesterAdditionalDetails;
  }

  hasSuggesterAdditionalDetails() {
    return this.suggesterAdditionalDetails !== null && this.suggesterAdditionalDetails !== '';
  }

  setInternalNotes(newInternalNotes) {
    this.internalNotes = newInternalNotes;
  }

  getInternalNotes() {
    return this.internalNotes;
  }

  setAdminNotes(newAdminNotes) {
    this.adminNotes = newAdminNotes;
  }

  getAdminNotes() {
    return this.adminNotes;
  }

  setGeneratedCompanyID(companyID) {
    this.generatedCompanyID = companyID;
  }

  getGeneratedCompanyID() {
    return this.generatedCompanyID;
  }
}

export default SuggestedCompany;
