import React from "react";
import { useEffect, useState }  from 'react';
import { csv } from 'd3';
import press from './pressCoverage.csv';

const PressCoverage = () => {

  const [csv_data, setData] = useState([]);

  useEffect(() => {
    csv(press).then(data => {
        setData(data);
    })
    .catch((err) => console.warn(err));
  }, []);

  const sizeData = () => {
    return csv_data.length;
  }

    return (
      sizeData() > 0 &&
        <div className="pressCoverage">
          <div className="row">
            <h3 style={{marginTop: "50px"}}>Press Coverage</h3>
            </div>
            <div className='row'>
              {
                csv_data.map((item, index) =>
                  <div className="col-sm-4" key={index}>
                      <div className="rectangle3">
                      <img src={item.Image} style={{width:"100%"}} alt={item.Title}/>
                      <div className="title">{item.Title}</div>
                      <div className="info">{item.Info}</div>
                      <a href={item.Url} className="btn btn-primary btn-lg" role="button" aria-pressed="true" target='blank'>Link to article</a>
                    </div>
                  </div>
                )
              }
          </div>
      </div>
    );
}

export default PressCoverage;
