import React from "react";
import PageSizeOption from "./PageSizeOption";

class PageSizeOptions extends React.Component {
  render() {
    let options = [];
    let self = this;
    this.props.pageSizeHandler.getAllowedPageSizes().forEach(
      function(allowedPageSize) {
        options.push(
          <PageSizeOption
            key={allowedPageSize}
            pageSize={allowedPageSize}
            isSelected={self.props.pageSizeHandler.isSelected(allowedPageSize)}
            clickHandler={self.props.changeHandler}
          />
        );
      }
    );
    return (
      <div className="page-size-options">
        <div>Show</div>
        {options}
      </div>
    )
  }
}

export default PageSizeOptions;
