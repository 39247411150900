import React from "react";

class TeamMember extends React.Component {
  render() {
    let imageSource = 'images/about-us/team-members/'+this.props.imageFilename;
    let memberName = this.props.name;
    let profileUrl = this.props.profileUrl;
    if (profileUrl) {
      memberName = <a href={profileUrl} target="team-member-profileUrl" >{memberName}</a>;
    }

    return <div className="team-member">
      <img src={imageSource} alt="{this.props.name}"/>
      <div className="member-name">{memberName}</div>
    </div>;
  }
}

export default TeamMember;
