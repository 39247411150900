import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
// I'm afraid this next import has to be here, even though the class is not used in this file, otherwise you get circular dependency issues.
// eslint-disable-next-line
import AbstractEmbeddedCategoryForm from "./components/admin/Categories/AbstractEmbeddedCategoryForm";
import DataRepositoryFactory from "./dataStore/DataRepositoryFactory";
import { AuthUserContext } from './components/session';
import {firebaseAuth} from "./dataStore/firebase";
import AdminController from "./components/admin/AdminController";
import PublicFacingController from "./components/publicFacing/PublicFacingController";
import { loadReCaptcha } from 'react-recaptcha-google'
import ReactGA from 'react-ga';
import SubscriberService from "./services/SubscriberService";

// @TODO: We need to encapsulate all the repositories in an object, with a getter for each repo, and pass this object down
// @TODO: into the child objects.

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authUser: null,
      loaded: false,
      categoryRepository: null,
      certificationRepository: null,
      companyRepository: null,
      countryRepository: null,
      ethicalFactsRepository: null,
      suggestedCompanyRepository: null,
      tagRepository: null,
      subscriberRepository: null
    };

    // Initialise Google Analytics.
    ReactGA.initialize(process.env.REACT_APP__GOOGLE_ANALYTICS__TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidMount() {
    // After the app is mounted, we need to wait for the database to provide us with the data. Once it's done that, we
    // populate the data in the state and render it.
    let dataRepositoryFactory = new DataRepositoryFactory();
    dataRepositoryFactory.ready(
      () => {
        this.categoryRepository = dataRepositoryFactory.getCategoryRepository();
        this.certificationRepository = dataRepositoryFactory.getCertificationRepository();
        this.companyRepository = dataRepositoryFactory.getCompanyRepository();
        this.countryRepository = dataRepositoryFactory.getCountryRepository();
        this.ethicalFactsRepository = dataRepositoryFactory.getEthicalFactsRepository();
        this.suggestedCompanyRepository = dataRepositoryFactory.getSuggestedCompanyRepository();
        this.tagRepository = dataRepositoryFactory.getTagRepository();
        this.subscriberRepository = dataRepositoryFactory.getSubscriberRepository();
        this.setState(
          {
            loaded: true,
            categoryRepository: this.categoryRepository,
            certificationRepository: this.certificationRepository,
            companyRepository: this.companyRepository,
            countryRepository: this.countryRepository,
            ethicalFactsRepository: this.ethicalFactsRepository,
            subscriberRepository: this.subscriberRepository,
            suggestedCompanyRepository: this.suggestedCompanyRepository,
            tagRepository: this.tagRepository,
            subscriberService: new SubscriberService(this.subscriberRepository)
          }
        );
      }
    );
    firebaseAuth.onAuthStateChanged(authUser => {
      authUser
        ? this.setState({ authUser })
        : this.setState({ authUser: null });
    });
    // Initialise recaptcha.
    loadReCaptcha();
  }

  render() {
    if (!this.state.loaded) {
      return <p>Loading...</p>;
    }

    // Here, if the path looks like '/admin', invoke the admin controller; otherwise invoke the public facing controller.
    return (
      <AuthUserContext.Provider value={this.state.authUser}>
        <Router>
          <div className="page-container">
            <div className="main-container">
              <Switch>
                <Route
                  path={/^(?!.*(\/admin)).*$/}
                  render={(props) => <PublicFacingController {...props} categoryRepository={this.state.categoryRepository} companyRepository={this.state.companyRepository} ethicalFactsRepository={this.state.ethicalFactsRepository} suggestedCompanyRepository={this.state.suggestedCompanyRepository} certificationRepository={this.state.certificationRepository} tagRepository={this.state.tagRepository} countryRepository={this.state.countryRepository} subscriberService={this.state.subscriberService}/>}
                />
                <Route
                  path={/\/admin.*$/}
                  render={(props) => <AdminController {...props} categoryRepository={this.state.categoryRepository} companyRepository={this.state.companyRepository} suggestedCompanyRepository={this.state.suggestedCompanyRepository} tagRepository={this.state.tagRepository} subscriberRepository={this.state.subscriberRepository} countryRepository={this.state.countryRepository}/>}
                />
              </Switch>
            </div>
          </div>
        </Router>
      </AuthUserContext.Provider>
    )
  }
}

export default App;
