import AbstractCompanyMaintenance from "./AbstractCompanyMaintenance";
import React from "react";

class CompanyEdit extends AbstractCompanyMaintenance {
  getInitialCompany() {
    return this.companyRepository.getCompanyById(this.props.match.params.id);
  }

  showImageChooserImage() {
    return true;
  }

  render() {
    return (
      <div className="edit">
        <h2>Edit Company - {this.state.company.getName()}</h2>
        {this.maintenanceForm()}
      </div>
    )
  }
}

export default CompanyEdit;
