import AbstractCompanyMaintenance from "./AbstractCompanyMaintenance";
import Company from "../../../models/Company";
import React from "react";

class CompanyAdd extends AbstractCompanyMaintenance {
  getInitialCompany() {
    return new Company({});
  }

  showImageChooserImage() {
    return false;
  }

  render() {
    return (
      <div className="add">
        <h2>Add a New Company</h2>
        {this.maintenanceForm()}
      </div>
    )
  }
}

export default CompanyAdd;
