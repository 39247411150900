import React from 'react';
import ShowEthicalFacts from "./EthicalFacts/ShowEthicalFacts";
import {Link} from "react-router-dom";
import Subscribe from "./publicFacing/Subscribe";

class ShowDefaultInfo extends React.Component {
  constructor(props) {
    super(props);

    this.ethicalFactsRepository = props.ethicalFactsRepository;
    this.categoryRepository = props.categoryRepository;
    this.subscriberService = props.subscriberService;
  }

  scrollToTop = () => {
    // We only want the "Suggest a company" page to scroll to the top if they clicked the link under the "Things You
    // Can Do" heading. There is an option to make it smooth, which I couldn't get to work, but I don't think we should
    // use it anyway because you're clicking a link - you expect to go straight to the page without seeing any scrolling.
    window.scrollTo(0, 0);
  }

  render() {
    // See if there's a current seasonal category (the method returns an array of categories). If there is, display its
    // image, the text and an "Explore" link.
    let seasonalMessage = null;
    let currentSeasonalCategory = this.categoryRepository.getCurrentSeasonalCategories();
    if (currentSeasonalCategory.length > 0 ) {
      currentSeasonalCategory = currentSeasonalCategory.shift();
      seasonalMessage = <div className="row">
        <div className="col-md-12 seasonal-message">
          <img src={currentSeasonalCategory.getImage().getUrl()} alt={currentSeasonalCategory.getSeasonalText()}/>
          <div className="seasonal-content">
            <div className="seasonal-heading">{currentSeasonalCategory.getSeasonalText()}</div>
            <Link to={`/category/${currentSeasonalCategory.getId()}`}>{currentSeasonalCategory.getSeasonalButtonLabel()}</Link>
          </div>
        </div>
      </div>
    }

    return (
      <div>
        <div className="landing-page">

          {seasonalMessage}

          <div className="row">
            <div className="col-md-12">
              <Subscribe template="landing-page-cta" subscriberService={this.subscriberService}/>
            </div>
          </div>

          <div className="row what-is-source-right">

            <div className="col image">
              <img className="woman-choosing-products" src="images/landing-page/source_right_illustration.png" alt="Woman choosing products"/>
            </div>

            <div className="col explanation">
              <h2 className="home-subheading">What is Source Right?</h2>
              <p>
                Source Right is a catalogue of companies which make ethically sourced products. From companies that have
                an authentic interest in environmental stewardship, to those that value sustainable business practices,
                we take you into a world of companies that really care. Trust us, ethical sourcing is at the heart of
                everything we do.
              </p>
            </div>
          </div>

          <img src="images/landing-page/home_shape1.png" className="background1 shape1" alt="Things you can do background"/>
          <div className="row">
            <div className="col-md-12 what-can-you-do">
              <h2 className="home-subheading">Things You Can Do</h2>
              <div className="col-md-4 col-sm-4">
                <img src="/images/landing-page/what-can-you-do/clothes-rack.png" alt="clothes rack"/>
                <Link to="/categories">
                  <div className="description">
                    <h3>SHOP CATEGORIES</h3>
                    <p className="content">Multiple categories and various brands to choose from.</p>
                    <p><span className="glyphicon glyphicon glyphicon-circle-arrow-right" aria-hidden="true"/></p>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-4">
                <img src="/images/landing-page/what-can-you-do/megaphone.png" alt="clothes rack"/>
                <Link to="/suggestCompany" onClick={this.scrollToTop}>
                  <div className="description">
                    <h3>SUGGEST COMPANIES</h3>
                    <p className="content">Which emphasize ethical sourcing and other sustainability practices.</p>
                    <p><span className="glyphicon glyphicon glyphicon-circle-arrow-right" aria-hidden="true"/></p>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-sm-4">
                <img src="/images/landing-page/what-can-you-do/shopping-bag.png" alt="clothes rack"/>
                <a target="_blank" href="https://sourceright.medium.com/5-easy-tips-for-sustainable-living-695aecf7e2d5" rel="noopener noreferrer">
                  <div className="description">
                    <h3>READ OUR BLOG</h3>
                    <p className="content">Day to day actions can make such a big difference.</p>
                    <p><span className="glyphicon glyphicon glyphicon-circle-arrow-right" aria-hidden="true"/></p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 facts">
              <h2 className="home-subheading">Did You Know?</h2>
              <ShowEthicalFacts ethicalFactsRepository={this.ethicalFactsRepository}/>
            </div>
          </div>

          <div className="row partnership-container">
            <img src="images/landing-page/home_shape2.png" className="background2 shape2" alt="Partnership form background"/>
            <div className="col">
              <Subscribe template="landing-partnership" subscriberService={this.subscriberService}/>
            </div>
            <div className="col partnership-image">
              <img id="aboutImg" src="images/landing-page/partner_brand_illustration.png" alt="Partnership illustration"/>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default ShowDefaultInfo;
