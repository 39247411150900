import React from "react";
import Category from "../../../models/Category";
import AbstractCategoryMaintenance from "./AbstractCategoryMaintenance";

class CategoryAdd extends AbstractCategoryMaintenance {
  getInitialCategory() {
    return new Category({});
  }

  showImageChooserImage() {
    return false;
  }

  render() {
    return (
      <div className="add">
        <h2>Add a New Category</h2>
        {this.maintenanceForm()}
      </div>
    )
  }
}

export default CategoryAdd;
