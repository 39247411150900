import React from 'react';
import {Route} from "react-router-dom";
import CategoryList from "./Categories/CategoryList";
import CategoryEdit from "./Categories/CategoryEdit";
import CompanyList from "./companies/CompanyList";
import CompanyEdit from "./companies/CompanyEdit";
import CompanyAdd from "./companies/CompanyAdd";
import Dashboard from "./Dashboard/Dashboard";
import CategoryAdd from "./Categories/CategoryAdd";
import UserProfile from "./Users/UserProfile";
import UserList from "./Users/UserList";
import TagList from "./Tags/TagList";
import TagEdit from "./Tags/TagEdit";
import SuggestedCompanyList from "./companies/Suggested/SuggestedCompanyList";
import SuggestedCompanyReview from "./companies/Suggested/SuggestedCompanyReview";
import SuggestedCompanyEdit from "./companies/Suggested/SuggestedCompanyEdit";

class AdminAuthenticatedController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryRepository: this.props.categoryRepository,
      companyRepository: this.props.companyRepository,
      countryRepository: this.props.countryRepository,
      subscriberRepository: this.props.subscriberRepository,
      suggestedCompanyRepository: this.props.suggestedCompanyRepository,
      tagRepository: this.props.tagRepository
    };
  }

  render() {
    return (
      <div>
        <Route exact path="/admin">
          <Dashboard suggestedCompanyRepository={this.state.suggestedCompanyRepository} companyRepository={this.state.companyRepository} categoryRepository={this.state.categoryRepository} subscriberRepository={this.state.subscriberRepository}/>
        </Route>
        <Route exact path="/admin/dashboard">
          <Dashboard suggestedCompanyRepository={this.state.suggestedCompanyRepository} companyRepository={this.state.companyRepository} categoryRepository={this.state.categoryRepository} subscriberRepository={this.state.subscriberRepository}/>
        </Route>
        <Route
          exact
          path="/admin/companies/edit/:id"
          render={(props) => <CompanyEdit {...props} companyRepository={this.state.companyRepository} categoryRepository={this.state.categoryRepository} tagRepository={this.state.tagRepository} countryRepository={this.state.countryRepository}/>}
        />
        <Route
          exact
          path="/admin/companies/add"
          render={(props) => <CompanyAdd {...props} companyRepository={this.state.companyRepository} categoryRepository={this.state.categoryRepository} tagRepository={this.state.tagRepository} countryRepository={this.state.countryRepository}/>}
        />
        <Route
          exact
          path="/admin/companies/list"
          render={(props) => <CompanyList {...props} companyRepository={this.state.companyRepository} categoryRepository={this.state.categoryRepository} tagRepository={this.state.tagRepository}/>}
        />
        <Route
          exact
          path="/admin/categories/list"
          render={(props) => <CategoryList {...props} categoryRepository={this.state.categoryRepository}/>}
        />
        <Route
          exact
          path="/admin/categories/add"
          render={(props) => <CategoryAdd {...props} categoryRepository={this.state.categoryRepository}/>}
        />
        <Route
          exact
          path="/admin/categories/edit/:id"
          render={(props) => <CategoryEdit {...props} categoryRepository={this.state.categoryRepository}/>}
        />
        <Route exact path="/admin/tags/list">
          <TagList tagRepository={this.state.tagRepository} companyRepository={this.state.companyRepository}/>
        </Route>
        <Route
          exact
          path="/admin/tags/edit/:id"
          render={(props) => <TagEdit {...props} tagRepository={this.state.tagRepository} companyRepository={this.state.companyRepository}/>}
        />
        <Route exact path="/admin/suggestedCompanies/list">
          <SuggestedCompanyList suggestedCompanyRepository={this.state.suggestedCompanyRepository} tagRepository={this.state.tagRepository} categoryRepository={this.state.categoryRepository}/>
        </Route>
        <Route
          exact
          path="/admin/suggestedCompanies/review/:id"
          render={(props) => <SuggestedCompanyReview {...props} companyRepository={this.state.companyRepository} categoryRepository={this.state.categoryRepository} tagRepository={this.state.tagRepository} suggestedCompanyRepository={this.state.suggestedCompanyRepository} countryRepository={this.state.countryRepository}/>}
        />
        <Route
          exact
          path="/admin/suggestedCompanies/edit/:id"
          render={(props) => <SuggestedCompanyEdit {...props} companyRepository={this.state.companyRepository} categoryRepository={this.state.categoryRepository} tagRepository={this.state.tagRepository} suggestedCompanyRepository={this.state.suggestedCompanyRepository} countryRepository={this.state.countryRepository}/>}
        />
        <Route exact path="/admin/users/list">
          <UserList/>
        </Route>
        <Route exact path="/admin/users/add">
          <UserProfile/>
        </Route>
        <Route exact path="/admin/users/edit">
          <UserProfile authUser={this.props.authUser} onUserEdit={this.props.onUserEdit}/>
        </Route>
      </div>
    )
  }
}

export default AdminAuthenticatedController;
