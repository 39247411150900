import React from 'react';
import FieldText from "../FormWidgets/FieldText";
import FieldTextArea from "../FormWidgets/FieldTextArea";
import Company from "../../../models/Company";
import ImageChooser from "../FormWidgets/ImageChooser";
import ModelImage from "../../../models/common/ModelImage";
import ErrorBlock from "../FormWidgets/ErrorBlock";
import FieldCategorySelector from "../FormWidgets/FieldCategorySelector";
import FieldTagSelector from "../FormWidgets/FieldTagSelector";
import CompanyFieldAttributes from "../../../services/CompanyFieldAttributes";
import FieldCountrySelector from "../FormWidgets/FieldCountrySelector";

// @TODO: A lot of this code can be commonised. Eg setters for name, desc, image. Have parent class; category edit and company edit to extend it.
class AbstractCompanyMaintenance extends React.Component {
  constructor(props) {
    super(props);

    this.companyRepository = this.props.companyRepository;
    this.categoryRepository = this.props.categoryRepository;
    this.tagRepository = this.props.tagRepository;
    this.countryRepository = this.props.countryRepository;

    this.company = this.getInitialCompany();
    this.fieldAttributes = this.getFieldAttributes();
    this.allCategories = this.categoryRepository.getCategories().sort(
      function(category1, category2) {
        return category1.getName().toLowerCase() > category2.getName().toLowerCase() ? 1 : -1
      }
    );

    this.state = {
      company: this.company,
      companyCopy: {
        id: this.company.getId(),
        name: this.company.getName(),
        description: this.company.getDescription(),
        image: this.company.getImage(),
        categoryIds: this.company.getCategoryIds(),
        tagIds: this.company.getTagIds(),
        linkUrl: this.company.getLinkUrl(),
        countryCodes: this.company.getCountryCodes()
      },
      newImage: null,
      errors: [],
      saveButtonEnabled: this.getSaveButtonInitialEnabledState(),
      internalNotesEnabled: false,
      adminNotesEnabled: false
    };
  }

  getSaveButtonInitialEnabledState() {
    return true; // Override this method if you need the save button to start disabled.
  }

  setSaveButtonEnabled() {
    this.setState({saveButtonEnabled: true});
  }

  setSaveButtonDisabled() {
    this.setState({saveButtonEnabled: false});
  }

  getInitialCompany() {
    // This is an abstract method that must be overridden in the subclasses. Not sure how to enforce that at the moment.
  }

  /**
   * Get the appropriate attributes for this screen.
   * @return {CompanyFieldAttributes}
   */
  getFieldAttributes() {
    return new CompanyFieldAttributes();
  }

  isViewScreen() {
    return false;
  }

  isSubmittedSuggestedCompany() {
    return false;
  }

  showImageChooserImage() {
    return true;
  }

  excludeSeasonalCategories() {
    return false; // Override this in subclass(es).
  }

  allowAddCategory() {
    return true;
  }

  allowAddTag() {
    return true;
  }

  additionalFields() {
    return null;
  }

  updateCompanyCopy(attributeName, attributeValue) {
    let newCompanyCopy = this.state.companyCopy;
    newCompanyCopy[attributeName] = attributeValue;
    this.setState({companyCopy: newCompanyCopy});
  }

  setName = event => {
    this.updateCompanyCopy('name', event.target.value);
  }

  setDescription = event => {
    this.updateCompanyCopy('description', event.target.value);
  }

  /**
   * In this setter, we don't populate anything in the company copy because we want to keep showing the original image.
   * Instead we put it in a separate property of the state, which gets picked up on save.
   * @param event
   */
  setImage = event => {
    this.setState({newImage: new ModelImage(event[0])});
  }

  setCategories = categoryIDs => {
    this.updateCompanyCopy('categoryIds', categoryIDs);
  }

  /**
   * This receives an array of the ids of _all_ the tags against the company (whether or not they've been saved against the company).
   * @param {array} tagIds
   */
  setTags = tagIds => {
    this.updateCompanyCopy('tagIds', tagIds);
  }

  /**
   * This receives an array of the ids of _all_ the country codes against the company (whether or not they've been
   * saved against the company).
   * @param {array} countryCodes
   */
  setCountries = countryCodes => {
    this.updateCompanyCopy('countryCodes', countryCodes);
  }

  setLinkUrl = event => {
    this.updateCompanyCopy('linkUrl', event.target.value);
  }

  prepareObjectToSave() {
    return new Company(this.state.companyCopy);
  }

  persistObject(objectToSave) {
    return this.companyRepository.persist(
      objectToSave,
      'The company was saved!',
      () => {this.props.history.push('/admin/companies/list');} // @TODO: We should make this URL a constant somewhere.
    );
  }

  saveCompany = event => {
    event.preventDefault();
    let objectToSave = this.prepareObjectToSave();
    // If the user has chosen a new image, give it to the company (or whatever) object that we're about to save.
    if (this.state.newImage) {
      objectToSave.setImage(this.state.newImage);
    }
    let result = this.persistObject(objectToSave);
    this.setState({errors: result.getErrors()});
    // If there are any warning, just alert them, but the processing won't be aborted.
    if (result.hasWarnings()) {
      result.getWarnings().forEach(
        function (warningMessage) {
          alert('Warning: '+warningMessage);
        }
      );
    }
  }

  maintenanceForm() {
    // Even when the screen is flagged as "isViewScreen", we still show the save button because at the moment, the only
    // "view-only" screen does have a field for adding to the additional details.
    // @TODO: We ought to make the above more explicit.
    return (
      <form>
        <FieldText isViewScreen={this.isViewScreen()} label="Name" value={this.state.companyCopy.name} required={this.fieldAttributes.isRequired('name')} onchange={this.setName}/>
        <FieldTextArea isViewScreen={this.isViewScreen()} label="Description" value={this.state.companyCopy.description} required={this.fieldAttributes.isRequired('description')} onchange={this.setDescription}/>
        <ImageChooser isViewScreen={this.isViewScreen()} label="Image" showImage={this.showImageChooserImage()} value={this.state.companyCopy.image} required={this.fieldAttributes.isRequired('image')} onchange={this.setImage}/>
        <FieldCategorySelector
          isViewScreen={this.isViewScreen()}
          required={this.fieldAttributes.isRequired('categories')}
          excludeSeasonalCategories={this.excludeSeasonalCategories()}
          allowCreateModel={this.allowAddCategory()}
          modelRepository={this.categoryRepository}
          currentlySelectedIds={this.state.company.getCategoryIds()}
          onchange={this.setCategories}
        />
        <FieldTagSelector
          isViewScreen={this.isViewScreen()}
          required={this.fieldAttributes.isRequired('tags')}
          allowCreateModel={this.allowAddTag()}
          modelRepository={this.tagRepository}
          currentlySelectedIds={this.state.company.getTagIds()}
          onchange={this.setTags}
        />
        <FieldText isViewScreen={this.isViewScreen()} label="Link Url" value={this.state.companyCopy.linkUrl} placeholderValue="The company's website" required={this.fieldAttributes.isRequired('linkUrl')} onchange={this.setLinkUrl}/>
        <FieldCountrySelector
          isViewScreen={this.isViewScreen()}
          required={this.fieldAttributes.isRequired('countries')}
          allowCreateModel={false}
          modelRepository={this.countryRepository}
          currentlySelectedIds={this.state.company.getCountryCodes()}
          onchange={this.setCountries}
        />
        {this.additionalFields()}
        {!this.isViewScreen() &&
        <ErrorBlock errorMessages={this.state.errors}/>
        }
        {!this.isSubmittedSuggestedCompany() &&
        <div className="row">
          <div className="col-md-2">
            <input type="button" value="Save" className="btn btn-primary" disabled={!this.state.saveButtonEnabled} onClick={this.saveCompany}/>
          </div>
        </div>
        }
      </form>
    )
  }
}

export default AbstractCompanyMaintenance;
