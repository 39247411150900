/**
 * This file is emulating ethical facts data coming from a database (or firebase). It is read by
 * the data repository.
 *
 * @type {*[]}
 */
let ethicalFactsJson = [
    {
      "logoFilename": "shop_icon.png",
      "fact": "82% of Gen Z are more likely to buy from a brand that uses ethical business practices."
    },
    {
      "logoFilename": "wallet_icon.png",
      "fact": "66% of global consumers say they would pay more for sustainable brands, while for millennials that number rises to 73 percent."
    },
    {
      "logoFilename": "forced_labor_icon.png",
      "fact": "Child labor rates dropped by over one third between 2000 and 2017, and they’ve continued this downward trend."
    },
]
;

export default ethicalFactsJson;
