import {firebaseAuth} from "../../dataStore/firebase";
import React, {Component} from "react";

const INITIAL_STATE = {
  inForgottenPasswordMode: false,
  email: '',
  password: '',
  error: null,
};

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  toggleForgottenPasswordMode = event => {
    event.preventDefault();
    this.setState({inForgottenPasswordMode: !this.state.inForgottenPasswordMode});
  }

  sendPasswordResetLink = event => {
    event.preventDefault();
    if (this.state.email === '') {
      window.alert('You must enter an email address to use that facility');
    } else {
      if (window.confirm('Send a reset password link to that address. Are you sure?')) {
        firebaseAuth.sendPasswordResetEmail(this.state.email)
          .then(
            function() {
              window.alert('The email was sent successfully. Please follow the instructions in that email.');
            }
          )
          .catch(
            function(error) {
              window.alert('There was an error sending the link: '+error);
            }
          )
      }
    }
  }

  onSubmit = event => {
    const { email, password } = this.state;

    firebaseAuth.signInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push('/admin');
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <form className='login-form' onSubmit={this.onSubmit}>
        <div className="row">
          <div className="col-md-6">
            <h2>Please log in</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1">Email</div>
          <div className="col-md-3">
            <input
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
          </div>
          {this.state.inForgottenPasswordMode &&
          <div className="col-md-3">
            <button onClick={this.sendPasswordResetLink}>Send Password Reset Link</button>
          </div>
          }
        </div>
        {!this.state.inForgottenPasswordMode &&
        <div className="row">
          <div className="col-md-1">Password</div>
          <div className="col-md-3">
            <input
              name="password"
              value={password}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
            />
          </div>
        </div>
        }
        <div className="row">
          <div className="col-md-1">
          </div>
          {!this.state.inForgottenPasswordMode &&
          <div className="col-md-2">
            <button disabled={isInvalid} type="submit">
              Sign In
            </button>
          </div>
          }
          {!this.state.inForgottenPasswordMode &&
          <div className="col-md-2">
            <a href="?" onClick={this.toggleForgottenPasswordMode}>
              Forgotten password?
            </a>
          </div>
          }
          {this.state.inForgottenPasswordMode &&
          <div className="col-md-2">
            <a href="?" onClick={this.toggleForgottenPasswordMode}>
              Cancel
            </a>
          </div>
          }
        </div>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

export default LoginPage;
