import EthicalFact from "../models/EthicalFact";
import ethicalFactsJson from "./internalDataSource/ethicalFacts";

/**
 * This class is the interface between the certification data and the storage engine (at time of writing, a file in
 * the application). It is instantiated when the data repository factory is instantiated. The raw data is loaded from
 * the file whenever the application asks for it. At the moment, the data is read-only.
 * If/when the certification data is stored like the rest of the data, this class should be refactored to work like the
 * other ones, so extend AbstractDataRepository, implement persist, etc.
 */
class EthicalFactsRepository {
  constructor() {
    this.ethicalFacts = null;
  }

  /**
   * Ethical facts are only loaded on demand. If the class property is null, it means we haven't
   * tried to load them (note: an empty array would mean we tried to load them and there were zero
   * of them).
   * This method picks "n" ethical facts from the list of all facts, in random order, where "n" is whichever is the
   * lower of "max" and the number of ethical facts. For now, it just returns _all_ the facts. In time we'll amend this
   * method, but we won't have to change the calling code at all.
   *
   * @return {EthicalFact[]}
   */
  getRandom(max) {
    // If we haven't loaded the ethical facts, load them now.
    if (this.ethicalFacts === null) {
      /**
       * @var {Array} ethicalFactsJson Defined in src/dataStore/internalDataSource/ethicalFacts.js
       */
      this.ethicalFacts = [];
      for(let factIdx in ethicalFactsJson) {
        if (ethicalFactsJson.hasOwnProperty(factIdx)) {
          this.ethicalFacts.push(new EthicalFact(ethicalFactsJson[factIdx]));
        }
      }
    }

    return this.ethicalFacts;
  };
}

export default EthicalFactsRepository;
