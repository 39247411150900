import {firebaseStorage, firebaseStorageRef} from "./firebase";

/**
 * This class handles uploading files to the firebase file storage area. Specifically, it allows images to be uploaded,
 * and makes the download URL available to the calling code's callback function.
 */
class StorageRepository {
  constructor() {
    this.storage = firebaseStorage;
    this.storageRef = firebaseStorageRef;
  }

  /**
   * @param {ModelImage} imageData
   * @param {string} targetFolder
   * @param callback
   */
  uploadImageInModel(imageData, targetFolder, callback) {
    // set file metadata
    var metadata = {
      contentType: 'image/jpeg' // @TODO: Should this change depending on the format of the image?
    };

    // use Firebase push call to upload file to Firebase
    var file = imageData.getFile();
    var uploadTask = this.storageRef.child(targetFolder + '/images/' + file.name).put(file, metadata);

    // monitor Firebase upload progress and catch errors
    uploadTask.on(
      this.storage.TaskEvent.STATE_CHANGED,
      function (snapshot) {
      },
      function (error) {
        // catch an error when it happens, note: there are more error codes
        switch (error.code) {
          case 'storage/bucket_not_found':
            console.log('The Bucket for this storage could not be found');
            break;
          case 'storage/unauthorized':
            console.log('User doesn\'t have access');
            break;
          case 'storage/cancelled':
            console.log('User cancelled the upload process');
            break;
          case 'storage/unknown':
          default:
            console.log('Unknown error');
            break;
        }
        return;
      },
      function () {
        // on success, get the download URL and call any callback function, passing in that URL.
        uploadTask.snapshot.ref.getDownloadURL().then(
          function(downloadURL) {
            if (callback) {
              callback(downloadURL);
            }
          }
        );
      }
    );
  };
}

export default StorageRepository;
