import React from 'react';
import AuthUserContext from '../session/context';
import AdminAuthenticatedController from "./AdminAuthenticatedController";
import LoginPage from "./LoginPage";
import LogOutButton from "./LogOutButton";
import {firebaseAuth} from "../../dataStore/firebase";
import MainLogo from "../MainLogo";
import {Link} from "react-router-dom";

class AdminController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryRepository: this.props.categoryRepository,
      companyRepository: this.props.companyRepository,
      countryRepository: this.props.countryRepository,
      subscriberRepository: this.props.subscriberRepository,
      suggestedCompanyRepository: this.props.suggestedCompanyRepository,
      tagRepository: this.props.tagRepository,
      currentUser: firebaseAuth.currentUser,
      refreshCount: 0
    };
  }

  userWasEdited = () => {
    // This is a bit of a hack to force the user's name in the top-right corner to update after they edit their profile.
    this.setState({refreshCount: 1 + this.state.refreshCount});
  }

  componentDidMount() {
    firebaseAuth.onAuthStateChanged(authUser => {
      this.setState({ currentUser: authUser });
    });
  }

  render() {
    return (
      <div className="admin">
        <div className="header">
          <div className="navbar" role="navigation">
            <div className="container">
              <div className="collapse navbar-collapse navbar-expand-lg" id="js-navbar-collapse">
                <ul className="nav navbar-nav">
                  <li><Link to="/"><MainLogo/></Link></li>
                  <li><Link to='/admin/dashboard'>Dashboard</Link></li>
                  <li><Link to='/admin/companies/list'>Companies</Link></li>
                  <li><Link to='/admin/categories/list'>Categories</Link></li>
                  <li><Link to='/admin/tags/list'>Tags</Link></li>
                  <li><Link to='/admin/suggestedCompanies/list'>Suggested Companies</Link></li>
                  <li><Link to='/admin/users/list'>Users</Link></li>
                </ul>
                <span className="navbar-text pull-right logout-container">{this.state.currentUser ? <LogOutButton refreshCount={this.state.refreshCount}/> : null}</span>
              </div>
            </div>
          </div>
        </div>

        <AuthUserContext.Consumer>
          {authUser =>
            authUser ? <AdminAuthenticatedController categoryRepository={this.state.categoryRepository} companyRepository={this.state.companyRepository} suggestedCompanyRepository={this.state.suggestedCompanyRepository} tagRepository={this.state.tagRepository} subscriberRepository={this.state.subscriberRepository} countryRepository={this.state.countryRepository} authUser={authUser} onUserEdit={this.userWasEdited}/> : <LoginPage/>
          }
        </AuthUserContext.Consumer>
      </div>
    );
  }
}

export default AdminController;
