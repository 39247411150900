import React from 'react';
import {Link} from "react-router-dom";

class CategoryListOne extends React.Component {
  constructor(props) {
    super(props);
    this.categoryRepository = props.categoryRepository;
    this.state = {
      category: this.props.category
    };
  }

  makeCurrent = event => {
    this.changeCurrentTo(this.props.category.getId(), true, 'That category is now the current seasonal category');
  }

  stopCurrent = event => {
    this.changeCurrentTo(this.props.category.getId(), false, 'There is now no current seasonal category');
  }

  changeCurrentTo(categoryID, newState, successMessage) {
    if (window.confirm('Are you sure you want to change the current seasonal category?')) {
      let category = this.categoryRepository.getCategoryById(categoryID);
      if (category) {
        category.setIsCurrentSeasonalCategory(newState);
        this.categoryRepository.persist(category, successMessage);
        this.props.reload();
      }
    }
  }

  // @TODO: We need the CSS here, of course.
  render() {
    let seasonalOptions = null;
    if (this.props.category.getIsSeasonalCategory()) {
      if (this.props.category.getIsCurrentSeasonalCategory()) {
        seasonalOptions = <button className="btn btn-danger" onClick={this.stopCurrent}>Remove current!</button>;
      } else {
        seasonalOptions = <button className="btn btn-success" onClick={this.makeCurrent}>Make current</button>;
      }
      seasonalOptions = <div><p>Seasonal!</p>{seasonalOptions}</div>;
    }

    return (
      <div className="row record">
        <div className="col-md-9 name-description">
          <h2>{this.state.category.getName()}</h2>
          <p>{this.state.category.getDescription()}</p>
        </div>
        <div className="col-md-2 seasonal">
          {seasonalOptions}
        </div>
        <div className="col-md-1 edit-link">
          <Link to={`/admin/categories/edit/${this.props.category.getId()}`}>Edit</Link>
        </div>
      </div>
    )
  }
}

export default CategoryListOne;
