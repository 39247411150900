import React from "react";
import {Link} from "react-router-dom";
import LabelLookup from "../../../../services/LabelLookup";
import SuggestedCompanyListContext from "../../../Contexts/SuggestedCompanyListContext";
import {SuggestedCompanyStatuses} from "../../../../models/Statuses/SuggestedCompanyStatuses";

class SuggestedCompanyList extends React.Component {
  constructor(props) {
    super(props);
    // We store the states of the status checkboxes and the sort columns/directions in local storage, and retrieve them here.
    this.state = {
      suggestedCompanyRepository: this.props.suggestedCompanyRepository,
      suggestedCompanies: this.props.suggestedCompanyRepository.getAll(),
      suggestedCompanyListContext: new SuggestedCompanyListContext(JSON.parse(localStorage.getItem('suggestedCompanyListContext')))
    };

    this.categoriesById = new LabelLookup(
      this.props.categoryRepository.getCategories(),
      (category) => {return category.getId()},
      (category) => {return category.getName()}
    )
    this.tagsById = new LabelLookup(
      this.props.tagRepository.getTags(),
      (tag) => {return tag.getId()},
      (tag) => {return tag.getName()}
    )
  }

  toggleStatusFilter(status) {
    this.state.suggestedCompanyListContext.toggleStatus(status);
    this.setState(
      {
        suggestedCompanyListContext: this.state.suggestedCompanyListContext
      },
      () => {
        // Save the new state of the checkboxes/sorting into local storage.
        localStorage.setItem('suggestedCompanyListContext', JSON.stringify(this.state.suggestedCompanyListContext))
      }
    );
  }

  render () {
    // @TODO: For the companies list, there are separate components for the heading line and each record in the list. We should do that, really.
    let allSuggestedCompanies = [];

    // Filter and sort the suggested companies.
    // @TODO: We need to paginate the list and do anything else that's needed.
    let filteredSuggestedCompanies = this.state.suggestedCompanies;
    filteredSuggestedCompanies = filteredSuggestedCompanies.filter(
      (suggestedCompany) => {
        return this.state.suggestedCompanyListContext.statusMatches(suggestedCompany.getStatus());
      }
    )
    // Sort the records.
    if (this.state.suggestedCompanyListContext.hasSortColumns()) {
      let sortColumns = this.state.suggestedCompanyListContext.getSortColumns();
      filteredSuggestedCompanies.sort(
        function (suggestedCompany1, suggestedCompany2) {
          // We need to loop through the sort columns, testing the respective values in the two suggested companies.
          // If they are different, we just return the relative order; otherwise, move on to the next one.
          for (let sortIdx = 0; sortIdx < sortColumns.length; sortIdx++) {
            let value1, value2;
            switch(sortColumns[sortIdx].column) {
              case 'createdAt':
                value1 = suggestedCompany1.getCreatedAt();
                value2 = suggestedCompany2.getCreatedAt();
                break;
              default:
                window.alert('Error: sort column "'+sortColumns[sortIdx].column+'" has not been defined');
                value1 = 0;
                value2 = 0;
                break;
            }
            let sign = sortColumns[sortIdx].direction === 'asc' ? -1 : 1;
            if (value1 !== value2) {
              // Values are different, so we can return a definite answer.
              return (value1 < value2 ? 1 : -1) * sign;
            }
            // Values are same; move on to the next column, if there is one.
          }

          // All values are the same: return "they are the same".
          return 0;
        }
      );
    }

    for (let scIdx = 0; scIdx < filteredSuggestedCompanies.length; scIdx++) {
      let suggestedCompany = filteredSuggestedCompanies[scIdx];

      // Build a list of categories and a list of tags.
      let categoryNames = suggestedCompany.hasCategoryIds() ? this.categoriesById.idsToLabels(suggestedCompany.getCategoryIds()) : null;
      let tagNames = suggestedCompany.hasTagIds() ? this.tagsById.idsToLabels(suggestedCompany.getTagIds()) : null;

      // @TODO: We need a global function for prefixing a URL with http or https. It also doesn't work if the URL starts with https.
      let linkUrl = null;
      if (suggestedCompany.hasLinkUrl()) {
        linkUrl = suggestedCompany.getLinkUrl();
        if (linkUrl.indexOf('http') !== 1) {
          linkUrl = 'https://'+linkUrl
        }
      }

      let reviewLabelMap = {};
      reviewLabelMap[SuggestedCompanyStatuses.submitted] = 'View';
      reviewLabelMap[SuggestedCompanyStatuses.pending] = 'Review';
      reviewLabelMap[SuggestedCompanyStatuses.approved] = 'View';
      reviewLabelMap[SuggestedCompanyStatuses.rejected] = 'Re-review';
      let reviewLabel = reviewLabelMap[suggestedCompany.getStatus()];

      allSuggestedCompanies.push(
        <div className="row record" key={suggestedCompany.getId()}>
          <div className="col-md-1 thumbnail"><img src={suggestedCompany.getImage().getUrl()} alt="Company"/></div>
          <div className="col-md-1">{suggestedCompany.getName()}</div>
          <div className="col-md-1">{categoryNames}</div>
          <div className="col-md-1">{tagNames}</div>
          <div className="col-md-2">{suggestedCompany.getDescription()}</div>
          <div className="col-md-2">{linkUrl ? <a href={linkUrl} target="_blank" rel="noopener noreferrer">{suggestedCompany.getLinkUrl()}</a> : null}</div>
          <div className="col-md-2 additional-details">{suggestedCompany.getSuggesterAdditionalDetails()}</div>
          <div className="col-md-1"><Link to={`/admin/suggestedCompanies/review/${suggestedCompany.getId()}`}>{reviewLabel}</Link></div>
          <div className={'col-md-1 status '+suggestedCompany.getStatus().toLowerCase()}>{suggestedCompany.getStatus()}</div>
        </div>
      );
    }

    let suggestedCompaniesDetail = <p>There are no suggested companies</p>;
    if (this.state.suggestedCompanies.length > 0) {
      let statusFilterOptions = [];
      for(let status in this.state.suggestedCompanyListContext.getStatusFilter()) {
        statusFilterOptions.push(
          <label id={status} key={status}>
            <input
              type="checkbox"
              checked={this.state.suggestedCompanyListContext.statusMatches(status)}
              onChange={() => {this.toggleStatusFilter(status)}}
            />
            &nbsp;
            {status}
          </label>
        );
      }
      let content;
      if (allSuggestedCompanies.length > 0) {
        content =
          <div className="suggested-companies-list">
            <div className="row">
              <div className="col-md-1">Image</div>
              <div className="col-md-1">Name</div>
              <div className="col-md-1">Categories</div>
              <div className="col-md-1">Tags</div>
              <div className="col-md-2">Description</div>
              <div className="col-md-2">Link</div>
              <div className="col-md-2">Additional Notes</div>
              <div className="col-md-1">Action</div>
              <div className="col-md-1">Status</div>
            </div>
            {allSuggestedCompanies}
          </div>
      } else {
        content = <p>No suggested companies match your criteria</p>
      }
      suggestedCompaniesDetail =
        <div className="suggested-companies">
          <div>
            <p className="filter-options">Show items {statusFilterOptions}</p>
          </div>
          {content}
        </div>;
    }

    return suggestedCompaniesDetail;
  }
}

export default SuggestedCompanyList;
