import React from 'react';

class OneCertification extends React.Component {
  constructor(props) {
    super(props);

    /**
     * @var {Certification} certification
     */
    this.certification = props.certification;
    this.uniqueId = props.uniqueId;
  }

  wrapWithLink(content, url, index) {
    return <a href={url} target="_blank" rel="noopener noreferrer" key={'link-'+this.uniqueId+index}>{content}</a>;
  }

  render() {
    let heading = this.certification.getName();
    if (this.certification.getHeadingUrl()) {
      heading = this.wrapWithLink(heading, this.certification.getHeadingUrl());
    }

    let images = [];
    if (this.certification.hasImages()) {
      let self = this;
      this.certification.getImages().forEach(
        function (image, index) {
          let imageElement = <img key={'image-'+self.uniqueId+'-'+index} src={image} alt="TBA"/>;
          if (self.certification.getHeadingUrl()) {
            imageElement = self.wrapWithLink(imageElement, self.certification.getHeadingUrl(), index);
          }
          images.push(imageElement);
        }
      )
    }

    return (
        <div className="col-sm-4">
          <div className="rectangleCertificate">
            <div className="images">{images}</div>
          </div>
          <h4 style={{width: "250px"}}>{heading}</h4>
        </div>
    );
  }
}

export default OneCertification;
