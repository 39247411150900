/**
 * This class helps with the fields for companies and suggested companies.
 * Initially it will just determine which fields are optional and which are mandatory.
 * The SuggestedCompanyFields subclass overrides the settings with its own settings, where necessary.
 */
class CompanyFieldAttributes {
  constructor() {
    // @TODO: We should use constants to represent these keys, so that the code can be statically analyised.
    this.fields = {
      name: {required: true},
      description: {required: true},
      image: {required: true},
      categories: {required: true},
      tags: {required: false},
      linkUrl: {required: true},
      countryCodes: {required: false},
    };
  }

  isRequired(fieldName) {
    if (this.fields.hasOwnProperty(fieldName) && this.fields[fieldName].hasOwnProperty('required')) {
      return this.fields[fieldName].required;
    }

    return false; // @TODO: We should throw some kind of exception here.
  }
}

export default CompanyFieldAttributes;
