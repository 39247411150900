import React from "react";

class CategoryFilter extends React.Component {
  constructor(props) {
    super(props);

    this.allCategories = this.props.categoryRepository.getCategories().sort(
      function(category1, category2) {
        return category1.getName().toLowerCase() > category2.getName().toLowerCase() ? 1 : -1
      }
    );
  }

  render() {
    let self = this;

    let checkboxes = [];
    this.allCategories.forEach(
      function (category) {
        let isChecked = self.props.selectedIds.indexOf(category.getId()) >= 0;
        checkboxes.push(
          <div key={category.getId()} className="col-md-6">
            <label>
              <input type="checkbox" checked={isChecked} onChange={() => self.props.toggleFilterId(category.getId())}/> {category.getName()}
            </label>
          </div>);
      }
    )

    let popupClasses = 'category-filter';
    let shieldClasses = 'category-filter-background';
    if (!this.props.visible) {
      popupClasses += ' hidden';
      shieldClasses += ' hidden';
    }

    return (
      <div>
        <div className={popupClasses}>
          <div className="close-category-filter pull-right" title="Close the category filter popup" onClick={this.props.toggleVisibility}>X</div>
          <h3>Category</h3>
          <div className="row filter-content">
            {checkboxes}
          </div>
        </div>
        <div className={shieldClasses} onClick={this.props.toggleVisibility}/>
      </div>
    )
  }
}

export default CategoryFilter;
