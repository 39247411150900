import AbstractFieldObjectSelector from "./AbstractFieldObjectSelector";
import AbstractEmbeddedCategoryForm from "../Categories/AbstractEmbeddedCategoryForm";
import React from "react";

class FieldCategorySelector extends AbstractFieldObjectSelector {
  constructor(props) {
    super(props);

    this.categoryRepository = this.props.modelRepository;
    this.excludeSeasonalCategories = !!this.props.excludeSeasonalCategories;
    this.state.showAddCategoryForm = false;
  }

  getLabel() {
    return 'Categories';
  }

  loadAllModels() {
    return this.categoryRepository.getCategories(null, this.excludeSeasonalCategories);
  }

  getObjectById(id) {
    return this.categoryRepository.getCategoryById(id);
  }

  getModelId(category) {
    return category.getId();
  }

  getModelName(category) {
    return category.getName();
  }

  getModelDescription() {
    return 'category';
  }

  createModel = name => {
    this.setState({showAddCategoryForm: true});
  }

  hideEmbeddedForm = () => {
    this.setState({showAddCategoryForm: false});
  }

  afterAddingCategory = (newCategoryId) => {
    this.refreshAllModels();
    this.selectModel(newCategoryId);
  }

  getContentOverride() {
    if (this.state.showAddCategoryForm) {
      return <div className="col-md-7">
        <AbstractEmbeddedCategoryForm
          handleRemoveForm={this.hideEmbeddedForm}
          categoryRepository={this.categoryRepository}
          addnewCategory={this.afterAddingCategory}
          initialName={this.state.enteredValue}
        />
      </div>;
    } else {
      return null;
    }
  }
}

export default FieldCategorySelector;
