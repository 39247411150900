import React from 'react';
import { ReCaptcha } from 'react-recaptcha-google'

class Recaptcha extends React.Component {
  constructor(props) {
    super(props);
    this.hasOffset = !(props.hasOwnProperty('noOffset') && props.noOffset === true);
    this.elementID = props.hasOwnProperty('elementID') ? props.elementID : 'g-recaptcha';
  }

  componentDidMount() {
    if (this.captchaWidget) {
      this.captchaWidget.reset();
    }
  }

  onLoadRecaptcha = () => {
    if (this.captchaWidget) {
      this.captchaWidget.reset();
    }
  }

  verifyCallback = recaptchaToken => {
    // Here you will get the final recaptchaToken!!!
    this.props.onVerify(recaptchaToken);
  }

  expiredCallback = () => {
    this.props.onExpire();
  }

  render() {
    return (
      <ReCaptcha
        ref={(el) => {this.captchaWidget = el;}}
        size="normal"
        theme="light"
        render="explicit"
        sitekey={process.env.REACT_APP__RECAPTCHA__SITE_KEY}
        onloadCallback={this.onLoadRecaptcha}
        verifyCallback={this.verifyCallback}
        expiredCallback={this.expiredCallback}
        elementID={this.elementID}
      />
    )
  }
}

export default Recaptcha;
