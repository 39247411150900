import Country from "../models/Country";
import countriesJson from "./internalDataSource/countries.js";

/**
 * This class is the interface between the country data and the storage engine (at time of writing, a file in
 * the application). It is instantiated when the data repository factory is instantiated. The raw data is loaded from
 * the file whenever the application asks for it. I don't expect the storage location to change.
 */
class CountryRepository {
  constructor() {
    this.countries = null;
  }

  /**
   * Countries are only loaded on demand. If the class property is null, it means we haven't
   * tried to load them (note: an empty array would mean we tried to load them and there were zero
   * of them).
   *
   * @return {Country[]}
   */
  getCountries() {
    // If we haven't loaded the countries, load them now.
    if (this.countries === null) {
      /**
       * @var {Array} countriesJson Defined in src/dataStore/internalDataSource/countries.js
       */
      this.countries = [];
      for(let countryIdx in countriesJson) {
        if (countriesJson.hasOwnProperty(countryIdx)) {
          this.countries.push(new Country(countriesJson[countryIdx]));
        }
      }
    }

    return this.countries;
  };

  getCountryByCode(code) {
    let countries = this.getCountries();
    let result = countries.filter(
      (country) => {
        return code === country.getCode();
      }
    )

    return result.length ? result.shift() : null;
  }
}

export default CountryRepository;
