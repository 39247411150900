import React from "react";
import LabelLookup from "../../../services/LabelLookup";
import {SuggestedCompanyStatuses} from "../../../models/Statuses/SuggestedCompanyStatuses";
import {Link} from "react-router-dom";
import UrlHelperService from "../../../services/UrlHelperService";
import DashboardCompanyFilter from "../../Contexts/DashboardCompanyFilter";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    // We need the last 5 suggested companies. Load all pending ones, sort by id desc, take the first 5.
    this.suggestedCompanies = this.props.suggestedCompanyRepository.getSuggestedCompaniesByStatus(SuggestedCompanyStatuses.pending).sort(
      (suggestedCompany1, suggestedCompany2) => {
        return suggestedCompany1.getId() > suggestedCompany2.getId() ? -1 : 1;
      }
    );
    this.suggestedCompanies = this.suggestedCompanies.slice(0, 5);

    this.state = {
      dashboardCompanyFilter: new DashboardCompanyFilter(JSON.parse(localStorage.getItem('dashboardCompanyFilter')))
    };
    // Load all the companies. The user might change the filtering, but we keep this "master" list and pick out the
    // ones we want. The list is sorted latest company first.
    this.companies = this.props.companyRepository.getCompanies().sort(
      (company1, company2) => {
        return company1.getId() > company2.getId() ? -1 : 1;
      }
    );
    // Load the last 20 subscriber emails.
    this.state.last20Subscribers = this.props.subscriberRepository.getLatestSubscribers(20);

    this.categoriesById = new LabelLookup(
      this.props.categoryRepository.getCategories(),
      (category) => {return category.getId()},
      (category) => {return category.getName()}
    )
  }

  changeCompanyFilterOption = event => {
    this.state.dashboardCompanyFilter.setFilter(event.target.value);
    this.setState(
      {
        dashboardCompanyFilter: this.state.dashboardCompanyFilter
      },
      () => {
        // Save the new state of the filter into local storage.
        localStorage.setItem('dashboardCompanyFilter', JSON.stringify(this.state.dashboardCompanyFilter))
      }
    )
  }

  render() {
    let suggestedCompaniesList = [];
    this.suggestedCompanies.forEach(
      (suggestedCompany) => {
        let categoryNames = suggestedCompany.hasCategoryIds() ? this.categoriesById.idsToLabels(suggestedCompany.getCategoryIds()) : null;
        suggestedCompaniesList.push(
          <div className="row record" key={suggestedCompany.getId()}>
            <div className="col-md-2">{suggestedCompany.getName()}</div>
            <div className="col-md-4">{categoryNames}</div>
            <div className="col-md-4">{suggestedCompany.getDescription()}</div>
            <div className={'col-md-1 status ' + suggestedCompany.getStatus().toLowerCase()}>{suggestedCompany.getStatus()}</div>
            <div className="col-md-1"><Link to={`/admin/suggestedCompanies/review/${suggestedCompany.getId()}`}>Review</Link></div>
          </div>
        );
      }
    )

    // Filter the companies according to the context.
    let filteredCompanies;
    if (this.state.dashboardCompanyFilter.isLimitedByCount()) {
      filteredCompanies = this.companies.slice(0, this.state.dashboardCompanyFilter.getLimitCount());
    } else {
      let cutoff = this.state.dashboardCompanyFilter.getDateCutoff();
      filteredCompanies = this.companies.filter(
        (company) => {
          return company.getCreatedAt() >= cutoff;
        }
      )
    }

    let companiesList;
    if (filteredCompanies.length) {
      companiesList = [];
      let urlHelperService = new UrlHelperService();
      filteredCompanies.forEach(
        (company) => {
          let categoryNames = company.hasCategoryIds() ? this.categoriesById.idsToLabels(company.getCategoryIds()) : null;
          let linkUrl = company.hasLinkUrl() ? urlHelperService.makeFullUrl(company.getLinkUrl()) : null;
          companiesList.push(
            <div className="row record" key={company.getId()}>
              <div className="col-md-2">{company.getName()}</div>
              <div className="col-md-4">{categoryNames}</div>
              <div className="col-md-3">{company.getDescription()}</div>
              <div className="col-md-2">{linkUrl ?
                <a href={linkUrl} target="_blank" rel="noopener noreferrer">{company.getLinkUrl()}</a> : null}</div>
              <div className="col-md-1"><Link to={`/admin/companies/edit/${company.getId()}`}>Edit</Link></div>
            </div>
          );
        }
      )
    } else {
      companiesList = <div className="row record"><p>No companies match your criteria</p></div>;
    }

    let filterOptions = [];
    let options = this.state.dashboardCompanyFilter.getOptions();
    for(let optionIdx in options) {
      if (options.hasOwnProperty(optionIdx)) {
        let selected = this.state.dashboardCompanyFilter.isCurrentFilter(optionIdx);
        filterOptions.push(
          <label key={optionIdx}>
            <input type="radio" name="companyLatest" value={optionIdx} checked={selected} onChange={this.changeCompanyFilterOption}/>{options[optionIdx]}
          </label>);
      }
    }
    filterOptions = <p className="company-filter">Show: {filterOptions}</p>;

    // Build the output for the latest subscribers.
    let subscribersList = [];
    this.state.last20Subscribers.forEach(
      (subscriber) => {
        subscribersList.push(
          <div className="row" key={subscriber.getId()}>
            <div className="col-md-2">{subscriber.getEmail()}</div>
            <div className="col-md-4">{subscriber.getCreatedAtAsString()}</div>
            <div className="col-md-2">{subscriber.getNewsletterCount() > 0 ? 'Yes ('+subscriber.getNewsletterCount()+')' : 'No'}</div>
            <div className="col-md-2">{subscriber.getPartnershipCount() > 0 ? 'Yes ('+subscriber.getPartnershipCount()+')' : 'No'}</div>
          </div>
        );
      }
    );

    return (
      <div className="dashboard">
        <div className="suggested-companies-list">
          <h2>Last 5 suggested companies</h2>
          <div className="row">
            <div className="col-md-2">Name</div>
            <div className="col-md-4">Categories</div>
            <div className="col-md-4">Description</div>
            <div className="col-md-1">Status</div>
            <div className="col-md-1">Action</div>
          </div>
          {suggestedCompaniesList}
        </div>
        <div className="companies-list">
          <h2>Recent companies added</h2>
          {filterOptions}
          <div className="row">
            <div className="col-md-2">Name</div>
            <div className="col-md-4">Categories</div>
            <div className="col-md-3">Description</div>
            <div className="col-md-2">Link URL</div>
            <div className="col-md-1">Action</div>
          </div>
          {companiesList}
        </div>
        <div className="subscribers-list">
          <h2>20 Most Recent Subscribers</h2>
          <div className="row">
            <div className="col-md-2">Address</div>
            <div className="col-md-4">When</div>
            <div className="col-md-2">Newsletter</div>
            <div className="col-md-2">Partnership</div>
          </div>
          {subscribersList}
        </div>
      </div>
    )
  }
}

export default Dashboard;
