import AbstractField from "./AbstractField";
import React from "react";
import MandatoryFieldIndicator from "./MandatoryFieldIndicator";
import OneLozenge from "./OneLozenge";

/**
 * This is an abstract field widget for allowing multi-select from a set of records in a separate table.
 * Subclass it for each record type you want to use - categories, tags, etc.
 */
class AbstractFieldObjectSelector extends AbstractField {
  constructor(props) {
    super(props);

    this.allowAddModel = !!this.props.allowCreateModel;

    this.allModels = [];

    this.state = {
      selectedModels: [],
      enteredValue: null,
      matchingModels: [],
      isExactMatch: true
    };
  }

  componentDidMount() {
    // Load all available models.
    this.refreshAllModels();

    // If the record already had some selected, load them now.
    if (this.props.currentlySelectedIds) {
      let selectedModels = [];
      // The prop is an array of object Ids. We need to map each id to the appropriate object model.
      for (let selIdx = 0; selIdx < this.props.currentlySelectedIds.length; selIdx++) {
        let objectModel = this.getObjectById(this.props.currentlySelectedIds[selIdx]);
        // Make sure the object instance exists (in case the object in the parent component has a reference a
        // non-existent object).
        if (objectModel) {
          selectedModels.push(objectModel);
        }
      }
      this.setState(
        {selectedModels: selectedModels},
        () => {
          this.refreshMatches('');
        }
      );
    }

  }

  /**
   * Abstract method to return the label for the field. Each subclass must provide a label.
   * @return {string}
   */
  getLabel() {
    return 'Label TBC';
  }

  refreshAllModels() {
    this.allModels = this.loadAllModels();
    let self = this;
    this.allModels.sort(
      function (model1, model2) {
        return self.getModelName(model1).toLowerCase() > self.getModelName(model2).toLowerCase();
      }
    );
  }

  /**
   * Abstract method to return all the available models for the selector. Each subclass must overrider this.
   *
   */
  loadAllModels() {
    return [];
  }

  /**
   * Abstract method to return the model for the given id. Each subclass should provide this method.
   * @param id
   * @return {Object|null}
   */
  getObjectById(id) {
    return null;
  }

  /**
   * Abstract method to return the id of the given model. Each subclass should provide this method.
   * @param model
   * @return {null}
   */
  getModelId(model) {
    return null;
  }

  /**
   * Abstract method to return the name of the given model. Each subclass should provide this method.
   * @param model
   * @return {null}
   */
  getModelName(model) {
    return null;
  }

  /**
   * This is a change in the text field, not a change in the set of models associated with the parent record.
   * @param event
   */
  onChange = event => {
    let newValue = event.target.value;
    this.refreshMatches(newValue);
  }

  /**
   * Repopulate the set of suggested models, etc. This is called after something changes, such as a change to the text
   * field or the user selecting/unselecting a model.
   * @param newValue
   * @param selectedModels
   */
  refreshMatches(newValue, selectedModels) {
    // We need to look this value up in the set of all existing models, then ignore any that are already selected.
    // We then show all those models as potential models to select.
    // If there isn't an exact match against all existing models, allow the user to add this as a new model (and select
    // it).
    // A special case is if the search box is empty. In this case, show all available models.

    // Make an array of the ids of the already selected models.
    let selectedModelIds = [];
    for (let selIdx = 0; selIdx < this.state.selectedModels.length; selIdx++) {
      selectedModelIds.push(this.getModelId(this.state.selectedModels[selIdx]));
    }

    // Now decide which ones are available/
    if (newValue === null) {
      newValue = '';
    }
    let matchingModels = [];
    let isExactMatch = false;
    let newValueLower = newValue.toLowerCase();
    let self = this;
    matchingModels = this.allModels.filter(
      function (oneModel) {
        let modelNameLower = self.getModelName(oneModel).toLowerCase();

        // If the name of this model matches exactly with the search term, then do not show a "create" option.
        isExactMatch = isExactMatch || modelNameLower === newValueLower;

        // If the search box is empty, we so show every available model.
        // If this model is already selected, don't show it in the "available" list.
        let isSelected = selectedModelIds.indexOf(self.getModelId(oneModel)) >= 0;
        if (newValue === '' || isSelected) {
          return !isSelected;
        }

        // Show this model in the "available" list if it matches the search term.
        return modelNameLower.indexOf(newValueLower) >= 0;
      }
    )
    isExactMatch = isExactMatch || newValue === '';

    // Build the new state.
    let newState = {enteredValue: newValue, matchingModels: matchingModels, isExactMatch: isExactMatch};
    if (selectedModels !== undefined) {
      newState.selectedModels = selectedModels;
    }
    this.setState(newState);
  }

  /**
   * Abstract method that returns the name of the object. Must be overridden by subclasses.
   * @return {string}
   */
  getModelDescription() {
    return 'Model Name TBC';
  }

  /**
   * This method actually facilitates the creation of a new instance of the model. A model name is passed in.
   * Each subclass must completely define what happens here. For a start, only they know the record type, the field
   * names, default values, validation, etc, etc for the model.
   * @param name
   */
  createModel = name => {
    window.alert('Creating an instance of a '+this.getModelDescription()+' have not yet been implemented');
  }

  /**
   * This method allows a subclass to override the contents of this selector (temporarily or permanently).
   * @return {null}
   */
  getContentOverride() {
    return null;
  }

  selectModel = modelId => {
    // Add the selected model to the set of currently selected models.
    // Then sort them into alphabetical order. It is a bit naughty modifying the value of a "state" property, but it
    // does call setState() later with the new value.
    this.state.selectedModels.push(this.getObjectById(modelId));
    let self = this;
    this.state.selectedModels.sort(
      function (model1, model2) {
        return self.getModelName(model1).toLowerCase() > self.getModelName(model2).toLowerCase();
      }
    );

    this.postSelection();
  }

  unselectModel = modelId => {
    // Remove the given model from the current selection, tell the calling code and refresh everything.
    for (let modelIdx = this.state.selectedModels.length-1; modelIdx >= 0 ; modelIdx--) {
      if (this.state.selectedModels[modelIdx].getId() === modelId) {
        this.state.selectedModels.splice(modelIdx, 1);
      }
    }

    this.postSelection();
  }

  postSelection() {
    // We need the selected models in 2 forms: an new copy of the array for the new state, and an array of just the ids,
    // to pass to the calling code.
    let selectedModels = [];
    let selectedIds = [];
    this.state.selectedModels.forEach(
      function(model) {
        selectedModels.push(model);
        selectedIds.push(model.getId());
      }
    )
    this.props.onchange(selectedIds);

    // Refresh the matching models.
    this.refreshMatches(this.state.enteredValue, selectedModels);
  }

  render() {
    let suggestionCreate = <div />;
    if (!this.state.isExactMatch && this.allowAddModel) {
      let hintCreate = 'Create this '+this.getModelDescription()+' and add to the company'
      suggestionCreate = (
          <div>
            Create:
            <OneLozenge value={this.state.enteredValue} clickHandler={() => this.createModel(this.state.enteredValue)} icon="plus" hint={hintCreate}/>
          </div>
      );
    }
    suggestionCreate = (
      <div key="0" className="suggestion-create-container">
        {suggestionCreate}
      </div>
    );

    let suggestions = [];
    let hintAdd = 'Add this '+this.getModelDescription()+' to the company';
    for (let matchIdx = 0; matchIdx < this.state.matchingModels.length; matchIdx++) {
      let matching = this.state.matchingModels[matchIdx];
      suggestions.push(
        <div key={matching.getId()}>
          <OneLozenge clickHandler={() => this.selectModel(matching.getId())} value={matching.getName()} icon="arrow-left" hint={hintAdd}/>
        </div>
      );
    }

    let lozenges = []; // This is when the options are view-only.
    let currentSelection = [];
    for (let currIdx = 0; currIdx < this.state.selectedModels.length; currIdx++) {
      let model = this.state.selectedModels[currIdx];
      let modelName = this.getModelName(model);
      let hintRemove = 'Remove this '+this.getModelDescription()+' from the company'; // @TODO: It might not be a company in the future.
      currentSelection.push(
        <div key={model.getId()}>
          <OneLozenge clickHandler={() => this.unselectModel(model.getId())} value={modelName} icon="remove" hint={hintRemove}/>
        </div>
      );
      lozenges.push(
        <OneLozenge key={model.getId()} value={modelName}/>
      );
    }

    // The HTML elements for this widget vary depending on whether it's read only, etc.
    let searchAndCurrent = [];
    if (this.isViewScreen) {
      searchAndCurrent.push(lozenges);
    } else {
      let initialValue = this.state.enteredValue || '';
      searchAndCurrent.push(<input key="search" className="search" type="text" placeholder={'Start typing your '+this.getModelDescription()+' name(s)'} value={initialValue} onChange={this.onChange}/>);
      searchAndCurrent.push(
        <div key="current-selection" className="current-selection-container">
          <h3>Selected</h3>
          {
            currentSelection.length
            ?
            <div className="current-selection">
              {currentSelection}
            </div>
            :
            <p>[none]</p>
          }
        </div>
      );
    }

    let available = [];
    if (suggestionCreate) {
      available.push(suggestionCreate);
    }
    available.push(
      <div key="matching" className="matching">
        <h3>Available</h3>
        {
          suggestions.length
          ?
          <div className="suggestions">
            {suggestions}
          </div>
          :
          <p>[none]</p>
        }
      </div>
    );

    let content = this.getContentOverride();
    if (content === null) {
      content = [];
      content.push(
        <div key="searchAndCurrent" className={this.isViewScreen ? '' : 'col-md-4'}>
          {searchAndCurrent}
        </div>
      );
      content.push(
        <div key="available" className="col-md-4">
          {!this.isViewScreen && available}
        </div>
      );
    }

    return (
      <div className="field-object-selector">
        <div className="row">
          <div className="col-md-2">
            {this.getLabel()}{this.props.required && <MandatoryFieldIndicator/>}
          </div>
          {content}
        </div>
      </div>
    );
  }
}

export default AbstractFieldObjectSelector;
