import React from 'react';
import PressCoverage from "./PressCoverage";
import TeamMember from "./TeamMember";
import TeamMemberGroup from "./TeamMemberGroup";

class AboutUs extends React.Component {


  render() {
    // Build the profile pictures, etc. Originally, Aashni was on the line above with the other 4 below, but then it
    // was changed so they were all on the same line. That's why there is only 1 group.
    let teamMemberGroups = [];

    let group1 = [
      <TeamMember imageFilename="Image_Aashni.png" name="Aashni Shah" profileUrl="https://linkedin.com/in/aashnisshah"/>,
      <TeamMember imageFilename="Image_Swati.png" name="Swati Priyam" profileUrl="https://www.linkedin.com/in/swatipriyam/"/>,
      <TeamMember imageFilename="Image_Carolyne.png" name="Carolyne Mweberi" profileUrl="https://www.linkedin.com/in/carolyne-mweberi-6647aa33/"/>,
      <TeamMember imageFilename="Image_Manisha.png" name="Manisha Goenka" profileUrl="https://www.linkedin.com/in/manishagoenka/"/>,
      <TeamMember imageFilename="Image_Glenn.png" name="Glenn Jenkins" profileUrl="https://gitlab.com/pedanticantic"/>,
      <TeamMember imageFilename="Image_Prerna.png" name="Prerna Anand" profileUrl="https://www.prernaanand.dev/"/>,
    ];
    teamMemberGroups.push(<TeamMemberGroup key="group1" members={group1}/>);

    return (
      <div className="about-us">
        <img src="images/about-us/about_us_background.png" className="background" alt="About us background"/>
        <div className="row">
          <h2 className="col">About Us</h2>
        </div>
        <div className="row">
          <div className="col" id="firstRow">
            <div className="rectangle1">
              <div className="vision">Vision</div>
              <div className="text">
                  Our vision is to be the largest catalogue of companies from all industries making ethically sourced products
                  providing every consumer the right data to make the correct purchase.
              </div>
            </div>
          </div>

          <div className="col">
            <div className="rectangle1">
              <div className="mission">Mission</div>
              <div className="text">
                Our mission is to empower every consumer during their purchasing process to only shop ethically sourced
                products.
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="rectangle2">
              <div className="purpose">Why are we doing this?</div>
              <div className="purpose_text">
                It all began sometime back when Aashni, our CEO, and few members were looking for solutions to easily find
                ethical, fair trade products online. We found that it was cumbersome and time consuming to find items in
                different categories. So we decided to build a website to provide easy access to anyone who is looking for
                ethically made products. We know it can be frustrating to be wanting to purchase and support fair trade goods
                but not being able to find relevant information.
              </div>
            </div>
          </div>

          <div className="col about-image">
            <img id="aboutImg" src={'images/about-us/About_us_illustration.png'} alt="Group of people"/>
          </div>
        </div>

        <h3>Our Team</h3>
        {teamMemberGroups}

        <PressCoverage />
      </div>
    )
  }
}

export default AboutUs;
