import React from "react";

class ListSearchBox extends React.Component {
  render() {
    let initialValue = this.props.initialValue || '';

    return <div className="list-search-box"><input type="text" placeholder="Search by name..." onChange={this.props.onchange} value={initialValue}/></div>
  }
}

export default ListSearchBox;
