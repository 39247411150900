/**
 * This class holds the context for the suggested companies list - filters and sort options. Possibly pagination info in the future.
 */
import {SuggestedCompanyStatuses} from "../../models/Statuses/SuggestedCompanyStatuses";

class SuggestedCompanyListContext {
  constructor(savedState) {
    // The status filter has a property for each status where the value is a boolean that says whether the user is
    // filtering on that status. By default, only "submitted" is switched on.
    // Initialise the values. If there's anything in the saved state, overwrite the values with that, otherwise revert
    // to the default.
    // @TODO: We want to hide the "submitted" option behind a "more..." option. We'll need to flag that says whether
    // @TODO: "more..." should be shown or the "Submitted" option.
    this.statusFilter = {};
    this.statusFilter[SuggestedCompanyStatuses.pending] = false;
    this.statusFilter[SuggestedCompanyStatuses.approved] = false;
    this.statusFilter[SuggestedCompanyStatuses.rejected] = false;
    // this.statusFilter[SuggestedCompanyStatuses.submitted] = false; // @TODO: For now, don't include "Submitted".
    if (savedState && savedState.hasOwnProperty('statusFilter')) {
      let savedStatusFilter = savedState.statusFilter;
      let allowedProperties = [SuggestedCompanyStatuses.pending, SuggestedCompanyStatuses.approved, SuggestedCompanyStatuses.rejected/*, SuggestedCompanyStatuses.submitted*/];
      allowedProperties.forEach(
        (prop) => {
          if (savedStatusFilter.hasOwnProperty(prop)) {
            this.statusFilter[prop] = !!savedStatusFilter[prop];
          }
        }
      )
    } else {
      this.statusFilter[SuggestedCompanyStatuses.pending] = true;
    }

    // Set the the sort order (column and direction). We have an allowed list of columns we can sort on, initially
    // just the creation date. If there is a saved state, populate the list with it, otherwise set it to the default
    // value.
    this.allowedSortColumns = ['createdAt']; // We might need to expose it to things that use this object.
    this.sortColumns = [];
    if (savedState && savedState.hasOwnProperty('sortColumns') && savedState.sortColumns.length) {
      // We need to be careful to go through the saved sort columns in the order they're in.
      savedState.sortColumns.forEach(
        (savedSortColumn) => {
          if (savedSortColumn.hasOwnProperty('column') && savedSortColumn.hasOwnProperty('direction')) {
            if ((savedSortColumn.direction === 'asc' || savedSortColumn.direction === 'desc') &&
                (this.allowedSortColumns.indexOf(savedSortColumn.column) !== -1)) {
              this.sortColumns.push({column: savedSortColumn.column, direction: savedSortColumn.direction});
            }
          }
        }
      );
    } else {
        this.sortColumns = [{column: this.allowedSortColumns[0], direction: 'desc'}];
    }
  }

  retrieveValue(prop, subProp, state, defaultValue) {
    if (state.hasOwnProperty(prop) && state[prop].hasOwnProperty(subProp)) {
      return !!state[prop][subProp];
    } else {
      return defaultValue;
    }
  }

  getStatusFilter() {
    return this.statusFilter;
  }

  statusMatches(status) {
    return this.statusFilter[status];
  }

  toggleStatus(status) {
    this.statusFilter[status] = !this.statusFilter[status];
  }

  hasSortColumns() {
    return this.sortColumns.length > 0;
  }

  getSortColumns() {
    return this.sortColumns;
  }
}

export default SuggestedCompanyListContext;
