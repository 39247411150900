import React from "react";
import {Link} from "react-router-dom";

class OneCompanyForCategory extends React.Component {
  constructor(props) {
    super(props);

    this.company = this.props.company;
  }

  render() {
    let imgSrc = this.company.getImage().hasUrl() ? this.company.getImage().getUrl() : '/images/noimage.png';

    return (
      <div className="col-md-4">
        <div className="one-company">
          <div className="image-container">
            <img src={`${imgSrc}`} alt={this.company.getName()}/>
          </div>
          <div className="company-name">{this.company.getName()}</div>
          <div className="company-description">{this.company.getDescription()}</div>
          <div className="more btn-outline">
            <Link to={`/company/${this.company.getId()}`}>More</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default OneCompanyForCategory;
