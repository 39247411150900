import CompanyFieldAttributes from "./CompanyFieldAttributes";

class SuggestedCompanyFieldAttributes extends CompanyFieldAttributes {
  constructor() {
    super();

    // Override any fields that are in the company, where we have a different setting.
    this.fields['description'].required = false;
    this.fields['image'].required = false;

    // Add fields that are suggested company specific.
    this.fields['reason'] = {required: true};
    this.fields['suggesterGivenName'] = {required: true};
    this.fields['suggesterFamilyName'] = {required: true};
    this.fields['suggesterPhone'] = {required: false};
    this.fields['suggesterEmail'] = {required: true};
    this.fields['additionalDetails'] = {required: false};
    this.fields['internalNotes'] = {required: false};
    this.fields['adminNotes'] = {required: false};
  }
}

export default SuggestedCompanyFieldAttributes;
