import React from "react";

class CompanyListHeadings extends React.Component {
  render() {
    return (
      <div className="row headings">
        <div className="col-md-1">Image</div>
        <div className="col-md-2">Company Title</div>
        <div className="col-md-1">Categories</div>
        <div className="col-md-1">Tags</div>
        <div className="col-md-3">Description</div>
        <div className="col-md-3">Link URL</div>
        <div className="col-md-1">Action</div>
      </div>
    )
  }
}

export default CompanyListHeadings;
