import React from "react";
import OneCompanyForCategory from "./OneCompanyForCategory";
import ShowMoreButton from "../Common/ShowMoreButton";

class CompaniesForCategory extends React.Component {
  constructor(props) {
    super(props);

    this.category = props.category;

    // We use the object that handles keeping track of how many records to display. We don't show the options to
    // change the page size, so we only use a small part of its functionality. Eg we just have one allowed page size.
    // We use a page size of 3 because we display 3 records per row - clicking "More" adds 1 complete row each time.
    // Because sometimes pages higher in the hierarchy need to reset this number, it's actually passed in to this
    // component as "numberOfCompaniesDisplayedForCategory".
    this.state = {
      availableCompanies: props.companies,
      displayedRecordsHandler: props.numberOfCompaniesDisplayedForCategory
    };
  }

  handleMoreClick = () => {
    this.state.displayedRecordsHandler.incrementNumDisplayedRecords();
    this.setState({displayedRecordsHandler: this.state.displayedRecordsHandler.getCopy()});
  }

  render() {
    let companies = [];
    let displayedCompanies = this.state.availableCompanies;
    let numBeforePageRestriction = displayedCompanies.length;
    displayedCompanies = displayedCompanies.slice(0, this.state.displayedRecordsHandler.getNumDisplayedRecords());
    displayedCompanies.forEach(
      (company) => {
        companies.push(<OneCompanyForCategory key={company.getId()} company={company}/>);
      }
    );

    if (companies.length === 0) {
      return null;
    }

    return (
      <div className="companies-for-category">
        <h2>All {this.category.getName()} Companies</h2>
        <div className="row">
          {companies}
        </div>
        {numBeforePageRestriction > displayedCompanies.length ? <ShowMoreButton clickHandler={this.handleMoreClick}/> : null}
      </div>
    );
  }
}

export default CompaniesForCategory;
