import MailerService from "./MailerService";
import UrlHelperService from "./UrlHelperService";

/**
 * This is a wrapper around the email service. There are various methods that receive a company suggestion
 * model and build an email appropriate for the method. They then call the actual mail service sender.
 */
class CommunicationsHelperService {
  constructor() {
    this.mailerService = new MailerService();
    this.location = window.location; // @TODO: Might need to make this more sophisticated or find a 3rd party repo or whatever.
    this.urlHelperService = new UrlHelperService();
  }

  /**
   * We need to work out what to send to the suggester (email and/or SMS). It will depend on what info the suggester
   * has supplied and what event has just happened.
   * @TODO: For now, we will only send emails (suggester email address is mandatory at time of writing).
   *
   * @param {SuggestedCompany} SuggestedCompany
   * @param {string} context
   */
  determineWhatToSend(SuggestedCompany, context) {
    let result = {
      'email': SuggestedCompany.getSuggesterEmailAddress() !== null,
      'sms': false // @TODO: SuggestedCompany.getSuggesterTelephoneNumber() !== null
    };
    // @TODO: Test context here as necessary.

    return result;
  };

  /**
   * @param {SuggestedCompany} SuggestedCompany
   * @param {string} pageUrl
   * @param {string} fallbackDescription
   * @return {string}
   */
  buildLink(SuggestedCompany, pageUrl, fallbackDescription) {
    let url = this.location.protocol+'//'+this.location.hostname+':'+this.location.port+pageUrl;
    let companyName = SuggestedCompany.getName();
    companyName = companyName ? companyName : SuggestedCompany.getDescription();
    companyName = companyName ? companyName : fallbackDescription;

    return '<a href="'+url+'">'+companyName+'</a>'; // @TODO: see if I can fix the warnings here.
  };

  /**
   * @param {SuggestedCompany} SuggestedCompany
   * @return {string}
   */
  buildViewLink(SuggestedCompany) {
    return this.buildLink(
      SuggestedCompany,
      this.urlHelperService.getPathForSuggestedProduct(SuggestedCompany),
      'Your company suggestion'
    );
  };

  /**
   * @param {SuggestedCompany} SuggestedCompany
   * @return {string}
   */
  buildAdminLink(SuggestedCompany) {
    return this.buildLink(
      SuggestedCompany,
      this.urlHelperService.getAdminPathForSuggestedProduct(SuggestedCompany),
      'The company suggestion'
    );
  };

  /**
   * @param {SuggestedCompany} SuggestedCompany
   * @return {string|null}
   */
  buildToAddress(SuggestedCompany) {
    // We want something of the form "email@address.com (Firstname Lastname)".
    // If there is no email address, return null.
    if (SuggestedCompany.getSuggesterEmailAddress()) {
      let name = SuggestedCompany.getSuggesterFullName();
      if (name) {
        name = ' ('+name+')';
      }

      return SuggestedCompany.getSuggesterEmailAddress()+name;
    } else {
      return null; // The email sender service will pick this up and simply not send the email.
    }
  };

  /**
   * @param {SuggestedCompany} SuggestedCompany
   * @return {string|null}
   */
  buildFromAddress(SuggestedCompany) {
    // What we want is actually exactly the same as the "to" address, so just call that method
    // and return the result.
    return this.buildToAddress(SuggestedCompany);
  };

  /**
   * @param {SuggestedCompany} SuggestedCompany
   * @param {Function|null} callback
   */
  afterCreation(SuggestedCompany, callback) {
    let whatToSend = this.determineWhatToSend(SuggestedCompany, 'afterCreation');
    if (whatToSend.email) {
      this.mailerService.sendToSuggester(
        this.buildToAddress(SuggestedCompany),
        'Thank you for suggesting a company',
        SuggestedCompany.getSuggesterGivenName(),
        'You have suggested a company for Source Right. Please click the link above and check the details are correct. '+
        'You will be asked for a 6 digit verification code - please enter: '+SuggestedCompany.extractVerificationCode(),
        this.buildViewLink(SuggestedCompany),
        callback
      );
    }
  };

  /**
   * @param {SuggestedCompany} SuggestedCompany
   */
  afterCodeVerification(SuggestedCompany) {
    let whatToSend = this.determineWhatToSend(SuggestedCompany, 'afterCodeVerification');
    if (whatToSend.email) {
      this.mailerService.sendToSuggester(
        this.buildToAddress(SuggestedCompany),
        'Thank you for verifying your suggestion',
        SuggestedCompany.getSuggesterGivenName(),
        'Thank you for verifying your suggestion. Our experts will now review it. We will keep you informed of progress.',
        this.buildViewLink(SuggestedCompany)
      );
    }
    // Also inform the admins that there is a new suggested company ready to review.
    this.sendMessageToAdmins(
      SuggestedCompany,
      'New Suggested Company',
      'The suggester has verified their suggestion for the above company'
    );
  }

  /**
   * @param {SuggestedCompany} SuggestedCompany
   * @param {Function|null} callback
   */
  afterEdit(SuggestedCompany, callback) {
    let whatToSend = this.determineWhatToSend(SuggestedCompany, 'afterEdit');
    if (whatToSend.email) {
      this.mailerService.sendToSuggester(
        this.buildToAddress(SuggestedCompany),
        'Your company suggestion has been edited',
        SuggestedCompany.getSuggesterGivenName(),
        'Please see your updated company suggestion (link above).',
        this.buildViewLink(SuggestedCompany),
        callback
      );
    }
  };

  /**
   * @param {SuggestedCompany} SuggestedCompany
   * @param {Function|null} callback
   */
  afterAddNotes(SuggestedCompany, callback) {
    let whatToSend = this.determineWhatToSend(SuggestedCompany, 'afterAddNotes');
    if (whatToSend.email) {
      this.mailerService.sendToSuggester(
        this.buildToAddress(SuggestedCompany),
        'We have added/amended the notes on your company suggestion',
        SuggestedCompany.getSuggesterGivenName(),
        'The new notes are: <pre>' + SuggestedCompany.getAdminNotes() + '</pre>',
        this.buildViewLink(SuggestedCompany),
        callback
      );
    }
  }

  /**
   * @param {SuggestedCompany} SuggestedCompany
   * @param {Function|null} callback
   */
  afterRejection(SuggestedCompany, callback) {
    let whatToSend = this.determineWhatToSend(SuggestedCompany, 'afterRejection');
    if (whatToSend.email) {
      this.mailerService.sendToSuggester(
        this.buildToAddress(SuggestedCompany),
        'Your company suggestion has been rejected',
        SuggestedCompany.getSuggesterGivenName(),
        'We are sorry but we have rejected your company suggestion.',
        this.buildViewLink(SuggestedCompany),
        callback
      );
    }
  }

  /**
   * @param {SuggestedCompany} SuggestedCompany
   * @param {Function|null} callback
   */
  afterUnrejection(SuggestedCompany, callback) {
    let whatToSend = this.determineWhatToSend(SuggestedCompany, 'afterUnrejection');
    if (whatToSend.email) {
      this.mailerService.sendToSuggester(
        this.buildToAddress(SuggestedCompany),
        'Your company suggestion has been un-rejected',
        SuggestedCompany.getSuggesterGivenName(),
        'We are pleased to tell you that we have reversed the rejection of your company suggestion.',
        this.buildViewLink(SuggestedCompany),
        callback
      );
    }
  }

  /**
   * @param {SuggestedCompany} SuggestedCompany
   * @param {Function|null} callback
   */
  afterApproval(SuggestedCompany, callback) {
    let whatToSend = this.determineWhatToSend(SuggestedCompany, 'afterApproval');
    if (whatToSend.email) {
      this.mailerService.sendToSuggester(
        this.buildToAddress(SuggestedCompany),
        'Your company suggestion has been approved',
        SuggestedCompany.getSuggesterGivenName(),
        'We are pleased to tell you that we have approved your company suggestion.',
        this.buildViewLink(SuggestedCompany),
        callback
      );
    }
  }

  sendMessageToAdmins(suggestedProduct, subject, body, callback) {
    this.mailerService.sendToAdmin(
      this.buildFromAddress(suggestedProduct),
      suggestedProduct.getSuggesterFullName(),
      subject,
      '<pre>'+body+'</pre>',
      this.buildAdminLink(suggestedProduct),
      callback
    );
  }
}

export default CommunicationsHelperService;
