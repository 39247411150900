import React from "react";
import Category from "../../../models/Category";
import AbstractCategoryForm from "./AbstractCategoryForm";

class AbstractEmbeddedCategoryForm extends AbstractCategoryForm {
  constructor(props) {
    super(props);

    this.state.categoryCopy.name = this.props.initialName;
  }

  getInitialCategory() {
    // At the moment, the embedded category forms only _add_ new categories.
    return new Category({});
  }

  showImageChooserImage() {
    return false;
  }

  getActionButtonSection() {
    return (
      <div className="row">
        <div className="col-md-2"/>
        <div className="col-md-4">
          <input type="button" value="Save" className="action-button btn-outline" onClick={this.saveCategory}/>
          <button className="action-button btn-outline" onClick={this.props.handleRemoveForm}>Cancel</button>
        </div>
      </div>
    );
  }

  afterCategorySave(recordId) {
    // Tell the embedding form that we've just added this category.
    this.props.addnewCategory(recordId);

    // We must close the form (the user will already have been told the category was saved, and this method is only
    // called after successfully saving the category, so we don't need to worry about errors or checking anything or
    // anything).
    this.props.handleRemoveForm();
  }

  render() {
    return (
      <div className="embedded-form">
        <h3>Add a new category</h3>
        {this.maintenanceForm()}
      </div>
    );
  }
}

export default AbstractEmbeddedCategoryForm;
