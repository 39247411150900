/**
 * This class represents an image in a model. The image can take the form of a URL, which is what is stored in the
 * data store, or an instance of a file, which is what the image chooser in the add/edit screens produce.
 * When saving a model, the repository asks this class if it is an image that needs uploading (ie is it a file), and if
 * so, uploads it to permanent storage and replaces this image instance with an instance created with the URL.
 */
class ModelImage {
  constructor(imageInfo) {
    // If the imageInfo is null or not recognised, both these properties will remain null.
    this.url = null;
    this.file = null;
    if (typeof imageInfo === 'string' || imageInfo instanceof String) {
      this.url = imageInfo;
    } else {
      if (imageInfo instanceof File) {
        this.file = imageInfo;
      }
    }
  }

  hasUrl() {
    return this.url !== null;
  }

  getUrl() {
    return this.url;
  }

  getFile() {
    return this.file;
  }

  requiresUpload() {
    return this.file !== null;
  }

  isEmpty() {
    return this.url === null && this.file === null;
  }
}

export default ModelImage;
