import firebase from 'firebase';

const config = {
  "apiKey": process.env.REACT_APP__FIREBASE__API_KEY,
  "authDomain": process.env.REACT_APP__FIREBASE__AUTH_DOMAIN,
  "databaseURL": process.env.REACT_APP__FIREBASE__DATABASE_URL,
  "messagingSenderId": process.env.REACT_APP__FIREBASE__MESSAGING_SENDER_ID,
  "projectId": process.env.REACT_APP__FIREBASE__PROJECT_ID,
  "storageBucket": process.env.REACT_APP__FIREBASE__STORAGE_BUCKET
};
firebase.initializeApp(config);

export const firebaseDatabase = firebase.database();
export const firebaseAuth = firebase.auth();
export const firebaseStorage = firebase.storage;
export const firebaseStorageRef = firebase.storage().ref();
