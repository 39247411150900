import React from 'react';
import MandatoryFieldIndicator from "./MandatoryFieldIndicator";
import AbstractField from "./AbstractField";

class FieldTextArea extends AbstractField {
  render() {
    let className = this.props.textClass || '';
    let inputValue = this.props.value || '';
    let placeholder = this.props.placeholder || '';
    return (
      <div className="row">
          <div className="col-md-2">
            {this.props.label}{this.props.required && <MandatoryFieldIndicator/>}
          </div>
          <div className="col-md-10">
            {this.isViewScreen ?
              <div><pre>{inputValue}</pre></div>
              :
              <textarea onChange={this.props.onchange} className={className} value={inputValue} placeholder={placeholder}/>
            }
          </div>
      </div>
    )
  }
}

export default FieldTextArea;
