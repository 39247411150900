import {firebaseDatabase} from "./firebase";
import CategoryRepository from "./CategoryRepository";
import StorageRepository from "./StorageRepository";
import CompanyRepository from "./CompanyRepository";
import CategoryValidator from "../validators/CategoryValidator";
import CompanyValidator from "../validators/CompanyValidator";
import TagRepository from "./TagRepository";
import TagValidator from "../validators/TagValidator";
import CertificationRepository from "./CertificationRepository.js";
import SuggestedCompanyRepository from "./SuggestedCompanyRepository";
import SuggestedCompanyValidator from "../validators/SuggestedCompanyValidator";
import EthicalFactsRepository from "./EthicalFactsRepository.js";
import SubscriberRepository from "./SubscriberRepository";
import SubscriberValidator from "../validators/SubscriberValidator";
import CountryRepository from "./CountryRepository";

/**
 * This class builds the data repositories from data sourced from the data storage engine (at time of writing, firebase).
 * It waits for the database to be ready, then creates the data repository object. That method allows a callback
 * function to be passed in, so that code that uses it can access the data repository object. It has a getter to
 * return that object.
 */
class DataRepositoryFactory {
  constructor() {
    this.categoryRepository = null;
    this.certificationRepository = null;
    this.companyRepository = null;
    this.countryRepository = null;
    this.ethicalFactsRepository = null;
    this.tagRepository = null;
    this.subscriberRepository = null;
    this.createRepositories = true;
  }

  ready(callback) {
    const databaseRoot = firebaseDatabase.ref('/');
    databaseRoot.on('value', (snapshot) => {
      let dataRoot = snapshot.val();

      // If we haven't created the repositories yet, create them and pass in the relevant datasets.
      // Otherwise, tell the repositories to update their data sets.
      let categoryDataset = (dataRoot && dataRoot.hasOwnProperty('categories')) ? dataRoot.categories : [];
      let companyDataset = (dataRoot && dataRoot.hasOwnProperty('companies')) ? dataRoot.companies : [];
      let suggestedCompanyDataset = (dataRoot && dataRoot.hasOwnProperty('suggestedCompanies')) ? dataRoot.suggestedCompanies : [];
      let tagDataset = (dataRoot && dataRoot.hasOwnProperty('tags')) ? dataRoot.tags : [];
      let subscriberDataset = (dataRoot && dataRoot.hasOwnProperty('subscribers')) ? dataRoot.subscribers : [];
      if (this.createRepositories) {
        this.createRepositories = false;
        this.categoryRepository = new CategoryRepository(
          firebaseDatabase,
          new StorageRepository(),
          categoryDataset
        );
        this.companyRepository = new CompanyRepository(
          firebaseDatabase,
          new StorageRepository(),
          companyDataset
        );
        this.suggestedCompanyRepository = new SuggestedCompanyRepository(
          firebaseDatabase,
          new StorageRepository(),
          suggestedCompanyDataset
        );
        this.tagRepository = new TagRepository(
          firebaseDatabase,
          new StorageRepository(),
          tagDataset
        );
        this.subscriberRepository = new SubscriberRepository(
          firebaseDatabase,
          new StorageRepository(),
          subscriberDataset
        );
        // We now need to create the validators, and pass them in to the repositories.
        // This is a bit nasty because the repositories need the validators and the validators need the repositories,
        // so we have to fudge things a little bit.
        this.categoryRepository.setCategoryValidator(new CategoryValidator(this.categoryRepository, this.companyRepository));
        this.companyRepository.setCompanyValidator(new CompanyValidator(this.companyRepository));
        this.suggestedCompanyRepository.setSuggestedCompanyValidator(new SuggestedCompanyValidator(this.companyRepository));
        this.tagRepository.setTagValidator(new TagValidator(this.tagRepository, this.companyRepository, this.suggestedCompanyRepository));
        this.subscriberRepository.setSubscriberValidator(new SubscriberValidator(this.subscriberRepository));
        if (callback && callback instanceof Function) {
          callback();
        }
      } else {
        this.categoryRepository.replaceDataset(categoryDataset);
        this.companyRepository.replaceDataset(companyDataset);
        this.suggestedCompanyRepository.replaceDataset(suggestedCompanyDataset);
        this.tagRepository.replaceDataset(tagDataset);
        this.subscriberRepository.replaceDataset(subscriberDataset);
      }

    });
  }

  getCategoryRepository() {
    return this.categoryRepository;
  }

  getCertificationRepository() {
    // This repository is special in that we instantiate it the first time it's asked for.
    if (this.certificationRepository === null) {
      this.certificationRepository = new CertificationRepository();
    }

    return this.certificationRepository;
  }

  getCompanyRepository() {
    return this.companyRepository;
  }

  getCountryRepository() {
    // This repository is special in that we instantiate it the first time it's asked for.
    if (this.countryRepository === null) {
      this.countryRepository = new CountryRepository();
    }

    return this.countryRepository;
  }

  getEthicalFactsRepository() {
    // This repository is special in that we instantiate it the first time it's asked for.
    if (this.ethicalFactsRepository === null) {
      this.ethicalFactsRepository = new EthicalFactsRepository();
    }

    return this.ethicalFactsRepository;
  }

  getSuggestedCompanyRepository() {
    return this.suggestedCompanyRepository;
  }

  getTagRepository() {
    return this.tagRepository;
  }

  getSubscriberRepository() {
    return this.subscriberRepository;
  }
}

export default DataRepositoryFactory;
