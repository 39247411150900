class UrlHelperService {
  /**
   * @param {SuggestedCompany} suggestedCompany
   * @return {string}
   */
  getPathForSuggestedProduct(suggestedCompany) {
    // Of course, by "path", we mean the bit after the hash.
    return '/manageSuggestedCompany/'+suggestedCompany.getRowId();
  };

  /**
   * @param {SuggestedCompany} suggestedCompany
   * @return {string}
   */
  getAdminPathForSuggestedProduct(suggestedCompany) {
    // Of course, by "path", we mean the bit after the hash.
    return '/admin/suggestedCompanies/review/'+suggestedCompany.getId();
  };

  makeFullUrl(url) {
    let linkUrl = url;
    if (linkUrl.indexOf('http') !== 0) {
      linkUrl = 'https://'+linkUrl;
    }

    return linkUrl;
  }
}

export default UrlHelperService;
