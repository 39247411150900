import React, {Component} from 'react';
import {firebaseAuth} from "../../dataStore/firebase";
import {Link} from "react-router-dom";

class LogOutButton extends Component {
  logOut = () => {
    firebaseAuth.signOut().then(function() {
      // Sign-out successful.
    }).catch(function(error) {
      // An error happened.
    });
  }

  render() {
    // The user shouldn't be null, but we handle it, just in case.
    let user = firebaseAuth.currentUser;
    let name = user ? (user.displayName ? user.displayName : user.email) : 'Unknown';

    // Currently, an admin can only edit their own user profile, hence the URL being "/admin/users/edit" with no user id.
    return (
      <div className="logged-in-user">
        <Link to='/admin/users/edit'>
          <span className="glyphicon glyphicon-cog user-profile" aria-hidden="true"></span>
        </Link>
        <span className="greeting">Hi, {name}</span>
        <button type="button" onClick={this.logOut}>
          Sign Out
        </button>
      </div>
    )
  }
}

export default LogOutButton;
