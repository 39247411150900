import React from "react";

class AbstractField extends React.Component {
  constructor(props) {
    super(props);

    this.isViewScreen = !!this.props.isViewScreen;
  }
}

export default AbstractField;
