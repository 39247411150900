import React from 'react';
import ImageUploader from 'react-images-upload';
import MandatoryFieldIndicator from "./MandatoryFieldIndicator";
import AbstractField from "./AbstractField";

class ImageChooser extends AbstractField {
  constructor(props) {
    super(props);

    this.showImage = this.props.hasOwnProperty('showImage') ? this.props.showImage : true;
  }

  // @TODO: Check allowed set of image types and max file size.
  // @TODO: We need to allow the user to remove the current image, if "required" is false.
  render() {
    // Unfortunately, we want a slightly different label to the one it makes, so we have to build it ourselves.
    const imgExtensions = ['.jpg', '.jpeg', '.gif', '.png'];
    const maxFileSizeMb = 5;
    let label='Max file size: '+maxFileSizeMb+'mb, accepted: '+imgExtensions.map((ext) => { return '*'+ext;}).join('|');

    return (
      <div className="row">
        <div className="col-md-2">
          {this.props.label}{this.props.required && <MandatoryFieldIndicator/>}
        </div>
        {
          (this.showImage || this.isViewScreen) &&
          <div className="col-md-4 image">
            <img src={this.props.value && this.props.value.hasUrl() ? this.props.value.getUrl() : '/images/noimage.png'} alt=""/>
          </div>
        }
        {!this.isViewScreen &&
        <div className="col-md-3">
          <ImageUploader
            singleImage={true}
            withPreview={true}
            buttonText='Choose image'
            onChange={this.props.onchange}
            imgExtension={imgExtensions}
            maxFileSize={maxFileSizeMb*1024*1024}
            label={label}
          />
        </div>
        }
      </div>
    )
  }
}

export default ImageChooser;
