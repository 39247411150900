class Country {
  constructor(data) {
    this.code = data.hasOwnProperty('code') ? data.code : null;
    this.name = data.hasOwnProperty('name') ? data.name : null;
  }

  getCode() {
    return this.code;
  };

  getName() {
    return this.name;
  };

  /**
   * Unfortunately there is some code (the field selectors) that assume the key for a model is the id. For countries
   * it's not, so we have to have a pseudo "getId()" method that actually returns the country code.
   * @return {String}
   */
  getId() {
    return this.getCode();
  };
}

export default Country;
