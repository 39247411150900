import React from 'react';
import OneCategory from "./OneCategory";

class ShowCategories extends React.Component {
  constructor(props) {
    super(props);

    // We have to reset the number of companies that are displayed for the selected category, as the user has arrived
    // on the list of categories page.
    props.numberOfCompaniesDisplayedForCategory.reset();
  }

  render() {
    let categoryList = [];
    let categories = this.props.categoryRepository.getCategories(null, true).sort(
      function(category1, category2) {
        return category1.getName().toLowerCase() > category2.getName().toLowerCase() ? 1 : -1
      }
    );
    for(let idx = 0; idx < categories.length; idx++) {
      categoryList.push((
        <div key={categories[idx].getId()} className="col-sm-4 col-md-4">
          <OneCategory category={categories[idx]}/>
        </div>
      ));
    }

    return (
      <div className="view-categories top-level-category">
        <h2>View Categories</h2>
        <div className="row">
          {categoryList}
        </div>
      </div>
    )
  }
}

export default ShowCategories;
