import React from "react";

class OneEthicalFact extends React.Component {
  constructor(props) {
    super(props);

    this.ethicalFact = props.ethicalFact;
    this.index = parseInt(this.props.index) + 1;
  }
  render() {
    // The output alternates "side to side". The "side" we're rendering depends on whether the index is odd or even.
    let isOdd = this.index % 2;
    let source = 'images/landing-page/ethical-facts/'+this.ethicalFact.getLogoFilename();
    let image = <img src={source} alt="Ethical Fact"/>;
    let hash = <div className="hash-container pull-left"><div className="hash">#{this.index}</div></div>;
    let fact = <p className="fact">{this.ethicalFact.getFact()}</p>;

    if (isOdd) {
      return (
        <div className="row ethical-fact">
          <div className="col-md-1 col-sm-1"/>
          <div className="col-md-7 col-sm-7">
            {hash}
            {fact}
          </div>
          <div className="col-md-2 col-sm-2">{image}</div>
        </div>
      );
    } else {
      return (
        <div className="row ethical-fact">
          <div className="col-md-2 col-sm-2"/>
          <div className="col-md-2 col-sm-2">{image}</div>
          <div className="col-md-7 col-sm-7">
            {hash}
            {fact}
          </div>
        </div>
      );
    }
  }
}

export default OneEthicalFact;
