import React from "react";
import {SuggestedCompanyStatuses} from "../../models/Statuses/SuggestedCompanyStatuses";
// eslint-disable-next-line
import SuggestedCompany from "../../models/SuggestedCompany";
import AbstractSuggestedCompanyMaintenance from "../admin/companies/Suggested/AbstractSuggestedCompanyMaintenance";
import CommunicationsHelperService from "../../services/CommunicationsHelperService";

class ManageSuggestedCompany extends AbstractSuggestedCompanyMaintenance {
  constructor(props) {
    super(props);

    this.state.companyCopy.verificationCode = this.company.extractVerificationCode();
    this.state.companyCopy.moreAdditionalDetails = '';

    this.state.verificationCode = '';
    this.state.codeIsInvalid = false;

    this.communicationsHelperService = new CommunicationsHelperService(); // @TODO: I'm not sure how best to get an instance of this service.
  }

  /**
   * @return {SuggestedCompany}
   */
  getSuggestedCompany() {
    return this.props.suggestedCompanyRepository.getSuggestedCompanyByKey(this.props.match.params.key);
  }

  getInitialCompany() {
    // Load the suggested company. If we can't find it, show a message and redirect to the home page.
    let suggestedCompany = this.getSuggestedCompany();
    if (suggestedCompany === null) {
      window.alert('Sorry, that company suggestion cannot be found');
      window.location.href = '/';
    }

    return suggestedCompany;
  }

  getSaveButtonInitialEnabledState() {
    return this.company.getStatus() !== SuggestedCompanyStatuses.submitted;
  }

  isViewScreen() {
    return true;
  }

  isSubmittedSuggestedCompany() {
    return this.state.companyCopy.status === SuggestedCompanyStatuses.submitted;
  }

  setMoreAdditionalDetails = event => {
    this.state.companyCopy.moreAdditionalDetails = event.target.value;
    this.updateState();
  }

  saveCompany = event => {
    event.preventDefault();
    if (this.state.companyCopy.moreAdditionalDetails === '') {
      window.alert('You must enter additional notes before you can save them!');

      return;
    }
    // Load the suggested company object in focus, append the additional notes to the existing notes and save it.
    let newAddition = this.state.companyCopy.moreAdditionalDetails;
    let suggestedCompany = this.getSuggestedCompany();
    let newDetails = suggestedCompany.getSuggesterAdditionalDetails();
    if (newDetails) {
      newDetails += "\n\n";
    }
    newDetails += '-- Added by the suggester ' + (new Date()).toUTCString() + " --\n"+newAddition;
    suggestedCompany.setSuggesterAdditionalDetails(newDetails);
    // Now save the record.
    this.suggestedCompanyRepository.persist(
      suggestedCompany,
      'Your suggested company was updated!',
      () => {
        // Set the current additional details in the company copy to the new details and clear the form field.
        this.state.companyCopy.suggesterAdditionalDetails = suggestedCompany.getSuggesterAdditionalDetails();
        this.state.companyCopy.moreAdditionalDetails = '';
        this.setState({companyCopy: this.state.companyCopy});
        // Send an email the admins to say the notes have been updated.
        this.communicationsHelperService.sendMessageToAdmins(
          suggestedCompany,
          'Suggester has added notes',
          'New notes:\n\n'+newAddition
        );
      }
    );

  }

  setVerificationCode = event => {
    this.setState({verificationCode: event.target.value});
  }

  handleVerificationCodeConfirm = event => {
    if (this.state.verificationCode === this.state.companyCopy.verificationCode.toString()) {
      // They entered the correct code!!
      // Load the actual suggested company, set the status, save it and in the callback, update the state so that it
      // displays as if a verified suggested company was loaded.
      let suggestedCompany = this.getSuggestedCompany();
      suggestedCompany.setStatus(SuggestedCompanyStatuses.pending);
      let self = this;
      this.suggestedCompanyRepository.persist(
        suggestedCompany,
        'Code is correct! The company is now ready for us to review!',
        () => {
          // Change the state so that the suggested company on screen _is_ pending.
          self.state.companyCopy.status = suggestedCompany.getStatus();
          self.setState({companyCopy: self.state.companyCopy});
          self.setSaveButtonEnabled();
          // Send an email to the admins to say a new company suggestion has been verified.
          this.communicationsHelperService.afterCodeVerification(suggestedCompany);
        }
      );
    } else {
      // Code does not match. We show an appropriate message and hide the form for a couple of seconds. We hide the
      // form in case someone is trying a brute force attack. It will at least slow them down.
      this.setState({codeIsInvalid: true});
      window.setTimeout(
        () => {this.setState({codeIsInvalid: false})},
        2000
      )
    }
  }

  verificationCodeForm() {
    if (!this.isSubmittedSuggestedCompany()) {
      return null;
    }

    let partial = <p className="error">Sorry, that code does not match. Please try again...</p>;
    if (!this.state.codeIsInvalid) {
      partial = (
        <div>
          <input type="text" value={this.state.verificationCode} maxLength="6" onChange={this.setVerificationCode}/>
          <button className="btn btn-success" onClick={this.handleVerificationCodeConfirm}>Confirm</button>
        </div>
      )
    }

    return (
      <div className="row">
        <div className="col-md-2"/>
        <div className="col-md-6 verification-code-form">
          <h3>Thank you so much for suggesting this company</h3>
          <p>Please enter the verification code that was emailed to you</p>
          {partial}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="public-facing manage">
        <h2>Manage Your Suggested Company</h2>
        {this.verificationCodeForm()}
        <h3>Company Details</h3>
        {this.maintenanceForm()}
      </div>
    )
  }
}

export default ManageSuggestedCompany;
