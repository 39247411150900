import {CollectionNames} from "./Constants";
import AbstractDataRepository from "./AbstractDataRepository";
import Subscriber from "../models/Subscriber";

/**
 * This class is the interface between the Subscribers data and the storage engine (at time of writing, firebase).
 * It is instantiated when the data repository factory determines the database is ready. The raw data is passed in to
 * the constructor.
 */
class SubscriberRepository extends AbstractDataRepository {
  /**
   * The constructor receives raw data from the database, representing the raw subscriber data.
   * @param dataSets
   * @param {StorageRepository} storageRepository
   * @param rawSubscribers
   */
  constructor(dataSets, storageRepository, rawSubscribers) {
    super(dataSets, storageRepository);
    this.replaceDataset(rawSubscribers);
  }

  replaceDataset(rawSubscribers) {
    // We just remember the raw subscribers here. They are only converted into instances of models when they're needed.
    this.subscribers = null;
    this.rawSubscribers = rawSubscribers;
  }

  populateData() {
    if (this.subscribers === null) {
      this.subscribers = {};
      for (let subscriberRef in this.rawSubscribers) {
        if (this.rawSubscribers.hasOwnProperty(subscriberRef)) {
          let oneCat = this.rawSubscribers[subscriberRef];
          this.subscribers[subscriberRef] = new Subscriber(oneCat);
        }
      }
    }
  }

  /**
   * @param {SubscriberValidator} subscriberValidator
   */
  setSubscriberValidator(subscriberValidator) {
    this.subscriberValidator = subscriberValidator;
  }

  findByEmail(email) {
    email = email.toString().toLowerCase();
    this.populateData();
    let matching = this.filterRecords(
      this.subscribers,
      function (subscriber) {
        return email === subscriber.getEmail().toString().toLowerCase();
      }
    )

    if (matching.length === 1) {
      return matching.shift(); // Take the first one (index not necessarily 0)
    }

    return null;
  }

  getLatestSubscribers(howMany) {
    // Make sure the data is populated.
    this.populateData();
    // Sort the records by id descending (we're assuming the calling code wants them latest first).
    // This is really horrible because we have to convert the set of subscribers into an array before we can sort it.
    let latestSubscribers = this.filterRecords(
      this.subscribers,
      () => true
    );
    latestSubscribers = latestSubscribers.sort(
      (subscriber1, subscriber2) => {
        return subscriber1.getId() > subscriber2.getId() ? -1 : 1;
      }
    );
    latestSubscribers = latestSubscribers.slice(0, howMany);

    return latestSubscribers;
  }

  /**
   * Save the given subscriber back to the data store.
   *
   * @param subscriber {Subscriber}
   * @param successMsg {string|null}
   * @param callback {Function|null}
   * @return {ValidationResult}
   */
  persist(subscriber, successMsg, callback) {
    let result = this.subscriberValidator.validate(subscriber);
    if (!result.isValid()) {
      return result;
    }
    this.populateData(); // Make sure the data is present.

    if (subscriber.hasId()) {

      // This is an update.
      // Determine the index of the record in the array from the model's id.
      let subscriberIndex = this.determineIndexFromSubscriberModel(subscriber);
      // Create a reference to the object in the array.
      let subscriberSource = this.subscribers[subscriberIndex];
      // @TODO: I wonder if the original version of the record should be in the model, before the model constructor
      // @TODO: does any data migrations (adding columns, etc).
      let originalSubscriber = Object.assign({}, subscriberSource);
      // Overwrite the record with the values from the model. It is a bit yucky because the image value has to be the
      // raw value that firebase will accept, but we need to convert it back to an object afterwards, hence wrapping
      // any callback function pass in with a function that will do that conversion.
      this.populateRecordFromSubscriberModel(subscriberSource, subscriber);
      let persistCallback = (recordId) => {
        if (callback) {
          callback(recordId);
        }
      }
      // Save the record back to the store.
      this.update(CollectionNames.subscribers, subscriberIndex, successMsg, persistCallback, subscriber.getId(), originalSubscriber, subscriberSource);

    } else {

      // This is an insert. Generate a new id and start a new object.
      subscriber.setId(this.generateNextSubscriberId());
      let newSubscriber = {
        id: subscriber.getId()
      };
      // Populate the record with the values from the model.
      this.populateRecordFromSubscriberModel(newSubscriber, subscriber);
      // Create the record in the store.
      this.insert(CollectionNames.subscribers, newSubscriber, successMsg, callback, subscriber.getId());

    }

    return result;
  };

  generateNextSubscriberId() {
    let id = 0;
    this.forEachRecord(
      this.subscribers,
      function(subscriber) {
        id = Math.max(id, subscriber.id);
      }
    );
    ++id;

    return id;
  };

  determineIndexFromSubscriberModel(subscriberModel) {
    // Determine the index of the firebase array, using the subscriber model's id.
    let subscriberIndex = null;
    this.forEachRecord(
      this.subscribers,
      function (subscriber, index) {
        if (subscriber.id === subscriberModel.getId()) {
          subscriberIndex = index;
        }
      }
    );

    return subscriberIndex;
  };

  /**
   * @param subscriberRecord
   * @param {Subscriber} subscriber
   */
  populateRecordFromSubscriberModel(subscriberRecord, subscriber) {
    subscriberRecord.email = subscriber.getEmail();
    subscriberRecord.newsletterCount = subscriber.getNewsletterCount();
    subscriberRecord.partnershipCount = subscriber.getPartnershipCount();
    subscriberRecord.createdAt = subscriber.getCreatedAtAsString();
  };

  /**
   * A fudge to add any newly created record on to the list of subscriber models in this repository.
   * @param newKey
   * @param record
   */
  afterInsert(newKey, record) {
    this.populateData();
    this.subscribers[newKey] = new Subscriber(record);
  }
}

export default SubscriberRepository;
