import Category from "../models/Category";
import {CollectionNames} from "./Constants";
import AbstractDataRepository from "./AbstractDataRepository";
import {UploadFolderNames} from "./UploadFolderNames";

/**
 * This class is the interface between the category data and the storage engine (at time of writing, firebase).
 * It is instantiated when the data repository factory determines the database is ready. The raw data is passed in to
 * the constructor.
 */
class CategoryRepository extends AbstractDataRepository {
  /**
   * The constructor receives raw data from the database, representing the raw category data.
   * @param dataSets
   * @param {StorageRepository} storageRepository
   * @param rawCategories
   */
  constructor(dataSets, storageRepository, rawCategories) {
    super(dataSets, storageRepository);
    this.replaceDataset(rawCategories);
  }

  replaceDataset(rawCategories) {
    this.categories = {};
    for (let categoryRef in rawCategories) {
      if (rawCategories.hasOwnProperty(categoryRef)) {
        let oneCat = rawCategories[categoryRef];
        this.categories[categoryRef] = new Category(oneCat);
      }
    }
  }

  /**
   * @param {CategoryValidator} categoryValidator
   */
  setCategoryValidator(categoryValidator) {
    this.categoryValidator = categoryValidator;
  }

  getCategories(excludeCategoryID, excludeSeasonalCategories) {
    excludeSeasonalCategories = !!excludeSeasonalCategories;
    // Because this.categories is an object pretending to be an array, we need to convert it to a proper array before
    // returning it. Note the firebase keys will get replaced with a simple zero-based index.
    let result = [];
    this.forEachRecord(
      this.categories,
      function(category) {
        if (excludeCategoryID === undefined || category.getId() !== excludeCategoryID) {
          if (!(excludeSeasonalCategories && category.getIsSeasonalCategory())) {
            result.push(category);
          }
        }
      }
    );

    return result;
  }

  getCategoryById(categoryId) {
    categoryId = parseInt(categoryId);
    let matching = this.filterRecords(
      this.categories,
      function (category) {
        return categoryId === category.getId();
      }
    )

    if (matching.length === 1) {
      return matching.shift(); // Take the first one (index not necessarily 0)
    }

    return null;
  };

  /**
   * @param {int|null} excludingCategoryId
   * @return {Category[]}
   */
  getCurrentSeasonalCategories(excludingCategoryId) {
    // First, get all current seasonal categories (in theory there will be zero or one of them).
    let matching = this.filterRecords(
      this.categories,
      function (category) {
        return category.getIsCurrentSeasonalCategory();
      }
    )
    // If an "excluding" category id was passed in, make sure that category is not in the result.
    if (excludingCategoryId) {
      excludingCategoryId = parseInt(excludingCategoryId);
      matching = this.filterRecords(
        matching,
        function (category) {
          return excludingCategoryId !== category.getId();
        }
      )
    }

    return matching;
  }

  /**
   * Save the given category back to the data store.
   *
   * @param category {Category}
   * @param successMsg {string|null}
   * @param callback {Function|null}
   * @param clearingCurrentSeasonalCategoryFlags {boolean|null}
   * @return {ValidationResult}
   */
  persist(category, successMsg, callback, clearingCurrentSeasonalCategoryFlags) {
    let result = this.categoryValidator.validate(category);
    if (!result.isValid()) {
      return result;
    }

    // If the category's image need uploading, we forget about saving the category (kind of) and instead upload the
    // image. In the callback method from that upload, we receive the new image URL, which we put in to the category
    // object and call persist recursively.
    if (category.getImage() && category.getImage().requiresUpload()) {
      // In the callback, we put the new image URL into the category and call persist recursively.
      let uploadCallback = imageUrl => {
        category.setImage(imageUrl);
        this.persist(category, successMsg, callback);
      }
      this.storageRepository.uploadImageInModel(category.getImage(), UploadFolderNames.category, uploadCallback);

      return result; // Forget about saving the the category in this "thread". The callback above will do it now.
    }

    // There is a rule that only 1 category can be the "current" seasonal category. If we are saving a category that
    // if marked as the current one, make sure all other categories are _not_ the current one.
    // We have a flag that says whether we are clearing the "current seasonal" flag to prevent it trying to do it
    // when we save a category when processing the "current seasonal" flag. Ie it prevents infinite recursion.
    if (clearingCurrentSeasonalCategoryFlags !== true && category.getIsCurrentSeasonalCategory()) {
      let currentSeasonalCategories = this.getCurrentSeasonalCategories(category.getId());
      // Loop through all those categories (hopefully only zero or one) and switch off their "current" flag.
      let self = this;
      currentSeasonalCategories.forEach(
        function (category) {
          category.setIsCurrentSeasonalCategory(false);
          self.persist(category, null, null, true);
        }
      )
    }

    if (category.hasId()) {

      // This is an update.
      // Determine the index of the record in the array from the model's id.
      let categoryIndex = this.determineIndexFromCategoryModel(category);
      // Create a reference to the object in the array.
      let categorySource = this.categories[categoryIndex];
      // @TODO: I wonder if the original version of the record should be in the model, before the model constructor
      // @TODO: does any data migrations (adding columns, etc).
      let originalCategory = Object.assign({}, categorySource);
      // Overwrite the record with the values from the model. It is a bit yucky because the image value has to be the
      // raw value that firebase will accept, but we need to convert it back to an object afterwards, hence wrapping
      // any callback function pass in with a function that will do that conversion.
      this.populateRecordFromCategoryModel(categorySource, category);
      let persistCallback = (recordId) => {
        // Convert the text URL back to an image object.
        categorySource.setImage(categorySource.getImage());
        if (callback) {
          callback(recordId);
        }
      }
      // Save the record back to the store.
      this.update(CollectionNames.categories, categoryIndex, successMsg, persistCallback, category.getId(), originalCategory, categorySource);

    } else {

      // This is an insert. Generate a new id and start a new object.
      category.setId(this.generateNextCategoryId());
      let newCategory = {
        id: category.getId()
      };
      // Populate the record with the values from the model.
      this.populateRecordFromCategoryModel(newCategory, category);
      // Create the record in the store.
      this.insert(CollectionNames.categories, newCategory, successMsg, callback, category.getId());

    }

    return result;
  };

  generateNextCategoryId() {
    let id = 0;
    this.forEachRecord(
      this.categories,
      function(category) {
        id = Math.max(id, category.id);
      }
    );
    ++id;

    return id;
  };

  determineIndexFromCategoryModel(categoryModel) {
    // Determine the index of the firebase array, using the category model's id.
    let categoryIndex = null;
    this.forEachRecord(
      this.categories,
      function (category, index) {
        if (category.id === categoryModel.getId()) {
          categoryIndex = index;
        }
      }
    );

    return categoryIndex;
  };

  populateRecordFromCategoryModel(categoryRecord, category) {
    categoryRecord.description = category.getDescription();
    categoryRecord.image = category.getImage().hasUrl() ? category.getImage().getUrl() : null;
    categoryRecord.name = category.getName();
    // Seasonal stuff...
    categoryRecord.isSeasonalCategory = category.getIsSeasonalCategory();
    categoryRecord.isCurrentSeasonalCategory = category.getIsCurrentSeasonalCategory();
    categoryRecord.seasonalText = category.getSeasonalText();
    categoryRecord.seasonalButtonLabel = category.getSeasonalButtonLabel();
  };

  /**
   * A fudge to add any newly created record on to the list of category models in this repository.
   * @param newKey
   * @param record
   */
  afterInsert(newKey, record) {
    this.categories[newKey] = new Category(record);
  }
}

export default CategoryRepository;
