/**
 * This represents an arbitrary text search string for a "list" page.
 */
class TextSearch {
  constructor(currentState) {
    this.textFilter = (currentState && currentState.hasOwnProperty('textFilter') ? currentState.textFilter : null);
  }

  setTextFilter(newValue) {
    this.textFilter = newValue;
  }

  getTextFilter() {
    return this.textFilter;
  }
}

export default TextSearch;
