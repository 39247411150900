import React from "react";

class TeamMemberGroup extends React.Component {
  render() {
    let members = this.props.members;
    // If members.length is greater than 6, then we need to do this in a more sophisticated way (do it in groups of 6).
    let teamMembers = [];
    if (members.length < 6) {
      let paddingCells = Math.floor((6 - members.length) / 2);
      for (let idx = 0; idx < paddingCells; ++idx) {
        teamMembers.push(<div className="col-md-4"/>);
      }
    }
    for (let idx = 0; idx < members.length; ++idx) {
      teamMembers.push(<div key={idx} className="col-lg-4 col-md-4 col-sm-4">{members[idx]}</div>);
    }

    return <div className="row">
      <div className="team-group">{teamMembers}</div>
    </div>;
  }
}

export default TeamMemberGroup;
