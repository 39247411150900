import AbstractField from "./AbstractField";
import MandatoryFieldIndicator from "./MandatoryFieldIndicator";
import React from "react";

class FieldCheckbox extends AbstractField {
  render() {
    let inputValue = !!this.props.value;

    return (
      <div className="row">
        <div className="col-md-2">
          {this.props.label}{this.props.required && <MandatoryFieldIndicator/>}
        </div>
        <div className="col-md-10">
          {this.isViewScreen ?
            <div>{inputValue ? 'Yes' : 'No'}</div>
            :
            <input type="checkbox" value="1" onChange={this.props.onchange} checked={inputValue}/>
          }
        </div>
      </div>
    )
  }
}

export default FieldCheckbox;
