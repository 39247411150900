import {SuggestedCompanyStatuses} from "../models/Statuses/SuggestedCompanyStatuses";
import CommunicationsHelperService from "./CommunicationsHelperService";
import Company from "../models/Company";

class SuggestedCompanyService {
  /**
   * @param {SuggestedCompanyRepository} suggestedCompanyRepository
   * @param {CompanyRepository} companyRepository
   */
  constructor(suggestedCompanyRepository, companyRepository) {
    this.suggestedCompanyRepository = suggestedCompanyRepository;
    this.companyRepository = companyRepository;
    this.communicationsHelperService = new CommunicationsHelperService(); // @TODO: I'm not sure how best to get an instance of this service.
  }

  /**
   * Transition the suggested company record with the given id to the given state. Sometimes this is not a trivial
   * process.
   */
  transition(suggestedCompanyId, newStatus, successMsg, callback) {
    // The action will depend on the value of the desired state and the current state of the record.
    // At time of writing the allowed changes are:
    //  Submitted -> Pending (not handled here) @TODO: See if it could be handled here
    //  Pending -> Approved|Rejected
    //  Submitted|Pending|Rejected -> permanently deleted @TODO: Not done yet
    // Load the suggested company, change the status, save it, and in the callback from the save, send the appropriate
    // email.
    let suggestedCompany = this.suggestedCompanyRepository.getSuggestedCompanyById(suggestedCompanyId);
    // Currently, a lot of transitions are not implemented/valid, so weed them out with a nice message.
    let allowed =
      (
        suggestedCompany.getStatus() === SuggestedCompanyStatuses.pending &&
        (newStatus === SuggestedCompanyStatuses.approved || newStatus === SuggestedCompanyStatuses.rejected)
      ) ||
      (
        suggestedCompany.getStatus() === SuggestedCompanyStatuses.rejected &&
        newStatus === SuggestedCompanyStatuses.pending
      ) ||
      (
        (
          suggestedCompany.getStatus() === SuggestedCompanyStatuses.submitted ||
          suggestedCompany.getStatus() === SuggestedCompanyStatuses.pending ||
          suggestedCompany.getStatus() === SuggestedCompanyStatuses.rejected
        ) &&
        newStatus === 'Deleted'
      );
    if (!allowed) {
      window.alert('The suggested company cannot currently be transitioned to the new state');

      return;
    }

    if (newStatus === 'Deleted') {
      // Currently, we don't send an email to the suggester when we delete one of their suggestions.
      let result = this.suggestedCompanyRepository.deleteSuggestedCompany(
        suggestedCompany,
        'The suggested company has been deleted',
        callback
      );
      if (!result.isValid()) {
        window.alert('Could not delete the suggested company: '+result.getErrors());
      }
    } else {
      // Set the status to the desired value.
      suggestedCompany.setStatus(newStatus);

      // Persist the change, then do any post-change processing.
      this.suggestedCompanyRepository.persist(
        suggestedCompany,
        successMsg,
        () => {
          // @TODO: For some reason, between loading the suggested company at the start of this method, and here, the
          // @TODO: createdAt property has been converted from a Date to a string (which can't happen!) and I'm not sure
          // @TODO: why. I'm re-loading the suggested company here to work around it, but it would be nice to find the
          // @TODO: cause of the problem.
          suggestedCompany = this.suggestedCompanyRepository.getSuggestedCompanyById(suggestedCompanyId);

          // Send the appropriate email to the suggester. This must be done last whichever way the flow goes because we
          // have to pass the supplied callback function in to the email methods.
          switch (suggestedCompany.getStatus()) {
            case SuggestedCompanyStatuses.approved:
              // This transition is complicated. We need to create a company from the suggested company data, then put the
              // company id in the suggested company, then send an email to the suggester. Because each step is
              // asynchronous, the "next" step must be done in the callback function passed to the previous step.
              let companyData = {};
              this.suggestedCompanyRepository.populateRecordFromSuggestedCompanyModel(companyData, suggestedCompany);
              let generatedCompany = new Company(companyData);
              this.companyRepository.persist(
                generatedCompany,
                'A company has been created from this suggestion',
                (generatedCompanyId) => {
                  // @TODO: Re-load the company, in case it's broken, as above.
                  suggestedCompany = this.suggestedCompanyRepository.getSuggestedCompanyById(suggestedCompanyId);
                  suggestedCompany.setGeneratedCompanyID(generatedCompanyId);
                  this.suggestedCompanyRepository.persist(
                    suggestedCompany,
                    null,
                    () => {
                      this.communicationsHelperService.afterApproval(suggestedCompany, callback);
                    }
                  );
                }
              )
              break;
            case SuggestedCompanyStatuses.rejected:
              this.communicationsHelperService.afterRejection(suggestedCompany, callback);
              break;
            case SuggestedCompanyStatuses.pending:
              this.communicationsHelperService.afterUnrejection(suggestedCompany, callback);
              break;
            default:
              if (callback) {
                callback();
              }
              break;
          }
        }
      );
    }
  }

  saveAdminNotes(suggestedCompanyId, newAdminNotes, successMsg, callback) {
    // Load the record and set its notes.
    let suggestedCompany = this.suggestedCompanyRepository.getSuggestedCompanyById(suggestedCompanyId);
    suggestedCompany.setAdminNotes(newAdminNotes);

    this.suggestedCompanyRepository.persist(
      suggestedCompany,
      successMsg,
      () => {
        // Send the appropriate email to the suggester. This must be done last whichever way the flow goes because we
        // have to pass the supplied callback function in to the email methods.
        this.communicationsHelperService.afterAddNotes(suggestedCompany, callback);
      }
    );
  }
}

export default SuggestedCompanyService;
