import React from 'react';

class MandatoryFieldIndicator extends React.Component {
  // @TODO: We need to pass in whether the form (well, field) is view-only, and not show the indicator if it is.

  render() {
    return (
      <span className="mandatory-field-indicator">*</span>
    )
  }
}

export default MandatoryFieldIndicator;
