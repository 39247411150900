import React from "react";

class CategoryDetailThumbnail extends React.Component {
  render() {
    let imgSrc = this.props.category.getImage().hasUrl() ? this.props.category.getImage().getUrl() : '/images/noimage.png';
    let desc = this.props.category.getDescription() ? <p>{this.props.category.getDescription()}</p> : null;

    return (
      <div className="row category-detail">
        <div className="col-md-3">
          <div className="image-container">
            <img src={`${imgSrc}`} className="img-responsive"
                 alt={this.props.category.getName()}/>
          </div>
        </div>

        <div className="col-md-9">
          <h2>{this.props.category.getName()}</h2>
          {desc}
        </div>
      </div>
    )
  }
}

export default CategoryDetailThumbnail;
