/**
 * The idea of this class is that it holds a set of labels, indexed by an id. Then, it has a method that receives an
 * array of ids and returns the matching labels as a CSL.
 * @TODO: Make specific subclasses of this for categories and tags.
 */
class LabelLookup {
  /**
   * @param {Array} dataSet
   * @param {Function} getId
   * @param {Function} getLabel
   */
  constructor(dataSet, getId, getLabel) {
    this.labelsById = [];

    let self = this;
    dataSet.forEach(
      function (dataItem) {
        self.labelsById[getId(dataItem)] = getLabel(dataItem);
      }
    )
  }

  /**
   * @param {Array} ids
   * @return {string}
   */
  idsToLabels(ids) {
    let labelNames = [];
    let self = this;
    ids.forEach(
      function(id) {
        labelNames.push(self.labelsById.hasOwnProperty(id) ? self.labelsById[id] : '[NOT FOUND]');
      }
    )

    return labelNames.join(', ');
  }

  getLabelFor(id) {
    return this.labelsById.hasOwnProperty(id) ? this.labelsById[id] : '[NOT FOUND]';
  }
}

export default LabelLookup;
