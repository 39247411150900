import AbstractFieldObjectSelector from "./AbstractFieldObjectSelector";

class FieldCountrySelector extends AbstractFieldObjectSelector {
  constructor(props) {
    super(props);

    this.countryRepository = this.props.modelRepository;
  }

  getLabel() {
    return 'Where does this company operate?';
  }

  loadAllModels() {
    return this.countryRepository.getCountries();
  }

  getObjectById(id) {
    return this.countryRepository.getCountryByCode(id);
  }

  getModelId(country) {
    return country.getCode();
  }

  getModelName(country) {
    return country.getName();
  }

  getModelDescription() {
    return 'country';
  }

  createModel = name => {
    alert('This should never be called');
  }

  /**
   * This overrides the default behaviour because we know the records are already in the correct (and special) order.
   */
  refreshAllModels() {
    this.allModels = this.loadAllModels();
  }
}

export default FieldCountrySelector;
