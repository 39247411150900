import ValidationResult from "./ValidationResult";
import CompanyFieldAttributes from "../services/CompanyFieldAttributes";

class CompanyValidator {
  /**
   * @param {CompanyRepository} companyRepository
   */
  constructor(companyRepository) {
    this.companyRepository = companyRepository;
    this.fieldAttributes = this.getFieldAttributes();
  }

  /**
   * Get the appropriate attributes for this screen.
   * @return {CompanyFieldAttributes}
   */
  getFieldAttributes() {
    return new CompanyFieldAttributes();
  }

  /**
   * @param {Company} company
   * @param {boolean|null} isSuggestedCompany
   * @param {int|null} generatedCompanyID If the object we're validating is a suggested company and it has been
   *                                      approved, this is the generated company ID.
   * @return {ValidationResult}
   */
  validate(company, isSuggestedCompany, generatedCompanyID) {
    let result = new ValidationResult();

    // Do the basic checks - are the mandatory fields entered? The field attributes are now held separately (basically
    // because the forms need them and everything needs to be consistent. We go through every field, and if it's
    // mandatory, we make sure it's populated.
    if (this.fieldAttributes.isRequired('name') && !company.hasName()) {
      result.addError('Company name is mandatory.');
    }
    if (this.fieldAttributes.isRequired('description') && !company.hasDescription()) {
      result.addError('Company description is mandatory.');
    }
    if (this.fieldAttributes.isRequired('image') && company.getImage().isEmpty()) {
      result.addError('Company image is mandatory.');
    }
    if (this.fieldAttributes.isRequired('categories') && !company.hasCategoryIds()) {
      result.addError('A company must have at least one category.');
    }
    if (this.fieldAttributes.isRequired('tags') && !company.hasTagIds()) {
      result.addError('A company must have at least one tag.');
    }
    if (this.fieldAttributes.isRequired('linkUrl') && !company.hasLinkUrl()) {
      result.addError('Company link URL is mandatory.');
    }

    // @TODO: should we check that the tag ids are valid?

    // Check that the company name is unique. The user could be adding a new company, editing an existing company or
    // trying to convert a suggested company to a company, so it's a little bit complicated. And also, there are
    // situations where an approved suggested company is being saved, in which case we shouldn't do the test at all!
    if (!isSuggestedCompany || !generatedCompanyID) {
      let excludeId = isSuggestedCompany ? null : company.getId();
      if (this.companyRepository.getCompanyByName(company.getName(), excludeId).length > 0) {
        // Be careful will this message because of all the scenarios we could be in.
        result.addError('A company already exists with this name.');
      }
    }

    return result;
  }
}

export default CompanyValidator;
