import Company from "../models/Company";
import {CollectionNames} from "./Constants";
import AbstractDataRepository from "./AbstractDataRepository";
import {UploadFolderNames} from "./UploadFolderNames";

/**
 * This class is the interface between the company data and the storage engine (at time of writing, firebase).
 * It is instantiated when the data repository factory determines the database is ready. The raw data is passed in to
 * the constructor.
 */
class CompanyRepository extends AbstractDataRepository {
  /**
   * The constructor receives raw data from the database, representing the raw company data.
   * @param dataSets
   * @param {StorageRepository} storageRepository
   * @param rawCompanies
   */
  constructor(dataSets, storageRepository, rawCompanies) {
    super(dataSets, storageRepository);
    this.replaceDataset(rawCompanies);
  }

  replaceDataset(rawCompanies) {
    this.companies = {};
    for (let companyRef in rawCompanies) {
      if (rawCompanies.hasOwnProperty(companyRef)) {
        let oneCompany = rawCompanies[companyRef];
        this.companies[companyRef] = new Company(oneCompany);
      }
    }
  }

  /**
   * @param {CompanyValidator} companyValidator
   */
  setCompanyValidator(companyValidator) {
    this.companyValidator = companyValidator;
  }

  getCompanyValidator() {
    return this.companyValidator;
  }

  getCompanies(excludeCompanyID) {
    // Because this.companies is an object pretending to be an array, we need to convert it to a proper array before
    // returning it. Note the firebase keys will get replaced with a simple zero-based index.
    // @TODO: Check whether we need the "exclude" parameter.
    let result = [];
    this.forEachRecord(
      this.companies,
      function(company) {
        if (excludeCompanyID === undefined || company.getId() !== excludeCompanyID) {
          result.push(company);
        }
      }
    );

    return result;
  }

  getCompanyById(companyId) {
    companyId = parseInt(companyId);
    let matching = this.filterRecords(
      this.companies,
      function (company) {
        return companyId === company.getId();
      }
    )

    if (matching.length === 1) {
      return matching.shift(); // Take the first one (index not necessarily 0)
    }

    return null;
  };

  /**
   * Return all companies whose category is the category that was passed in.
   * @param {Category} category
   */
  getCompaniesForCategory(category) {
    return this.filterRecords(
      this.companies,
      function (company) {
        return company.getCategoryIds().indexOf(category.getId()) >= 0;
      }
    );
  }

  getByTagId(tagId) {
    return this.filterRecords(
      this.companies,
      function (company) {
        return company.getTagIds().indexOf(tagId) >= 0;
      }
    );
  }

  getCompanyByName(name, excludeId) {
    name = name.toLowerCase();
    excludeId = excludeId ? excludeId : null; // Make it null if a number hasn't been passed in.
    return this.filterRecords(
      this.companies,
      function (company) {
        return company.getName().toLowerCase() === name && (excludeId === null || company.getId() !== excludeId);
      }
    );
  }

  /**
   * Save the given company back to the data store.
   *
   * @param company {Company}
   * @param successMsg {string|null}
   * @param callback {Function|null}
   * @return {ValidationResult}
   */
  persist(company, successMsg, callback) {
    let result = this.companyValidator.validate(company);
    if (!result.isValid()) {
      return result;
    }

    // If the company's image need uploading, we forget about saving the company (kind of) and instead upload the
    // image. In the callback method from that upload, we receive the new image URL, which we put in to the company
    // object and call persist recursively.
    if (company.getImage() && company.getImage().requiresUpload()) {
      // In the callback, we put the new image URL into the company and call persist recursively.
      let uploadCallback = imageUrl => {
        company.setImage(imageUrl);
        this.persist(company, successMsg, callback);
      }
      this.storageRepository.uploadImageInModel(company.getImage(), UploadFolderNames.company, uploadCallback);

      return result; // Forget about saving the the company in this "thread". The callback above will do it now.
    }

    if (company.hasId()) {

      // This is an update.
      // Determine the index of the record in the array from the model's id.
      let companyIndex = this.determineIndexFromCompanyModel(company);
      // Create a reference to the object in the array.
      let companySource = this.companies[companyIndex];
      // @TODO: I wonder if the original version of the record should be in the model, before the model constructor
      // @TODO: does any data migrations (adding columns, etc).
      let originalCompany = Object.assign({}, companySource);
      // Overwrite the record with the values from the model. It is a bit yucky because the image value has to be the
      // raw value that firebase will accept, but we need to convert it back to an object afterwards, hence wrapping
      // any callback function pass in with a function that will do that conversion.
      this.populateRecordFromCompanyModel(companySource, company);
      let persistCallback = () => {
        // Convert the text URL back to an image object.
        companySource.setImage(companySource.getImage());
        if (callback) {
          callback();
        }
      }
      // Save the record back to the store.
      this.update(CollectionNames.companies, companyIndex, successMsg, persistCallback, company.getId(), originalCompany, companySource);

    } else {

      // This is an insert. Generate a new id and start a new object.
      company.setId(this.generateNextCompanyId());
      let newCompany = {
        id: company.getId()
      };
      // Populate the record with the values from the model.
      this.populateRecordFromCompanyModel(newCompany, company);
      // Create the record in the store.
      this.insert(CollectionNames.companies, newCompany, successMsg, callback, company.getId());

    }

    return result;
  };

  generateNextCompanyId() {
    let id = 0;
    this.forEachRecord(
      this.companies,
      function(company) {
        id = Math.max(id, company.id);
      }
    );
    ++id;

    return id;
  };

  determineIndexFromCompanyModel(companyModel) {
    // Determine the index of the firebase array, using the company model's id.
    let companyIndex = null;
    this.forEachRecord(
      this.companies,
      function (company, index) {
        if (company.id === companyModel.getId()) {
          companyIndex = index;
        }
      }
    );

    return companyIndex;
  };

  populateRecordFromCompanyModel(companyRecord, company) {
    companyRecord.description = company.getDescription();
    companyRecord.image = company.getImage().hasUrl() ? company.getImage().getUrl() : null;
    companyRecord.name = company.getName();
    companyRecord.categoryIds = company.getCategoryIds();
    companyRecord.tagIds = company.getTagIds();
    companyRecord.linkUrl = company.getLinkUrl();
    companyRecord.countryCodes = company.getCountryCodes();
    companyRecord.createdAt = company.getCreatedAtAsString();
  };

  /**
   * A fudge to add any newly created record on to the list of company models in this repository.
   * @param newKey
   * @param record
   */
  afterInsert(newKey, record) {
    this.companies[newKey] = new Company(record);
  }
}

export default CompanyRepository;
