/**
 * This class represents a set of ids that the user of the class is filtering on.
 */
class IdFilter {
  constructor(currentState) {
    this.idList = currentState && currentState.hasOwnProperty('idList') ? currentState.idList : [];
  }

  getIds() {
    return this.idList;
  }

  hasIds() {
    return this.idList.length > 0;
  }

  hasId(id) {
    return this.idList.indexOf(id) >= 0;
  }

  toggleFilterId(id) {
    let idIndex = this.idList.indexOf(id);
    if (idIndex >= 0) {
      // We are currently including this category in the filter, so remove it.
      this.idList.splice(idIndex, 1);
    } else {
      // We are not currently filtering on this category, so add it.
      this.idList.push(id);
    }
  }
}

export default IdFilter;
