import ModelImage from "./common/ModelImage";

class Company {
  constructor(data) {
    this.description = data.hasOwnProperty('description') ? data.description : null;
    this.id = data.hasOwnProperty('id') && data.id !== null ? parseInt(data.id) : null;
    this.setImage(data.hasOwnProperty('image') ? data.image : null);
    this.name = data.hasOwnProperty('name') ? data.name : null;
    // The company has multiple category IDs, but it used to have a single ID. Load the IDs. For now, if there is no
    // "IDs" attribute in the data, but there is an "ID" attribute, convert the latter to the former.
    this.categoryIds = [];
    if (data.hasOwnProperty('categoryIds') && data.hasOwnProperty('categoryIds') !== null) {
      // @TODO: I'm sure this code can be commonised with the tagId code below.
      this.categoryIds = data.categoryIds;
      if (!Array.isArray(this.categoryIds)) {
        this.categoryIds = this.categoryIds.split(',');
        for (let categoryIdx = 0 ; categoryIdx < this.categoryIds.length ; categoryIdx++) {
          // @TODO: In theory, there should be some validation here.
          this.categoryIds[categoryIdx] = parseInt(this.categoryIds[categoryIdx]);
        }
      }
    } else {
      if (data.hasOwnProperty('categoryId') && data.categoryId !== null) {
        this.categoryIds.push(parseInt(data.categoryId));
      }
    }
    // In theory, the tags arrive as an array, but we accept a CSL just in case.
    this.tagIds = [];
    if (data.hasOwnProperty('tagIds') && data.tagIds !== null) {
      this.tagIds = data.tagIds;
      if (!Array.isArray(this.tagIds)) {
        this.tagIds = this.tagIds.split(',');
        for (let tagIdx = 0 ; tagIdx < this.tagIds.length ; tagIdx++) {
          // @TODO: In theory, there should be some validation here.
          this.tagIds[tagIdx] = parseInt(this.tagIds[tagIdx]);
        }
      }
    }
    this.linkUrl = data.hasOwnProperty('linkUrl') && data.linkUrl !== '' ? data.linkUrl : null;
    this.createdAt = null;
    if (this.hasId()) {
      // createdAt should remain null if it's missing in the record.
      // @TODO: We should validate this date/time.
      this.createdAt = data.hasOwnProperty('createdAt') ? new Date(data.createdAt) : null;
    } else {
      this.createdAt = new Date(); // @TODO: This needs to be converted to a string on save.
    }
    // In theory, the countries arrive as an array, but we accept a CSL just in case.
    this.countryCodes = [];
    if (data.hasOwnProperty('countryCodes') && data.countryCodes !== null) {
      this.countryCodes = data.countryCodes;
      if (!Array.isArray(this.countryCodes)) {
        this.countryCodes = this.countryCodes.split(',');
      }
    }
  };

  getId() {
    return this.id;
  };

  hasId() {
    return this.id !== null;
  };

  setId(newId) {
    this.id = newId;
  };

  getName() {
    return this.name;
  };

  hasName() {
    return this.name !== null && this.name !== '';
  };

  setName(name) {
    this.name = name;
  };

  getDescription() {
    return this.description;
  };

  setDescription(description) {
    this.description = description;
  };

  hasDescription() {
    return this.description !== null && this.description !== '';
  };

  getImage() {
    return this.image;
  };

  setImage(imageInfo) {
    this.image = imageInfo instanceof ModelImage ? imageInfo : new ModelImage(imageInfo);
  };

  setCategoryIds(categoryIds) {
    this.categoryIds = categoryIds;
  }

  getCategoryIds() {
    return this.categoryIds;
  }

  hasCategoryIds() {
    return this.categoryIds.length > 0;
  }

  /**
   * Accepts an array of ids of tags (possibly empty) associated with this company.
   * @TODO: In theory, we should validate this (eg each element is a positive integer).
   * @param {array} tagIds
   */
  setTagIds(tagIds) {
    this.tagIds = tagIds;
  }

  getTagIds() {
    return this.tagIds;
  }

  hasTagIds() {
    return this.tagIds.length > 0;
  }

  setLinkUrl(linkUrl) {
    this.linkUrl = linkUrl;
  }

  getLinkUrl() {
    return this.linkUrl;
  }

  hasLinkUrl() {
    return this.linkUrl !== null;
  }

  getCreatedAt() {
    return this.createdAt;
  }

  getCreatedAtAsString() {
    return this.createdAt === null ? null : this.createdAt.toUTCString();
  }

  getCountryCodes() {
    return this.countryCodes;
  }
}

export default Company;
