import React from "react";
import ModelImage from "../../../models/common/ModelImage";
import FieldText from "../FormWidgets/FieldText";
import FieldTextArea from "../FormWidgets/FieldTextArea";
import ImageChooser from "../FormWidgets/ImageChooser";
import ErrorBlock from "../FormWidgets/ErrorBlock";
import Category from "../../../models/Category";
import FieldCheckbox from "../FormWidgets/FieldCheckbox";

/**
 * Every category form (embedded or not; add or edit) is based on this class. It is subclassed for embedded and
 * non-embedded forms, and they are subclassed as necessary, eg for add and edit.
 */
class AbstractCategoryForm extends React.Component {
  constructor(props) {
    super(props);

    this.categoryRepository = this.props.categoryRepository;

    /** @var {Category|null} category */
    let category = this.getInitialCategory();

    this.state = {
      category: category, // @TODO: I'm not sure if this is used
      categoryCopy: {
        id: category.getId(),
        name: category.getName(),
        description: category.getDescription(),
        image: category.getImage(),
        isSeasonalCategory: category.getIsSeasonalCategory(),
        isCurrentSeasonalCategory: category.getIsCurrentSeasonalCategory(),
        seasonalText: category.getSeasonalText(),
        seasonalButtonLabel: category.getSeasonalButtonLabel(),
      },
      newImage: null,
      errors: []
    };
  }

  getInitialCategory() {
    // This is an abstract method that must be overridden in the subclasses. Not sure how to enforce that at the moment.
    window.alert('getInitialCategory() must be implemented in the appropriate subclass(es)!');

    return null;
  }

  showImageChooserImage() {
    return true;
  }
  getActionButtonSection() {
    // This is an abstract method that must be overridden in the subclasses. Not sure how to enforce that at the moment.
    window.alert('getActionButtonSection() must be implemented in the appropriate subclass(es)!');
  }
  showSeasonalSettings() {
    return false;
  }

  updateState(newCategoryCopy) {
    this.setState({categoryCopy: newCategoryCopy});
  }

  setName = event => {
    let categoryCopy = this.state.categoryCopy;
    categoryCopy.name = event.target.value;
    this.updateState(categoryCopy);
  }

  setDescription = event => {
    let categoryCopy = this.state.categoryCopy;
    categoryCopy.description = event.target.value;
    this.updateState(categoryCopy);
  }

  setSeasonalCategory = event => {
    let categoryCopy = this.state.categoryCopy;
    categoryCopy.isSeasonalCategory = !!event.target.checked;
    this.updateState(categoryCopy);
  }

  setIsCurrentSeasonalCategory = event => {
    let categoryCopy = this.state.categoryCopy;
    categoryCopy.isCurrentSeasonalCategory = !!event.target.checked;
    this.updateState(categoryCopy);
  }

  setSeasonalText = event => {
    let categoryCopy = this.state.categoryCopy;
    categoryCopy.seasonalText = event.target.value;
    this.updateState(categoryCopy);
  }

  setSeasonalButtonLabel = event => {
    let categoryCopy = this.state.categoryCopy;
    categoryCopy.seasonalButtonLabel = event.target.value;
    this.updateState(categoryCopy);
  }

  /**
   * In this setter, we don't populate anything in the category copy because we want to keep showing the original image.
   * Instead we put it in a separate property of the state, which gets picked up on save.
   * @param event
   */
  setImage = event => {
    this.setState({newImage: new ModelImage(event[0])});
  }

  // @TODO: Sometimes this "form" is embedded inside another form (eg company add), so we can't always use a form tag. We need to sort out how we handle this.
  maintenanceForm() {
    let seasonalFields = null;
    if (this.showSeasonalSettings()) {
      seasonalFields = [
        <FieldCheckbox label="Seasonal Category?" value={this.state.categoryCopy.isSeasonalCategory ? '1' : ''} onchange={this.setSeasonalCategory}/>,
        <FieldCheckbox label="Is Current Seasonal Category?" value={this.state.categoryCopy.isCurrentSeasonalCategory ? '1' : ''} onchange={this.setIsCurrentSeasonalCategory}/>,
        <FieldTextArea label="Seasonal Text" value={this.state.categoryCopy.seasonalText} required={false} onchange={this.setSeasonalText}/>,
        <FieldTextArea label="Seasonal Button Label" value={this.state.categoryCopy.seasonalButtonLabel} required={false} onchange={this.setSeasonalButtonLabel}/>,
      ]
    }

    return (
      <div>
        <FieldText label="Name" value={this.state.categoryCopy.name} required={true} onchange={this.setName}/>
        <FieldTextArea label="Description" value={this.state.categoryCopy.description} required={true} onchange={this.setDescription}/>
        <ImageChooser label="Image" showImage={this.showImageChooserImage()} value={this.state.categoryCopy.image} required={true} onchange={this.setImage}/>
        {seasonalFields}
        <ErrorBlock errorMessages={this.state.errors}/>
        {this.getActionButtonSection()}
      </div>
    )
  }

  afterCategorySave(recordId) {
    // By default this does nothing. Obviously, add any necessary functionality into this class or its subclasses.
  }

  saveCategory = event => {
    event.preventDefault();
    let categoryToSave = new Category(this.state.categoryCopy);
    // If the user has chosen a new image, give it to the category object that we're about to save.
    if (this.state.newImage) {
      categoryToSave.setImage(this.state.newImage);
    }
    let result = this.categoryRepository.persist(
      categoryToSave,
      'The category was saved!',
      (recordId) => {this.afterCategorySave(recordId);}
    );
    this.setState({errors: result.getErrors()});
    // If there are any warning, just alert them, but the processing won't be aborted.
    if (result.hasWarnings()) {
      result.getWarnings().forEach(
        function (warningMessage) {
          alert('Warning: '+warningMessage);
        }
      );
    }
  }
}

export default AbstractCategoryForm;
