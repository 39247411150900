import React from 'react';
import {Link} from "react-router-dom";
import UrlHelperService from "../../../services/UrlHelperService";

class CompanyListOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: this.props.company,
      categoriesById: this.props.categoriesById,
      tagsById: this.props.tagsById
    };
  }

  // @TODO: We need the CSS here, of course.
  render() {
    let imgSrc = this.props.company.getImage().hasUrl() ? this.props.company.getImage().getUrl() : '/images/noimage.png';

    // Take the company's category ids and convert them into a CSL of category names. Ditto for its tags.
    let categoryNames = this.state.company.hasCategoryIds() ? this.state.categoriesById.idsToLabels(this.state.company.getCategoryIds()) : null;
    let companyTagNames = this.state.company.hasTagIds() ? this.state.tagsById.idsToLabels(this.state.company.getTagIds()) : null;

    let urlHelperService = new UrlHelperService();
    let linkUrl = this.state.company.hasLinkUrl() ? urlHelperService.makeFullUrl(this.state.company.getLinkUrl()) : null;

    return (
      <div className="row record">
        <div className="col-md-1"><img src={imgSrc} alt="Company"/></div>
        <div className="col-md-2">{this.state.company.getName()}</div>
        <div className="col-md-1">{categoryNames}</div>
        <div className="col-md-1">{companyTagNames}</div>
        <div className="col-md-3">{this.state.company.getDescription()}</div>
        <div className="col-md-3">{linkUrl ? <a href={linkUrl} target="_blank" rel="noopener noreferrer">{this.state.company.getLinkUrl()}</a> : null}</div>
        <div className="col-md-1"><Link to={`/admin/companies/edit/${this.props.company.getId()}`}>Edit</Link></div>
      </div>
    )
  }
}

export default CompanyListOne;
